import themeEvah from '../../../assets/theme';

const styles = (theme) => ({
  root: {
  },
  containerTitle: {
    textAlign: 'center',
    fontWeight: '700',
    fontFamily: 'Open Sans',
    fontSize: 39,
    color: '#333',

    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
      lineHeight: '1.2',
    },
  },
  section: {
    padding: '50px 50px 70px',

    [theme.breakpoints.down('sm')]: {
      padding: '30px 0px 20px',
    },
  },
  sectionHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(5),
  },
  sectionTitle: {
    fontFamily: 'Avenir Medium',
    fontSize: 30,
    margin: '15px 30px 20px',

    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
      margin: '15px 15px 20px',
    },
  },
  sectionText: {
    margin: '0 30px',
    fontSize: 18,

    [theme.breakpoints.down('sm')]: {
      margin: '0 15px 25px',
    },
  },
  itemTitle: {
    fontFamily: 'Open Sans',
    fontWeight: '600',
    marginBottom: theme.spacing(1),
  },
  itemText: {
    fontFamily: 'Open Sans',
    fontSize: 18,
  },
  ssl: {
    background: '#F5F5F5',
  },
  lighthouseText: {
    paddingLeft: 30,
    marginBottom: 30,

    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,

    },
  },
  icon: {
    width: '100%',
    height: 'auto',
    maxWidth: 100,
  },
  lighthouseIcon: {
    width: '100%',
    height: 'auto',
    maxWidth: 130,
    marginLeft: 15,
  },
  sslIcon: {
    width: '100%',
    height: 'auto',
    maxWidth: 90,
  },
  amazonIcon: {
    width: '100%',
    height: 'auto',
    maxWidth: 150,
  },
  sectionSpacing: {
    padding: 15,
  },
  videoWrapper: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
  },
});


export default styles;
