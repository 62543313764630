/* eslint-disable no-unused-vars */
import { call, put } from 'redux-saga/effects';


import { Creators as actions } from '../ducks/plano';

import api from '../../services/api';


export function* getPlanos() {
  try {
    const { data } = yield call(api.get, '/plan/stripe/');

    yield put(actions.getPlanosSuccess(data));
  } catch (error) {
    yield put(actions.getTemplatesError());
  }
}
