import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core';

import { ValidatorForm } from 'react-material-ui-form-validator';

import classnames from 'classnames';
import { Creators as actions } from '../../../store/ducks/user';

import styles from './styles';


function EditProfile({ classes }) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user.me);
  const [picture, setPicture] = useState('');

  let inputUpload;

  useEffect(() => {
    if (!me) {
      dispatch(actions.getMeRequest());
    }
  }, [me]);


  const handleUploadPhoto = (event) => {
    setPicture(URL.createObjectURL(event.target.files[0]));

    console.log(me);

    dispatch(actions.getMeUploadRequest(event.target.files[0]));
  };

  function handleSubmit(e) {
    e.preventDefault();

    // handleUpdateProfile();
  }

  return (
    <Grid container justify="center">

      <ValidatorForm className={classes.form} onSubmit={(e) => handleSubmit(e)}>
        <Grid item container xs={12} className={classes.formItem}>
          <Typography variant="body1" className={classes.title}>Nome da agência</Typography>

          <Grid item xs={12} sm={8} className={classes.formInputWrapper}>
            <TextField
              value=""
              placeholder={me ? me.name ? me.name : `${me.first_name} ${me.last_name}` : 'Seu nome'}
              // onChange={(e) => setSiteData(e, 'business_name')}
              margin="dense"
              variant="outlined"
              id="agency_name"
              type="text"
              fullWidth
              className={classes.formInput}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} className={classes.formItem}>
          <Typography variant="body1" className={classes.title}>E-mail</Typography>

          <Grid item xs={12} sm={8} className={classes.formInputWrapper}>
            <TextField
              value=""
              placeholder={me ? me.email : 'Seu email'}
              /* onChange={(e) => {
                setSiteData(e, 'zip_code');
                if (e.target.value.length === 8) getCEP(e.target.value);
              }} */

              margin="dense"
              variant="outlined"
              id="email"
              type="email"
              fullWidth
              className={classes.formInput}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} className={classes.formItem}>
          <Typography variant="body1" className={classes.title}>Telefone</Typography>

          <Grid item xs={12} sm={8} className={classes.formInputWrapper}>
            <TextField
              value=""
              placeholder={me ? me.phone : 'Seu telefone'}
              // onChange={(e) => setSiteData(e, 'bairro')}
              margin="dense"
              variant="outlined"
              id="bairro"
              type="tel"
              fullWidth
              className={classes.formInput}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} className={classes.formItem}>
          <Typography variant="body1" className={classes.title}>Alterar senha</Typography>

          <Grid item xs={12} sm={8} className={classes.formInputWrapper}>
            <TextField
              // onChange={(e) => setSiteData(e, 'street')}
              value="senha"
              margin="dense"
              variant="outlined"
              id="password"
              type="password"
              fullWidth
              className={classes.formInput}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} className={classes.formItem} style={{ paddingBottom: 90 }}>
          <Typography variant="body1" className={classes.title}>Foto de Perfil</Typography>

          <Grid item xs={12} sm={8} className={classes.formInputWrapper}>
            <Avatar
              variant="rounded"
              src={picture !== '' ? picture : (me && (me.photo || 'https://api.adorable.io/avatars/285/abott@adorable.png'))}
              alt={me && me.name ? me.name : 'Sua foto'}
              className={classes.avatar}
              />
            <Button variant="contained" onClick={() => inputUpload.click()} className={classes.pictureButton}>
              + Carregar
            </Button>
            <input
              hidden
              ref={(ref) => { inputUpload = ref; }}
              type="file"
              onChange={handleUploadPhoto}
              accept="image/png, image/jpeg"
              />
          </Grid>
        </Grid>


        <DialogActions style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          padding: 30,
          width: '100%',
          background: 'white',
        }}
        >
          <Button variant="contained" color="primary" type="submit">
              Salvar
          </Button>
        </DialogActions>


      </ValidatorForm>

    </Grid>
  );
}

export default withStyles(styles)(EditProfile);
