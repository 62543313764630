const styles = (theme) => ({
  logoWrapper: {
    width: '100%',
  },

  logo: {
    height: 40,
    width: 100,
    borderRadius: 4,
    backgroundColor: 'black',
    padding: 4,
  },

  title: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  loading: {
    textAlign: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#0e9bd4',
    '&:hover': {
      backgroundColor: '#0b7ba8',
    },
  },

  error: {
    textAlign: 'center',
    color: 'red',
  },

  container: {
    paddingTop: 50,
    paddingBottom: 50,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#0e9bd4',
  },

  card: {
    paddingTop: 25,
    paddingBottom: 25,


  },

  register: {
    textDecoration: 'none',
    color: '#0e9bd4',
    fontWeight: 'bold',
    '&:hover': {
      color: '#0b7ba8',
    },
  },

  registerGroup: {
    fontWeight: 'bold',
    color: '#617379',
  },


});

export default styles;
