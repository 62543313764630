import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import classnames from 'classnames';
// import { Creators as actions } from '../../store/ducks/user';
import styles from './styles';
import Logo from '../../../assets/logo-evah.svg';


function Header({ classes }) {
  const redirect = useHistory();
  /*
  const Signed = useSelector((state) => state.user.token);


  const dispatch = useDispatch();


  function handleLogout() {
    dispatch(actions.getLogoutRequest());
  }
  function handleDashboard() {
    redirect.push('/dashboard');
  }
  function handleRegister() {
    redirect.push('/register');
  }
*/
  function handleLogin() {
    redirect.push('/login');
  }


  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.header}>
        <Toolbar className={classes.contentSize}>
          <Grid className={classes.logoWrapper}>
            <Link to="/">
              <img src={Logo} className={classes.logo} alt="logo EVAH" />
            </Link>
          </Grid>


          {/* <Hidden smDown>
            <Grid className={classes.nav}>
              <Button className={classes.navLink} href="#produto">
                Produto
              </Button>
              <Button className={classes.navLink} href="#templates">
                Templates
              </Button>
              <Button className={classes.navLink} href="#planos">
                Planos
              </Button>
            </Grid>
          </Hidden> */}

          <Grid className={classes.filler} />

          <>
            <Button variant="contained" onClick={() => handleLogin()} className={classnames(classes.button, classes.whiteButton)}>
              Entrar
            </Button>
          </>

        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withStyles(styles)(Header);
