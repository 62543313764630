import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Lighthouse from '../../../assets/icon-lighthouse.svg';
import SSL from '../../../assets/icon-ssl.svg';
import Amazon from '../../../assets/icon-hospedagem.svg';
import Score1 from '../../../assets/icon-pwa-1.png';
import Score2 from '../../../assets/icon-pwa-2.png';
import PWA from '../../../assets/icon-pwa-3.png';

import mp4SSLAnimation from '../../../assets/videos/ssl-animacao.mp4';
import webmSSLAnimation from '../../../assets/videos/ssl-animacao.webm';
import mp4Hosting from '../../../assets/videos/hospedagem-animacao.mp4';
import webmHosting from '../../../assets/videos/hospedagem-animacao.webm';

import styles from './styles';

function Highlights({ classes }) {
  return (
    <div className={classes.root}>
      <Typography variant="h3" component="h2" className={classes.containerTitle}>Conheça os destaques da EVAH</Typography>

      <div className={classes.lighthouse}>
        <Container maxWidth="lg" className={classes.section}>
          <div className={classes.sectionHeading}>
            <img src={Lighthouse} className={classes.lighthouseIcon} />
            <Typography variant="h4" className={classes.sectionTitle}>
              Google Lighthouse
            </Typography>
          </div>

          <Grid container className={classes.sectionSpacing}>

            <Grid item container xs={12} md={6}>
              <Grid item xs={2}>
                <img src={Score1} alt="" className={classes.icon} />
              </Grid>
              <Grid item xs={10} className={classes.lighthouseText}>
                <Typography variant="h5" className={classes.itemTitle}>Performance</Typography>
                <Typography variant="body1" align="left" className={classes.itemText}>
                No atributo de performance, imagens next-gen, bloqueadores de CSS, tempo de resposta e outros itens otimizados automaticamente via algoritmos.
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6}>
              <Grid item xs={2}>
                <img src={Score1} alt="" className={classes.icon} />
              </Grid>
              <Grid item xs={10} className={classes.lighthouseText}>
                <Typography variant="h5" className={classes.itemTitle}>Boas práticas</Typography>
                <Typography variant="body1" align="left" className={classes.itemText}>
                Evite automaticamente as más práticas de desenvolvimento de páginas web automaticamente.
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6}>
              <Grid item xs={2}>
                <img src={Score2} alt="" className={classes.icon} />
              </Grid>
              <Grid item xs={10} className={classes.lighthouseText}>
                <Typography variant="h5" className={classes.itemTitle}>Acessibilidade</Typography>
                <Typography variant="body1" align="left" className={classes.itemText}>
                Tenha labels, textos alternativos e outras práticas de acessibilidade implementadas facilmente.
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6}>
              <Grid item xs={2}>
                <img src={Score2} alt="" className={classes.icon} />
              </Grid>
              <Grid item xs={10} className={classes.lighthouseText}>
                <Typography variant="h5" className={classes.itemTitle}>SEO</Typography>
                <Typography variant="body1" align="left" className={classes.itemText}>
                Tenha uma interface de gestão de SEO simplificada, edite palavras chaves, meta dados e descrições facilmente.
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6}>
              <Grid item xs={2}>
                <img src={PWA} alt="" className={classes.icon} />
              </Grid>
              <Grid item xs={10} className={classes.lighthouseText}>
                <Typography variant="h5" className={classes.itemTitle}>Progressive Web App (PWA)</Typography>
                <Typography variant="body1" align="left" className={classes.itemText}>
                Configure o PWA do seu site deslizando um simples botão, seu site instalável como um App.
                </Typography>
              </Grid>
            </Grid>

          </Grid>
        </Container>

      </div>


      <div className={classes.ssl}>
        <Container maxWidth="lg" className={classes.section}>

          <Grid container className={classes.sectionSpacing}>
            <Grid item container xs={12} md={6}>
              <Grid item xs={2}>
                <img src={SSL} className={classes.sslIcon} />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h4" className={classes.sectionTitle}>
                  Segurança SSL
                </Typography>
                <Typography variant="body1" align="left" className={classes.sectionText}>
                Após a publicação de um site faça a ativação do certificado de segurança com um clique.
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} align="center" className={classes.videoWrapper}>
              <video width="350" autoPlay muted playsinline loop>
                <source src={webmSSLAnimation} type="video/webm" />
                <source src={mp4SSLAnimation} type="video/mp4" />
              </video>
            </Grid>

          </Grid>

        </Container>
      </div>

      <div className={classes.amazon}>
        <Container maxWidth="lg" className={classes.section}>

          <Grid container className={classes.sectionSpacing}>

            <Grid item container xs={12} md={6}>
              <Grid item xs={2}>
                <img src={Amazon} className={classes.amazonIcon} />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h4" className={classes.sectionTitle}>
                Hospedagem Amazon
                </Typography>
                <Typography variant="body1" align="left" className={classes.sectionText}>
                Com integração total aos servidores da Amazon, seu site é automaticamente hospedado no maior e mais seguro serviço de hospedagem do mundo. E o melhor, sem custo adicional algum.
                </Typography>
              </Grid>

            </Grid>

            <Grid item xs={12} md={6} align="center" className={classes.videoWrapper}>
              <video width="350" autoPlay muted playsinline loop>
                <source src={webmHosting} type="video/webm" />
                <source src={mp4Hosting} type="video/mp4" />
              </video>
            </Grid>

          </Grid>

        </Container>

      </div>

    </div>
  );
}

export default withStyles(styles)(Highlights);
