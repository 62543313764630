
const styles = (theme) => ({
  container: {
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#0e9bd4',
    '&:hover': {
      backgroundColor: '#0b7ba8',
    },
  },
  hide: {
    display: 'none',
  },


});

export default styles;
