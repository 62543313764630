import themeEvah from '../../../assets/theme';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  mainBanner: {
    padding: '150px 0 90px',

    [theme.breakpoints.down('sm')]: {
      padding: '70px 20px 60px',
    },
  },
  blue: {
    color: themeEvah.colors.secondary,
    fontWeight: 600,
  },
  mainTitleWrapper: {
    marginBottom: theme.spacing(5),
  },
  mainTitle: {
    fontWeight: 500,

    [theme.breakpoints.down('sm')]: {
      fontSize: 42,
      marginBottom: 0,
      lineHeight: 1.2,
    },
  },
  subTitle: {
    marginBottom: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: 1.2,
    },
  },
  button: {
    fontSize: 18,
    fontWeight: 'bold',
    textTransform: 'none',
    padding: '7px 32px',
  },
  blueButton: {
    backgroundColor: themeEvah.colors.secondary,
    color: '#fff',

    '&:hover': {
      backgroundColor: '#0b7ba8',
    },
  },
  subBanner: {
    maxWidth: 700,
    margin: themeEvah.contentSize.margin,
    padding: themeEvah.contentSize.padding,
    paddingTop: 30,
    paddingBottom: 40,
  },
  adsTitle: {
    fontWeight: 100,
  },
  adsImage: {
    [theme.breakpoints.only('xs')]: {
      margin: '20px 0',
    },
  },
});

export default styles;
