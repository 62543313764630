import themeEvah from '../../../assets/theme';

const styles = (theme) => ({
  root: {
    padding: '30px 0',
    // background: 'linear-gradient(185deg, #D2D2D2, #B0B4B7 69.85%, white 70%, white)',
  },
  containerTitle: {
    color: '#333333',
    fontFamily: 'Open Sans',
    fontWeight: 700,
    textAlign: 'center',
    margin: '10px 0',
  },
  cardWrapper: {
    margin: '20px 0 70px',
  },
  item: {
    display: 'flex',
  },
  card: {
    boxShadow: '0px 3px 6px rgba(0,0,0,.16)',
    maxWidth: 375,
    borderRadius: 5,
    padding: '28px 25px',
    color: '#333',
    background: '#fff',
  },
  cardTitle: {
    color: '#000',
    fontFamily: 'Avenir Roman',
    marginBottom: 10,
  },
  cardText: {
    fontFamily: 'Open Sans',
  },
});


export default styles;
