import React, { useEffect } from 'react';

import { withStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import SignIn from '../../components/SignIn';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { Link, useHistory } from 'react-router-dom';
import styles from './styles';
import Logo from '../../assets/logo-evah.svg';


function Login({ classes }) {
  const [value, setValue] = React.useState(0);

  const url_atual = window.location.href;

  useEffect(() => {
    if (url_atual.indexOf('register') !== -1) {
      setValue(1);
    }
  }, []);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container component="main" maxWidth="100%" className={classes.container}>
      {/* <div className={classes.logoWrapper}>
        <Link to="/">
          <img src={Logo} className={classes.logo} alt="logo EVAH" />
        </Link>
      </div>
     */}

      <Card className={classes.card}>

        <Container component="main" maxWidth="xs">
          <CardContent className={classes.CardContent}>
            <Typography component="h5" align="center" variant="h5">
                LOGIN
            </Typography>
            <SignIn />
          </CardContent>
        </Container>

        <CardContent className={classes.registerGroup}>
          <Typography align="center" variant="body1">
            Sem Conta? Sem problema.<a href="/register" className={classes.register}> Se Registre Aqui.</a>
          </Typography>
        </CardContent>
      </Card>

    </Container>



  );
}


export default withStyles(styles)(Login);
