import { combineReducers } from 'redux';


import user from './user';
import sites from './sites';
import payment from './payment';
import plano from './plano';

export default combineReducers({
  user, sites, payment, plano,
});
