/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { call, put } from 'redux-saga/effects';

import { Creators as UserActions } from '../ducks/user';

import bucket from '../index';

import api from '../../services/api';

export function* resendMe() {
  try {
    const { data } = yield call(api.get, 'user/verify-email/');

    yield put(UserActions.getResendEmaiSuccess(data));
  } catch (error) {
    yield put(UserActions.getLoginError());
  }
}

export function* uploadMe({ payload }) {
  try {
    const fd = new FormData();

    fd.append('file', payload.photo);


    const { data } = yield call(api.post, 'user/upload', fd);

    yield put(UserActions.getMeUploadSuccess(data));
  } catch (error) {
    console.log(error);
  }
}

export function* userLogin({ payload }) {
  try {
    const { data } = yield call(api.post, 'user/login', payload.data);


    api.defaults.headers.Authorization = `Bearer ${data.token}`;


    yield put(UserActions.getLoginSuccess(
      data,
    ));
  } catch (error) {
    yield put(UserActions.getLoginError());
  }
}

export function* userRegister({ payload }) {
  try {
    payload.data.phone = payload.data.phone.replace('-', '').replace(/ /g, '').replace('(', '').replace(')', '');


    const { data } = yield call(api.post, 'user/create', payload.data);

    api.defaults.headers.Authorization = `Bearer ${data.token}`;

    yield put(UserActions.getRegisterSuccess(data));
  } catch (error) {
    yield put(UserActions.getRegisterError());
  }
}

export function* controlRedux() {
  const { token } = bucket.store.getState().user;

  if (!token) {
    yield put(UserActions.getLogoutRequest());
  }
}


// eslint-disable-next-line no-unused-vars
export function* userMe({ payload }) {
  try {
    const { data } = yield call(api.get, 'user');

    console.log(data);

    yield put(UserActions.getMeSuccess(data));
  } catch (error) {
    yield put(UserActions.getError());
  }
}

export function* recovery({ payload }) {
  try {
    const { data } = yield call(api.post, 'user/reset-password', payload.email);

    console.log(data);

    yield put(UserActions.getRecoveryPassSuccess(data));
  } catch (error) {
    yield put(UserActions.getRecoveryError());
  }
}
