import React from 'react';

import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import styles from './styles';
import OptionSelector from './OptionSelector';
import ImageCards from './ImageCards';
import PlanCards from './PlanCards';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Banner from './Banner';
import Contrato from './Contrato';

function Home({ classes }) {
  return (
    <div className={classes.root}>

      <Header />
      <Container component="main" maxWidth="lg">
        <Banner />

        <OptionSelector />
        <ImageCards />
        <PlanCards />
        <Contrato />

      </Container>

      <Footer />

    </div>
  );
}


export default withStyles(styles)(Home);
