import themeEvah from '../../../assets/theme';

const styles = (theme) => ({
  form: {
    width: '100%',
    padding: '0 20px',
  },
  formItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 15,

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  formInputWrapper: {
    marginLeft: 40,
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  formInput: {
    maxWidth: 370,
    color: '#858585',
  },
  title: {
    alignSelf: 'center',
  },
  pictureInput: {
  },
  pictureButton: {
    background: '#343434',
    color: '#fff',
    marginLeft: 20,
    textTransform: 'none',
    padding: '0px 12px',

    '&:hover': {
      background: '#1a1a1a',
      color: '#fff',
    },
  },
});


export default styles;
