import themeEvah from '../../assets/theme';

const styles = (theme) => ({
  root: {
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  modalTitle: {
    '& h2': {
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontSize: 28,
      padding: '10px 0 20px 30px',
    },
  },
});


export default styles;
