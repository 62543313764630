
import theme from '../../../assets/themAlex';

const styles = () => ({
  container: {
    margin: '20px auto',
    fontFamily: theme.font.fontFamily,
  },

  scrita: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },

  title: {
    alignSelf: 'end',
    marginTop: 10,
    color: 'rgb(0, 154, 214)',
    borderBottom: '1px solid rgb(0, 154, 214)',
    fontWeight: 600,
    marginLeft: 30,
    marginBottom: 20,
  },

  card: {


  },

  content: {
    fontSize: theme.font.fontSize,
    color: 'rgb(33, 33, 33)',
    fontWeight: 500,
    lineHeight: 'normal',
    fontFamily: 'sans-serif',
    marginLeft: 30,
    marginTop: 20,
  },

  paddinx: {
    paddingLeft: 50,
    paddingRight: 50,
  },

});

export default styles;
