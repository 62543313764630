import React from 'react';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import styles from './styles';

import Card from '../../../components/CardImage';

const cards = [
  {
    title: 'Web Designers',
    img: 'https://irp-cdn.multiscreensite.com/a2c49a4e/dms3rep/multi/desktop/person-designer2.png',
    imgAlt: 'Web Designers',
    content: 'Supere as expectativas dos seus clientes com sites profissionais sem necessidade de programação.',
  },
  {
    title: 'Agências',
    img: 'https://irp-cdn.multiscreensite.com/a2c49a4e/dms3rep/multi/desktop/person-agencia2.png',
    imgAlt: 'Agências',
    content: 'Escale sua Agência com um construtor de sites robusto, SSL com um clique, otimização do PageSpeed, personalização e muito mais.',
  },
  {
    title: 'Rede de Franquias',
    img: 'https://irp-cdn.multiscreensite.com/a2c49a4e/dms3rep/multi/desktop/person-rede.png',
    imgAlt: 'Rede de Franquias',
    content: 'Centralize em um único painel o desenvolvimento de vários sites e explore todo o potencial do Google para gerencias novos negócios',
  },
];


function ImageCards({ classes }) {
  return (
    <Grid container className={classes.container} spacing={2} direction="row" justify="space-between" alignItems="stretch">

      {cards.map((card) => (
        <Grid key={card.title} item xs={12} justify="center" sm={3}>
          <Card img={card.img} title={card.title} imgAlt={card.imgAlt} content={card.content} />
        </Grid>
      ))}

      <Grid
        item
        xs={12}
        container
        direction="column"
        justify="center"
        alignItems="center"
        sm={3}
        className={classes.paddinx}
      >
        <Typography className={classes.content}>Nossos templates são a maneira
          mais bela de apresentar suas ideias
          on-line. Destaque-se com um site, um portifólio ou uma loja on-line profissional
        </Typography>
        <Typography className={classes.title}>SAIBA MAIS</Typography>
      </Grid>

    </Grid>
  );
}

export default withStyles(styles)(ImageCards);
