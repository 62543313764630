import themeEvah from '../../../assets/theme';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  header: {
    backgroundColor: themeEvah.colors.primary,
    overflow: 'hidden',
  },
  contentSize: {
    maxWidth: 1700,
    width: themeEvah.contentSize.width,
    padding: '0 30px',
    margin: themeEvah.contentSize.margin,
  },
  logoWrapper: {
  },
  logo: {
    height: 35,

    [theme.breakpoints.down('sm')]: {
      height: 25,
    },
  },
  nav: {
    flexGrow: 1,
    marginLeft: theme.spacing(5),
  },
  navLink: {
    color: '#fff',
    borderRight: '1px solid rgba(255,255,255, .3)',
    borderRadius: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textTransform: 'none',

    '&:last-child': {
      borderRight: 'none',
    },
  },
  filler: {
    flexGrow: 1,
  },
  button: {
    fontWeight: 'bold',
    textTransform: 'none',
    marginRight: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
      padding: '5px 8px',
      fontSize: 12,
    },
  },
  blueButton: {
    backgroundColor: themeEvah.colors.secondary,
    color: '#fff',

    '&:hover': {
      backgroundColor: '#0b7ba8',
    },
  },
  whiteButton: {
    backgroundColor: '#fff',
    color: '#000',
  },
});

export default styles;
