import React from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/styles';

import styles from './styles';


function card({
  classes, content, img, title, imgAlt,
}) {
  return (
    <Card className={classes.card}>

      <img alt={imgAlt} className={classes.img} src={img} />
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.content}>{content}</Typography>
    </Card>


  );
}


export default withStyles(styles)(card);
