import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Container } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import PhoneCode from 'react-phone-code';
import { TextMaskPhone } from '../MaskInput/index';
import { Creators as actions } from '../../store/ducks/user';


import SnackBar from '../SnackBar';
import styles from './styles';

function SignUp({ classes }) {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.user.loading);
  const token = useSelector((state) => state.user.token);
  const error = useSelector((state) => state.user.errorCreate);
  const [open, setOpen] = useState(false);

  const redirect = useHistory();

  useEffect(() => {
    if (token && token !== null) {
      redirect.push('/dashboard');
    }
  }, [token]);

  const [user, setUser] = useState({
    name: '', email: '', phone: '', password: '', password2: '',
  });


  function handleChange(event, name) {
    setUser({ ...user, [name]: event.target.value });
  }


  ValidatorForm.addValidationRule('isEqualPassword', (value) => {
    if (value !== user.password) {
      return false;
    }
    return true;
  });

  ValidatorForm.addValidationRule('min6', (value) => {
    if (value.length < 6) {
      return false;
    }
    return true;
  });


  function handleSubmit(event) {
    event.preventDefault();
    const { password2, ...rest } = user;
    dispatch(actions.getRegisterRequest(rest));
  }

  useEffect(() => {
    if (error === true) {
      setOpen(true);
    }
  }, [error]);


  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <CssBaseline />


      <div className={classes.paper}>
        <ValidatorForm
          className={classes.form}
          onSubmit={(e) => handleSubmit(e)}
        >

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextValidator
                autoComplete="name"
                name="name"
                variant="filled"
                className={classes.input}

                value={user.name}
                fullWidth
                id="name"
                label="Nome"
                validators={['required']}
                errorMessages={['Este campo é obrigatorio']}
                onChange={(e) => handleChange(e, 'name')}
                autoFocus
              />
            </Grid>
            {/* <Grid item xs={6} >
              <TextValidator
               variant="filled"
                className={classes.input}

                value={user.last_name}
                fullWidth
                id="lastName"
                label="Sobrenome"
                name="last_name"
                autoComplete="lname"
                validators={['required']}
                errorMessages={['Este campo é obrigatorio']}
                onChange={(e) => handleChange(e, 'last_name')}
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextValidator
                variant="filled"
                className={classes.input}
                fullWidth
                value={user.email}
                id="email"
                label="E-mail"
                name="first_name"
                autoComplete="email"
                validators={['required', 'isEmail']}
                errorMessages={['Este campo é obrigatorio', 'Email não valido']}
                onChange={(e) => handleChange(e, 'email')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                variant="filled"
                className={classes.input}
                fullWidth
                value={user.phone}
                id="phone"
                label="Telefone"
                name="phone"
                autoComplete="phone"
                onChange={(e) => handleChange(e, 'phone')}
                validators={['required', 'minStringLength:16', 'maxStringLength:16']}
                errorMessages={['Este campo é obrigatorio']}
                InputProps={{
                  inputComponent: TextMaskPhone,
                }}
              />
            </Grid>
            <Grid item xs={12}>

              <TextValidator
                variant="filled"
                className={classes.input}

                fullWidth
                name="password"
                label="Senha"
                value={user.password}
                onChange={(e) => handleChange(e, 'password')}
                type="password"
                id="password"
                validators={['required', 'min6']}
                errorMessages={['Este campo é obrigatorio', 'Mínimo de 6 caracteres']}
                autoComplete="current-password"
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              {
                loading ? <CircularProgress /> : (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Cadastrar
                  </Button>
                )
              }
            </Grid>

          </Grid>

        </ValidatorForm>
      </div>
      <SnackBar open={open} setOpen={setOpen} message="Verifique seu dados e tente novamente!" variant="error" />
    </Container>
  );
}


export default withStyles(styles)(SignUp);
