import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import {
  Card, CardActions, CardContent, Avatar, Typography, Button, MenuItem, MenuList,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { classExpression } from '@babel/types';
import { Creators as actions } from '../../store/ducks/user';

import Picture from '../../assets/images.png';
import styles from './styles';
import ModalCreateSite from '../ModalCreateSite';


function Profile({ classes }) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user.me);
  const sites = useSelector((state) => state.sites.sites);
  const [sendEmail, setSendEmail] = useState(false);

  const [open, setOpen] = useState(false);

  function resendEmail() {
    dispatch(actions.getResendEmailRequest());
    setSendEmail(true);
  }

  function handleLogout() {
    dispatch(actions.getLogoutRequest());
  }

  useEffect(() => {
    if (!me) {
      dispatch(actions.getMeRequest());
    }
  }, [me]);

  return (
    <div className={classes.perfil}>
      <div className={classes.profile}>
        <img src={Picture} alt={me && me.name ? me.name : 'Sua foto'} className={classes.avatar} />
        <div className={classes.profileInfo}>
          <strong variant="h6" component="h2" align="center" className={classes.name} gutterBottom>
            {me && me.name ? me.name : `${me.first_name} ${me.last_name}`}
          </strong>
          <p variant="body2" color="textSecondary">
            {me && me.email}
          </p>
          <p variant="body2" color="textSecondary">
            {me && me.emailVerified ? 'Conta verificada' : 'Conta não verificada'}
          </p>
        </div>
      </div>
      <div className={classes.divider} />
      <MenuList>
        <MenuItem className={classes.optionButton}>Editar perfil</MenuItem>
        <MenuItem className={classes.optionButton}>Configurações</MenuItem>
        <MenuItem className={classes.optionButton}>Ajuda</MenuItem>
        <MenuItem className={classes.optionButton}>Sair</MenuItem>
      </MenuList>
      <div className={classes.bottom} />

      <CardContent className={classnames(classes.cardContent, classes.column)}>
        <div onClick={() => console.log('click')}>
          <Avatar src={Picture} className={classes.avatar} alt="Jane Carlson" />

        </div>

        <Typography variant="h6" component="h2" align="center" className={classes.name} gutterBottom>
          {me && me.name ? me.name : `${me.first_name} ${me.last_name}`}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {me && me.email}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {me && me.emailVerified ? '✓ Verificado' : '✖ Não verificado'}
        </Typography>
        {
          me && !me.emailVerified && !sendEmail && (
            <Button variant="outlined" onClick={() => resendEmail()} fullWidth size="small">
              <Typography variant="body2" component="h2" align="center" gutterBottom>
                Reenviar email de verificação
              </Typography>
            </Button>
          )
        }
        {
          sendEmail
          && (
            <Typography variant="body2" component="h2" align="center" gutterBottom style={{ padding: 15, color: 'red' }}>
              Um email com o código de verificação foi enviado para seu email
            </Typography>
          )
        }
        <Typography variant="body2" color="textSecondary">
          {me && me.phone}
        </Typography>

        {sites.length < 8
          ? (
            <div style={{ alignItems: 'center' }}>
              <Button onClick={() => setOpen(true)} variant="outlined" fullWidth size="small" style={{ marginTop: 20 }}>
                <AddIcon />
                <Typography variant="body2" component="h2" align="center" gutterBottom>
                  Criar site
                </Typography>
              </Button>
            </div>
          ) : null}


      </CardContent>

      <CardActions className={classnames(classes.cardActions, classes.column)}>
        <Button onClick={() => handleLogout()} className={classes.link}>Sair</Button>
      </CardActions>

      <ModalCreateSite open={open} setOpen={setOpen} />
    </div>
  );
}

export default withStyles(styles)(Profile);
