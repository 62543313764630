
const styles = (theme) => ({

  loading: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 50,
  },
  content: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 100,
      paddingRight: 100,

    },
    overflow: 'scroll',
    height: 'calc(100% - 90px)',
    overflowX: 'hidden',
  },

  form: {
    width: '100%',
    display: 'contents',
    flexWrap: 'wrap',

  },

  title: {
    padding: '16px 0 !important',

  },

});

export default styles;
