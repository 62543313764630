import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import Card from '@material-ui/core/Card';
import classnames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';


import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import ModalPayment from './ModalPayment';
import ModalUnpublish from './ModalUnpublishSite';
import ModalFreePublish from './ModalFreePublish';
import ModalEditDomain from './ModalEditDomain';
import ExclueModel from './ModalExclueSite';

import styles from './styles';

import EditIcon from '../../../assets/icons/edit-icon.svg';
import PublishIcon from '../../../assets/icons/publish-icon.svg';
import tagNew from '../../../assets/icons/tag-new.png';
import SiteMenu from '../../../assets/icons/menu-icon.png';
import CustomDomain from '../../../assets/icons/icon-domain.png';
import DeleteIcon from '../../../assets/icons/delete-icon.svg';

function SiteComponent({ classes, site, index }) {
  const [exclueModel, setExclueModel] = useState(false);
  const [editeDomain, setEditeDomain] = useState(false);
  const [openPay, setOpenPay] = useState(false);
  const [openUnpublish, setOpenUnpublish] = useState(false);
  const { numberSites } = useSelector((state) => state.user.me);


  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <Card style={{ boxShadow: 'none' }}>
      <Grid
        container
        className={classnames(classes.card, classes.siteInfoContainer)}
        direction="row"
        justify="space-between"
        alignItems="stretch"
      >
        <Grid item xs={12} md={2} align="center" style={{ padding: 10 }} className={classes.siteImageContainer}>
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={(
              <Typography className={styles.text} component="h6" align="left">
                {site.business_name}
              </Typography>
                )}
          >
            <img className={classes.img} src={site ? site.thumbnail : 'https://my.duda.co/editor/direct/images/nee/snapshotPlaceholder.png'} alt="template" />
          </Tooltip>

        </Grid>

        <Grid item xs={12} style={{ position: 'relative' }}>
          <div className={classes.siteTextContainer}>


            <Typography noWrap component="h1" noWrap variant="h5" className={classnames(classes.name)} gutterBottom>
              {site.business_name} <img src={tagNew} height="22" className={classes.tagNew} />
            </Typography>
            <Typography noWrap className={classnames(classes.link, classes.centerText)} variant="body1">

              <a href={`http://${site.domain ? site.domain : `${site.business_name.replace(/[ ]/g, '-')}.evah.io`}`}>
                {site.domain ? site.domain : `${site.business_name.replace(/[ ]/g, '-')}.evah.io` }
              </a>
              {/*
              <IconButton aria-label="Edit" className={classes.icon} onClick={() => setEditeDomain(true)}>
                <img src={EditIcon} width="14" height="14" />
              </IconButton>
              */}
            </Typography>


            <Typography variant="caption" component="h6" style={{ color: '#343434', fontWeight: 600 }} className={classnames(classes.centerText)} gutterBottom>
              Criado em {new Date(site.created_at).toLocaleDateString('pt-BR')}
            </Typography>


            {site.status === 'PUBLISHED'

              ? (
                <Typography
                  variant="caption"
                  component="h6"
                  className={classnames(classes.status)}
                  style={{ backgroundColor: '#009BD6', color: 'white', textAlign: 'center' }}
                >
                  Publicado
                </Typography>
              ) : (


                <Tooltip
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  title={(
                    <Typography className={styles.text} component="h6" align="left">
                      Esse site só estará disponível quando estiver publicado.
                    </Typography>
                    )}
                >
                  <Typography
                    variant="caption"
                    component="h6"
                    className={classnames(classes.status)}
                    style={{ backgroundColor: '#929292', color: '#fff', textAlign: 'center' }}
                  >
                  Não publicado
                  </Typography>
                </Tooltip>
              )}


          </div>


          <div className={classes.finalButtons}>
            <Grid>

              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title={(
                  <Typography component="h6" align="left">
                    Editar Site
                  </Typography>
                )}
              >
                <Button variant="outlined" href={site.ssolink} className={classes.editButton} target="_blank" size="small">
                  <Typography className={classes.textLowercase} variant="button" component="h6" align="left">
                    <img src={EditIcon} width="14" height="14" style={{ marginBottom: '-2px' }} /> Editar
                  </Typography>
                </Button>
              </Tooltip>
            </Grid>
            {/*  <Grid item xs={12} sm={3}>

              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title={(
                  <Typography className={styles.text} component="h6" align="left">
                    Informações do Site
                  </Typography>
                )}
              >
                <Button variant="outlined" fullWidth size="small">
                  <PriorityHighIcon />
                </Button>
              </Tooltip>
            </Grid>
 */}

            <Grid style={{ marginLeft: 10 }}>

              {site.status === 'PUBLISHED'

                ? (
                  <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    title={(
                      <Typography component="h6" align="left">
                          Despublicar Site
                      </Typography>
                        )}
                  >
                    <Button variant="outlined" className={classes.unpublishButton} onClick={() => { setOpenUnpublish(!openUnpublish); }} size="small">
                      <Typography className={classes.textLowercase} variant="button" component="h6" align="left">
                          Despublicar
                      </Typography>
                    </Button>

                  </Tooltip>
                ) : (

                  <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    title={(
                      <Typography component="h6" align="left">
                          Publicar Site
                      </Typography>
                        )}
                  >
                    <Button variant="outlined" className={classes.publishButton} onClick={() => { setOpenPay(!openPay); }} size="small">
                      <Typography className={classes.textLowercase} variant="button" component="h6" align="left">
                        <img src={PublishIcon} width="14" height="14" style={{ marginBottom: '-3px' }} /> Publicar
                      </Typography>
                    </Button>
                  </Tooltip>
                )}


            </Grid>
          </div>

          <div className={classes.customDomainWrapper}>
            <Button aria-label="Edit" className={classes.customDomain} onClick={() => setEditeDomain(true)}>
              <img src={CustomDomain} style={{ marginRight: 4 }} height="15" /> Customizar domínio
            </Button>
          </div>
        </Grid>

        {/*
        <Tooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={(
            <Typography className={styles.text} component="h6" align="left">
                    Excluir Site
            </Typography>
                )}
        >
          <Button size="small" aria-label="exclue" onClick={() => setExclueModel(!exclueModel)} className={classes.ExclueIcon}>
            <ClearIcon />
          </Button>
        </Tooltip>
        */}

        <Tooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={(
            <Typography className={styles.text} component="h6" align="left">
                    Opções
            </Typography>
                )}
        >
          <Button size="small" className={classes.SiteMenuIcon} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            <img src={SiteMenu} height="26" />
          </Button>
        </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => setExclueModel(!exclueModel)} className={classes.menuItem}>
            <img src={DeleteIcon} className={classes.deleteIcon} height="18" /> Excluir
          </MenuItem>
        </Menu>

      </Grid>

      <ExclueModel open={exclueModel} index={index} site={site} setOpen={setExclueModel} />
      {numberSites >= 5 && numberSites <= 7

        ? <ModalFreePublish open={openPay} setOpen={setOpenPay} site={site} />
        : <ModalPayment open={openPay} setOpen={setOpenPay} site={site} />}

      <ModalUnpublish open={openUnpublish} setOpen={setOpenUnpublish} site={site} />

      <ModalEditDomain open={editeDomain} setOpen={setEditeDomain} site={site} oldDomain={site.domain ? site.domain : `${site.business_name.replace(/[ ]/g, '-')}.evah.io`} />


    </Card>
  );
}

export default withStyles(styles)(SiteComponent);
