
const styles = () => ({


  loading: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 50,
  },

  dialog: {
    overflowY: 'hidden',
    margin: '0 auto',
  },
});

export default styles;
