/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Switch, Route, Redirect,
  Link, useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import {
  Home, Login, HomeAlex, SiteHome, HomeCatherine, SignUp,
} from './routes';


import Header from './components/Header';
import RecoveryPass from './routes/RecoveryPass';

ReactGA.initialize('UA-153149685-1');

function App() {
  const location = useLocation();

  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);

  const token = useSelector((state) => state.user.token);

  return (

    <Switch>
      <Route path="/" exact>
        <HomeCatherine />
      </Route>
      <Route login path="/login" exact>
        <Header hide />
        <Login />
      </Route>
      <Route login path="/recovery" exact>
        <Header hide />
        <RecoveryPass />
      </Route>
      <Route login path="/register" exact>
        <Header hide />
        <SignUp />
      </Route>
      <PrivateRoute token={token} path="/dashboard" exact>
        <SiteHome />
      </PrivateRoute>
      <Route path="/home/alecs" exact>
        <HomeAlex />
      </Route>
      <Route path="/home/catherine" exact>
        <HomeCatherine />
      </Route>
    </Switch>
  );
}

function PrivateRoute({
  children, token, ...rest
}) {
  return (
    <Route
      {...rest}
      render={({ location }) => (token ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      ))}
    />
  );
}

export default App;
