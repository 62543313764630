import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import styles from './styles';

import mp4Steps from '../../../assets/videos/3-passos.mp4';
import webmSteps from '../../../assets/videos/3-passos.webm';
import mp4Customize from '../../../assets/videos/personalize.mp4';
import webmCustomize from '../../../assets/videos/personalize.webm';
import mp4Publish from '../../../assets/videos/publique.mp4';
import webmPublish from '../../../assets/videos/publique.webm';

function HowItWorks({ classes }) {
  return (
    <Container maxWidth="lg" className={classes.root}>
      <Typography variant="h3" component="h2" className={classes.containerTitle}>Como criar um site na EVAH?</Typography>

      <Grid container className={classes.section} spacing={8}>
        <Grid item xs={12} md={6} className={classes.mdOrder1}>
          <Container className={[classes.card, classes.card1]}>
            <Typography variant="h5" className={classes.cardTitle}>Escolha um Template</Typography>
            <Typography variant="body1" align="left" className={classes.cardText}>
            Escolha um de nossos template, de o nome e domínio para projeto e coloque alguns dados opcionais do projeto.
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={12} md={6} align="center" className={classes.mdOrder0}>
          <video className={classes.video} width="100%" autoPlay muted playsinline loop>
            <source src={webmSteps} type="video/webm" />
            <source src={mp4Steps} type="video/mp4" />
          </video>
        </Grid>
      </Grid>

      <Grid container className={classes.section} spacing={8}>
        <Grid item xs={12} md={6}>
          <Container className={[classes.card, classes.card2]}>
            <Typography variant="h5" className={classes.cardTitle}>Personalize Seu Site</Typography>
            <Typography variant="body1" align="left" className={classes.cardText}>
            Crie, edite e altere tanto o layout quanto conteúdo do site. Tudo isso sem custos.
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={12} md={6} align="center">
          <video className={classes.video} width="100%" autoPlay muted playsinline loop>
            <source src={webmCustomize} type="video/webm" />
            <source src={mp4Customize} type="video/mp4" />
          </video>
        </Grid>
      </Grid>

      <Grid container className={classes.section} spacing={8}>
        <Grid item xs={12} md={6} className={classes.mdOrder1}>
          <Container className={[classes.card, classes.card3]}>
            <Typography variant="h5" className={classes.cardTitle}>Configure e Publique</Typography>
            <Typography variant="body1" align="left" className={classes.cardText}>
            Agora com o projeto aprovado pelo cliente é só publicar o projeto e configurar o domínio. <span>Pague somente nessa etapa!</span>
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={12} md={6} align="center" className={classes.mdOrder0}>
          <video className={classes.video} width="100%" autoPlay muted playsinline loop>
            <source src={webmPublish} type="video/webm" />
            <source src={mp4Publish} type="video/mp4" />
          </video>
        </Grid>
      </Grid>

    </Container>
  );
}

export default withStyles(styles)(HowItWorks);
