
const styles = () => ({

  loading: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 50,
  },
});

export default styles;
