import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import SitesDashboard from '../../components/SitesDashboard';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SpeedDialCreateSite from '../../components/SpeedDialCreateSite';

import styles from './styles';
import Profile from '../../components/Profile';
import { Creators as actions } from '../../store/ducks/user';

// import { Container } from './styles';

import Drawer from '../../components/Drawer';

function SiteHome({ classes }) {
  // const token = useSelector((state) => state.user.token);
  const me = useSelector((state) => state.user.me);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getMeRequest());
  }, []);

  return (
    <div className={classes.root}>
      
      

      <Drawer children={<SitesDashboard />} />
      {/* <SpeedDialCreateSite /> */}

    </div>
  );
}


export default withStyles(styles)(SiteHome);
