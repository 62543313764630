const font = {
  fontSize: 16,
  fontFamily: 'Roboto',
};
const colors = {
  primary: '#000',
  secondary: '#0e9bd4',
};

const contentSize = {
  maxWidth: 1080,
  width: 'calc(100% - 40px)',
  padding: '0 20px',
  margin: '0 auto',
};

export default { font, colors, contentSize };
