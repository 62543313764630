/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../../services/api';
import { Creators as actions } from '../../../../store/ducks/sites';

import styles from './styles';

function ModalEditDomain({
  classes, open, setOpen, site, oldDomain
}) {
  const dispatch = useDispatch();
  const [domain, setDomain] = useState(oldDomain);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  async function handleAtt(){
    setLoading(true);

    try{
      await  api.put(`/user/update/site/${site.site_name}`,{
        site_domain: domain
      });

      dispatch(actions.getSitesRequest());

      setOpen(!open);

    }catch(error) {
      setError(true);
    }
    
    setLoading(false);
  }

  function resetModal(){
    setError(false);
  }

 

  return (


    <Dialog open={open} onEnter={() => resetModal()} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Domínio personalizado</DialogTitle>
      <DialogContent>
        <Typography variant="span" color="error" component="body1">
          Para voltar ao domínio padrão salve o campo a seguir vazio.
        </Typography>
        <ValidatorForm  onSubmit={() => handleAtt()}>
            <Grid container justify="center" spacing={2}>


              <Grid item xs={12} >
                <TextValidator
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                  margin="dense"
                  label="Novo Domínio"
                  type="text"
                  fullWidth
                />
              </Grid>


              <Grid item xs={12} >
                <DialogContentText className={classes.content}>
                  1	- Faça login em seu provedor de hospedagem de domínio, vá até a seção de gerenciamento de domínios e acesse as opções de alteração de DNS. <br/>
                  <br/>Criar um novo registro CNAME com as seguintes configurações:
                  <br/>• Host / Alias: www
                  <br/>• Destino / Aponta para: s.multiscreensite.com
                  <br/><br/>2 -	Aponte a raiz do seu domínio (domain.com) para o seu site. Para isso, crie dois A-Records com as seguintes configurações:
                  <br/>Primeiro
                  <br/>• Host / Alias: @
                  <br/>• Destino / Aponta para: 35.172.94.1

                  <br/><br/>Segundo
                  <br/>• Host / Alias: @
                  <br/>• Destino / Aponta para: 100.24.208.97
              </DialogContentText>
              </Grid>

              <Grid item xs={12} >
            
                { error ?
                  <Typography variant="span" color="error" component="body1">
                    Domínio invalido ou ja existente
                  </Typography>
                  : null
                  
                }
            
              </Grid>
            

              <DialogActions>
              { loading ? (
                  <Grid item xs={12} className={classes.loading}>
                    <CircularProgress />
                  </Grid>
              ) : <>
                  <Button color="primary" onClick={() => setOpen(false)}>
                  Cancelar
                  </Button>
                  <Button color="primary" type="submit" >
                    Mudar domínio
                  </Button>
                </>}
            </DialogActions>
            

            </Grid>


          </ValidatorForm>


      </DialogContent>

    </Dialog>

  );
}

export default withStyles(styles)(ModalEditDomain);
