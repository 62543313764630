import themeEvah from '../../assets/theme';

const styles = (theme) => ({

  btnGoogle: {
    width: '100%',
    height: '35px',
    borderRadius: '4px',
    display: 'flex',
    background: '#db3236',
    color: 'white',
    border: '0px transparent',
    textAlign: 'center',
  },
  submit: {
    background: '#DB4437',
  },
  container: {
    paddingTop: 20,
  },
});

export default styles;
