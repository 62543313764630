import themeEvah from '../../assets/theme';

const styles = (theme) => ({
  perfil: {
    background: '#F5F5F5 0% 0% no-repeat padding-box',
    boxShadow: '5px 0px 6px #0000003B',
    opacity: 1,  

  
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    paddingLeft: '10%',
    paddingRight: '10%',
    
  },
  profile: {
    paddingTop: 20,
    paddingBottom: 20,
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 50,
    width: 50,
    borderRadius: '50%',
  },
  profileInfo: {
    marginLeft: 10,
  },

  divider: {
    border: 'none',
    height: 2,
    margin: 0,
    flexShrink: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  options: {
    paddingTop: 20,
    paddingBottom: 20,

    display: 'flex',
    flexDirection: 'column',
  },
  optionButton: {
    textAlign: 'left',
    color: '#343434',
    opacity: 1,
    fontSize: 16,
    background: 'none',
    border: 'none',
    paddingTop: 5,
    paddingBottom:5
  },



  column: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  cardContent: {
    paddingBottom: 30,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  
  name: {
    fontWeight: '600',
    marginBottom: 4,
  },
  cardActions: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  link: {
    textTransform: 'none',
    color: themeEvah.colors.primary,
    transition: '0.2s',
    cursor: 'pointer',

    '&:hover': {
      color: themeEvah.colors.secondary,
      backgroundColor: 'transparent',
    },
  },
});


export default styles;
