import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Container, SvgIcon, Icon } from '@material-ui/core';
import { loadCSS } from 'fg-loadcss';
import { Creators as actions } from '../../store/ducks/user';

import firebase, { provider } from '../../services/firebase';

import Google from '../../assets/google.svg';

import Styles from './styles';


function SignInGoogle({ classes }) {
  const [error, setError] = useState(null);

  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    firebase.signInWithPopup(provider)
      .then((result) => {
        console.log(result);
        // setError(null);
        // this.props.history.push(ROUTES.HOME);
      })
      .catch((error) => {
        console.log(error);
        // setError(error);
      });
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>

      <CssBaseline />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={onSubmit}
        className={classes.submit}
        startIcon={(
          <Icon className="fab fa-google" />
        )}
      >
        Cadastrar com Google
      </Button>
    </Container>
  );
}

export default withStyles(Styles)(SignInGoogle);
