import themeEvah from '../../../assets/theme';

const styles = (theme) => ({
  root: {
  },
  bannerText: {
    padding: '90px 30px 80px 10%',

    [theme.breakpoints.down('sm')]: {
      padding: '40px 20px',
    },
  },
  bannerForm: {
    background: 'transparent linear-gradient(141deg, #11BDFF 0%, #009BD6 100%) 0% 0% no-repeat padding-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginBottom: theme.spacing(3),
    lineHeight: '1.3',
    fontFamily: 'Open Sans',
    color: '#333',

    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },

    '& span': {
      fontWeight: 700,
    },
  },
  subtitle1: {
    color: '#333',
    fontFamily: 'Open Sans',
    fontSize: 34,
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
    },
  },
  subtitle2: {
    color: '#333',
    fontFamily: 'Open Sans',
    fontSize: 34,
    marginBottom: theme.spacing(10),

    '& span': {
      fontWeight: 700,
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
      marginBottom: 30,
    },

  },
  form: {
    background: '#fff',
    display: 'flex',
    flexBasis: 450,
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 10,
    boxShadow: '3px 3px 6px rgba(0, 0, 0, .16)',
    padding: theme.spacing(3),
    margin: '32px 10px',
  },
  formTitle1: {
    fontFamily: 'Open Sans',
    fontWeight: '700',
    color: '#333',
    fontSize: 34,
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
    },
  },
  formTitle2: {
    fontFamily: 'Open Sans',
    fontWeight: '600',
    color: '#333',
    fontSize: 34,
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
    },
  },
  submitButton: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1),
    fontFamily: 'Open Sans',
    fontSize: 22,
    textTransform: 'none',
    background: '#02569C',
  },
  ilustration: {
    width: '100%',
    height: 'auto',
    maxWidth: '400px',
  },
});


export default styles;
