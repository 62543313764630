import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clx from 'clsx';

import classnames from 'classnames';
import { Creators as actions } from '../../store/ducks/user';
import styles from './styles';
import Logo from '../../assets/logo-evah.svg';
import { textAlign } from '@material-ui/system';


function Header({ classes , hide}) {
  const Signed = useSelector((state) => state.user.token);


  const dispatch = useDispatch();

  const redirect = useHistory();


  function handleLogout() {
    dispatch(actions.getLogoutRequest());
  }
  function handleDashboard() {
    redirect.push('/dashboard');
  }


  function handleLogin() {
    redirect.push('/login');
  }

  function handleRegister() {
    redirect.push('/register');
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={ classes.header}>
        <Toolbar justify="center"  className={ clx (classes.contentSize , hide ? classes.center : null )}>
          <Grid  className={classes.logoWrapper}>
            <Link to="/">
              <img src={Logo} className={classes.logo} alt="logo EVAH" />
            </Link>
          </Grid>

        

         {!hide &&  ( <>
         <Hidden smDown >
            <Grid className={classes.nav}>
              <Button className={classes.navLink} href="#produto">
                Produto
              </Button>
              <Button className={classes.navLink} href="#templates">
                Templates
              </Button>
              <Button className={classes.navLink} href="#planos">
                Planos
              </Button>
            </Grid>
          </Hidden>

          <Grid className={classes.filler} />
         
          {
            !Signed ? (
              <>
                <Button variant="contained" onClick={() => handleRegister()} className={classnames(classes.button, classes.blueButton)}>
                  Experimente Grátis
                </Button>
                <Button variant="contained" onClick={() => handleLogin()} className={classnames(classes.button, classes.whiteButton)}>
                  Entrar
                </Button>
              </>
            ) : (


              <>
                <Button variant="contained" onClick={() => handleDashboard()} className={classnames(classes.button, classes.blueButton)}>
                      Dashboard
                </Button>

                <Button variant="contained" onClick={() => handleLogout()} className={classnames(classes.button, classes.whiteButton)}>
                    Sair
                </Button>
              </>
            )
          }
          </>)}


        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withStyles(styles)(Header);
