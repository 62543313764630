import axios from 'axios';

const api = axios.create({
  // baseURL: 'http://192.168.0.126:3000/',
  // baseURL: 'http://192.168.0.110:3000/',
  baseURL: 'https://service.evah.io/',
});
api.interceptors.request.use(async (config) => {
  const token = null;
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});


export default api;
