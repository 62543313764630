/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';

import { Creators as actions } from '../../store/ducks/sites';

import styles from './styles';

import CreateSite from './CreateSite';
import TemplateList from './TemplateList';


function ModalCreateSite({ classes, open, setOpen }) {
  const dispatch = useDispatch();
  const [paginate, setPaginate] = useState(0);
  const [template, setTemplate] = useState(null);

  const [siteData, setSiteData] = useState({
    phone_number: '',
    business_name: '',
    email: '',
    site_domain: '',
    zip_code: '',
    street: '',
    city: '',
    state: '',
    country: '',
    number: '',
    bairro: '',
  });

  function handleChange(event, name) {
    setSiteData({ ...siteData, [name]: event.target.value });
  }

  const loading = useSelector((state) => state.sites.loading);


  // eslint-disable-next-line no-shadow
  const error = useSelector((state) => state.sites.errorCreateSite);

  useEffect(() => {
    if (error === false && open) setOpen(false);
  }, [error]);


  function resetModal() {
    setPaginate(0);
    setSiteData({
      phone_number: '',
      business_name: '',
      email: '',
      site_domain: '',
      zip_code: '',
      street: '',
      city: '',
      state: '',
      country: '',
      number: '',
      bairro: '',
    });
  }

  async function getCEP(cep) {
    const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
    setTimeout(() => {
      setSiteData({
        ...siteData,

        street: data.logradouro,
        city: data.localidade,
        state: data.uf,
        bairro: data.bairro,
        country: 'Brasil',
      });
    }, 500);
  }

  function handleCreate() {
    const address = {
      zip_code: siteData.zip_code,
      country: siteData.country,
      street: `${siteData.number} ${siteData.street}`,
      city: siteData.city,
      state: siteData.state,
    };

    dispatch(actions.createSiteRequest({
      phone_number: siteData.phone_number,
      business_name: siteData.business_name,
      email: siteData.email,
      site_domain: `${siteData.business_name.replace(/[ ]/g, '-')}.evah.io`,
      address,
      template_id: template,
    }));
  }


  return (
    <Dialog
      open={open}
      fullScreen
      onEnter={() => resetModal()}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
    >

      <DialogTitle id="form-dialog-title">Criar um Novo Site</DialogTitle>


      <DialogContent className={classes.dialog}>
        {error ? (
          <DialogContentText>
            <Typography variant="span" color="error" component="body1">
              Nome invalido ou ja existente, verifique e tente novamente.
            </Typography>
          </DialogContentText>
        ) : null}

        <DialogContentText>
          {
            paginate === 0 ? 'Preencha os campos abaixo para a criacão do site.' : ''

          }
        </DialogContentText>

        {
          paginate === 1
            ? (
              <CreateSite
                siteData={siteData}
                setSiteData={handleChange}
                paginate={paginate}
                setPaginate={setPaginate}
                setOpen={setOpen}
                getCEP={getCEP}
                handleCreate={handleCreate}
                loading={loading}
              />
            )
            : (
              <TemplateList
                template={template}
                setTemplate={setTemplate}
                paginate={paginate}
                setPaginate={setPaginate}
                setOpen={setOpen}
              />
            )
        }


      </DialogContent>

      {
        loading ? (
          <Grid item xs={12} className={classes.loading}>
            <CircularProgress />
          </Grid>
        ) : (

          <>
            {
                paginate === 0 ? (
                  <DialogActions style={{ padding: 30 }}>
                    <Button variant="contained" onClick={() => setOpen(false)} color="primary">
                      Cancelar
                    </Button>
                    <Button variant="contained" onClick={() => setPaginate(1)} color="primary">
                      Próximo
                    </Button>
                  </DialogActions>


                ) : null
}
          </>
        )
      }
    </Dialog>

  );
}

export default withStyles(styles)(ModalCreateSite);
