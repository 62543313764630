import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Creators as actions } from '../../store/ducks/user';


import Styles from './styles';


function SignIn({ classes }) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const errorLogin = useSelector((state) => state.user.errorLogin);
  const token = useSelector((state) => state.user.token);
  const loading = useSelector((state) => state.user.loading);

  const redirect = useHistory();


  useEffect(() => {
    if (token && token !== null) {
      redirect.push('/dashboard');
    }
  }, [token]);


  function handleSubmit(e) {
    e.preventDefault();
    dispatch(actions.getLoginRequest({ email, password }));
  }
  return (
    <>
      <CssBaseline />
      <div className={classes.paper}>
        <ValidatorForm className={classes.form} onSubmit={(e) => handleSubmit(e)}>
          <TextValidator
            variant="filled"
            margin="normal"

            fullWidth
            id="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            autoComplete="email"
            autoFocus
            validators={['required', 'isEmail']}
            errorMessages={['Este campo é obrigatorio', 'Email não valido']}
          />
          <TextValidator
            variant="filled"
            margin="normal"
            fullWidth
            name="password"
            label="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            validators={['required']}
            errorMessages={['Este campo é obrigatorio']}
            type="password"
            id="password"
            autoComplete="current-password"
          />

          <Typography align="right" variant="body1">
            <a href="/recovery" className={classes.register}>
              Esqueceu a senha?
            </a>
          </Typography>


          {loading

            ? (
              <Grid item xs={12} className={classes.loading}>
                <CircularProgress />
              </Grid>
            )
            : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Login
              </Button>
            )}

          {/*  <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Esqueceu a senha?
              </Link>
            </Grid>
          </Grid> */}
        </ValidatorForm>
      </div>

      {errorLogin ? (
        <Typography variant="body1" color="error">
          Erro no login , verifique seus dados e tente novamente.
        </Typography>
      ) : null}

    </>
  );
}

export default withStyles(Styles)(SignIn);
