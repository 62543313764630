import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';

import styles from './styles';
import CTAImage from '../../../assets/cta.png';

function Contrato({ classes }) {
  return (
    <div className={classes.root}>
      <Grid container xs={12} className={classes.imageWrapper} justify="center">
        <img src={CTAImage} className={classes.CTAImage} alt="" />
      </Grid>

      <Typography variant="h4" component="h3" className={classes.CTAtitle} align="center">
        Assuma o controle do <span className={classes.bold}>crescimento</span>
      </Typography>
      <Typography variant="h4" component="h3" className={classes.CTAtitle} align="center">
        do seu <span className={classes.regular}>negócio</span> com <span className={classes.blue}>Evah</span>
      </Typography>

      <Grid className={classes.buttonWrapper} align="center">
        <Button variant="contained" className={[classes.button, classes.blueButton]}>
          Contrate
        </Button>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(Contrato);
