const font = {
  fontSize: 16,
  fontFamily: 'Roboto',
};
const colors = {
  primary: '#000',
  secondary: '#0e9bd4',
};
const contentSize = {
  maxWidth: 1200,
  width: '100%',
  paddingLeft: 20,
  paddingRight: 20,
  margin: '0 auto',
};
export default { font, colors, contentSize };
