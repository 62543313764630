export const Types = {
  LOGOUT_REQUEST: 'user/LOGOUT_REQUEST',
  LOGIN_REQUEST: 'user/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'user/LOGIN_SUCCESS',
  LOGIN_ERROR: 'user/LOGIN_ERROR',
  GET_ME_REQUEST: 'user/GET_ME_REQUEST',
  GET_ME_SUCCESS: 'user/GET_ME_SUCCESS',
  REGISTER_REQUEST: 'user/REGISTER_REQUEST',
  REGISTER_SUCCESS: 'user/REGISTER_SUCCESS',
  RESEND_EMAIL_REQUEST: 'user/RESEND_EMAIL_REQUEST',
  RESEND_EMAIL_SUCCESS: 'user/RESEND_EMAIL_SUCCESS',
  REGISTER_ERROR: 'user/REGISTER_ERROR',
  RECOVERY_SUCCESS: 'user/RECOVERY_SUCCESS',
  RECOVERY_REQUEST: 'user/RECOVERY_REQUEST',
  RECOVERY_ERROR: 'user/RECOVERY_ERROR',
  GET_ME_UPLOAD_REQUEST: 'user/GET_ME_UPLOAD_REQUEST',
  GET_ME_UPLOAD_SUCCESS: 'user/GET_ME_UPLOAD_SUCCESS',
  ERROR: 'user/ERROR',
};

const INITAL_STATE = {
  loading: false,
  me: null,
  token: null,
  ssolink: '',
  errorLogin: null,
  errorCreate: null,
  errorRecovery: null,
};

export default function user(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.LOGIN_REQUEST:
      return { ...state, loading: true, errorLogin: null };
    case Types.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload.data.token,
        // me: action.payload.data.me,
        ssolink: action.payload.data.ssolink,
      };

    case Types.LOGOUT_REQUEST:
      return {
        loading: false,
        me: null,
        token: null,
        ssolink: '',
        errorLogin: null,
        errorCreate: null,
      };
    case Types.GET_ME_REQUEST:
      return { ...state, loading: true };
    case Types.GET_ME_SUCCESS:
      return {
        ...state, loading: false, me: action.payload.data,
      };

    case Types.RECOVERY_REQUEST:
      return { ...state, loading: true, errorRecovery: null };
    case Types.RECOVERY_SUCCESS:
      return { ...state, loading: false, errorRecovery: false };
    case Types.GET_ME_UPLOAD_REQUEST:
      return { ...state, loading: true };
    case Types.GET_ME_UPLOAD_SUCCESS:
      return { ...state, loading: false };
    case Types.RECOVERY_ERROR:
      return { ...state, loading: false, errorRecovery: true };
    case Types.LOGIN_ERROR:
      return { ...state, loading: false, errorLogin: true };

    case Types.RESEND_EMAIL_REQUEST:
      return { ...state, loading: true };


    case Types.RESEND_EMAIL_SUCCESS:
      return { ...state, loading: false };
    case Types.REGISTER_REQUEST:
      return { ...state, loading: true, errorCreate: null };
    case Types.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload.data.token,
        me: action.payload.data.me,
        ssolink: action.payload.data.ssolink,
        errorCreate: false,
      };
    case Types.REGISTER_ERROR:
      return { ...state, loading: false, errorCreate: true };
    case Types.ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export const Creators = {
  getLogoutRequest: () => ({
    type: Types.LOGOUT_REQUEST,
  }),
  getLoginRequest: (data) => ({
    type: Types.LOGIN_REQUEST,
    payload: { data },
  }),
  getMeUploadRequest: (photo) => ({
    type: Types.GET_ME_UPLOAD_REQUEST,
    payload: { photo },
  }),
  getMeUploadSuccess: () => ({
    type: Types.GET_ME_UPLOAD_SUCCESS,
  }),
  getLoginSuccess: (data) => ({
    type: Types.LOGIN_SUCCESS,
    payload: { data },
  }),
  getMeRequest: () => ({
    type: Types.GET_ME_REQUEST,
  }),
  getMeSuccess: (data) => ({
    type: Types.GET_ME_SUCCESS,
    payload: { data },
  }),
  getLoginError: () => ({
    type: Types.LOGIN_ERROR,
  }),
  getRegisterRequest: (data) => ({
    type: Types.REGISTER_REQUEST,
    payload: { data },
  }),
  getRegisterSuccess: (data) => ({
    type: Types.REGISTER_SUCCESS,
    payload: { data },
  }),

  getResendEmailRequest: () => ({
    type: Types.RESEND_EMAIL_REQUEST,
  }),
  getResendEmaiSuccess: () => ({
    type: Types.RESEND_EMAIL_SUCCESS,
  }),
  getRegisterError: () => ({
    type: Types.REGISTER_ERROR,
  }),
  getRecoveryPassRequest: (email) => ({
    type: Types.RECOVERY_REQUEST,
    payload: { email },

  }),
  getRecoveryPassSuccess: () => ({
    type: Types.RECOVERY_SUCCESS,
  }),
  getRecoveryError: () => ({
    type: Types.RECOVERY_ERROR,

  }),
  getError: () => ({
    type: Types.ERROR,
  }),
};
