import { black } from 'ansi-colors';

const useStyles = (theme) => ({
  logoWrapper: {
    width: '100%',
  },

  logo: {
    height: 40,
    width: 100,
    borderRadius: 4,
    backgroundColor: 'black',
    padding: 4,
  },

  root: {
    height: '100%',
  },
  register: {
    textDecoration: 'none',
    color: '#0e9bd4',
    fontWeight: 'bold',
    '&:hover': {
      color: '#0b7ba8',
    },
  },

  registerGroup: {
    fontWeight: 'bold',
    color: '#617379',
  },
  formTitle: {
    fontWeight: 600,
    fontSize: 35,
    fontFamily: 'Open Sans',
  },
  image: {
    /* backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#eee',
    backgroundSize: 'cover',
    backgroundPosition: 'center', */
    background: '#0e9bd4',
    height: '100%',
    paddingTop: '5%',
    paddingBottom: '5%',
    paddingRight: '15%',
  },
  paper: {
    height: '100%',
    padding: theme.spacing(5, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 0,
  },
  signUpWrapper: {
    marginTop: '-80px',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  leftPaper: {
    height: '100%',
    boxShadow: '10px 0 20px rgba(0,0,0,.2)',
    zIndex: 1,
    marginTop: 0,
  },

  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  observation: {
    marginTop: 40,
  },
  observation2: {
    marginTop: 20,
  },
  video: {
    border: '2px solid #707070',
    borderRadius: 5,
  },
  selector: {
    position: 'absolute',
    bottom: 40,
  },
  videoWrapper: {
    marginTop: '-90px',
  },
  containerTitle: {
    fontFamily: 'Avenir Medium',
    fontSize: 39,
  },
  containerText: {
    fontFamily: 'Open Sans',
    color: '#333',
    width: 600,
    margin: '15px 0 30px',
  },
});

export default useStyles;
