/* eslint-disable camelcase */
import React from 'react';
import {
  Grid, Button, DialogActions, TextField, DialogTitle, withStyles,CircularProgress
} from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import { TextMaskCEP } from '../../MaskInput';

import styles from './styles';

function CreateSite({
  siteData, setSiteData, classes, paginate, setPaginate, handleCreate, getCEP,loading
}) {
  function handleSubmit(e) {
    e.preventDefault();


    handleCreate();
  }


  return (
    <>
      <Grid container justify="center" spacing={2} className={classes.content}>

        <ValidatorForm className={classes.form} onSubmit={(e) => handleSubmit(e)}>
          <Grid item xs={12}>
            <DialogTitle className={classes.title} id="form-dialog-title">Dados do site</DialogTitle>
          </Grid>
          <Grid item xs={12}>
            <TextValidator
              value={siteData.business_name}
              onChange={(e) => setSiteData(e, 'business_name')}
              margin="dense"
              id="business_name"
              label="Nome do seu site"
              type="text"
              fullWidth
              validators={['required']}
              errorMessages={['Este campo é obrigatorio']}
            />
          </Grid>


          {/*  <Grid item xs={12}>
            <TextValidator
              value={siteData.site_domain}
              onChange={(e) => setSiteData(e, 'site_domain')}
              margin="dense"
              id="site_domain"
              label="Domínio do seu site"
              type="text"
              validators={['required']}
              errorMessages={['Este campo é obrigatorio']}
              fullWidth
            />
          </Grid> */}

          {/*      <Grid item xs={12} sm={6}>
          <TextField
            value={siteData.phone_number}
            onChange={(e) => setSiteData(e, 'phone_number')}
            margin="dense"
            id="phone_number"
            label="Telefone"
            type="phone_number"
            InputProps={{
              inputComponent: TextMaskPhone,
            }}
            fullWidth
          />

        </Grid>

          <Grid item xs={12} sm={6}>
          <TextField
            value={siteData.email}
            onChange={(e) => setSiteData(e, 'email')}
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
          />

        </Grid>

          <Grid item xs={12} sm={6} />

          <Grid item xs={12}>
          <TextField
            value={siteData.site_domain}
            onChange={(e) => setSiteData(e, 'site_domain')}
            margin="dense"
            id="site_domain"
            label="Domínio do seu site"
            type="text"
            fullWidth
          />
        </Grid>


      </Grid> */}

          <Grid item xs={12}>
            <DialogTitle className={classes.title} id="form-dialog-title">Endereço do seu negócio (Opcional)</DialogTitle>
          </Grid>

          <Grid item xs={12} sm={6} style={{ paddingRight: 15 }}>
            <TextField
              value={siteData.zip_code}
              onChange={(e) => {
                setSiteData(e, 'zip_code');
                if (e.target.value.length === 8) getCEP(e.target.value);
              }}

              margin="dense"
              id="zip_code"
              label="CEP"
              type="zip_code"
              fullWidth
              InputProps={{
                inputComponent: TextMaskCEP,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              value={siteData.bairro}
              onChange={(e) => setSiteData(e, 'bairro')}
              margin="dense"
              id="bairro"
              label="Bairro"
              type="text"
              fullWidth
            />

          </Grid>

          <Grid item xs={6} style={{ paddingRight: 15 }}>
            <TextField
              value={siteData.street}
              onChange={(e) => setSiteData(e, 'street')}
              margin="dense"
              id="street"
              label="Rua"
              type="text"
              fullWidth
            />

          </Grid>
          <Grid item xs={6}>
            <TextField
              value={siteData.number}
              onChange={(e) => setSiteData(e, 'number')}
              margin="dense"
              id="number"
              label="Número"
              type="number"
              fullWidth
            />

          </Grid>
          <Grid item xs={6}>
            <TextField
              value={siteData.city}
              onChange={(e) => setSiteData(e, 'city')}
              margin="dense"
              id="city"
              label="Cidade"
              type="text"
              fullWidth
            />
          </Grid>

          <Grid item xs={6} style={{ paddingRight: 15 }}>
            <TextField
              value={siteData.state}
              onChange={(e) => setSiteData(e, 'state')}
              margin="dense"
              id="state"
              label="Estado"
              type="text"
              fullWidth
            />

          </Grid>

          <Grid item xs={6}>
            <TextField
              value={siteData.country}
              onChange={(e) => setSiteData(e, 'country')}
              margin="dense"
              id="country"
              label="País"
              type="text"
              fullWidth
            />

          </Grid>


          <DialogActions style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            padding: 30,
            width: '100%',
            background: 'white',
          }}
          >

            <Button variant="contained" disabled={loading} color="primary" onClick={() => { setPaginate(paginate - 1); }}>
              Voltar
            </Button>

            <Button variant="contained" disabled={loading} color="primary" type="submit">
              Criar Site
            </Button>


          </DialogActions>


        </ValidatorForm>

      </Grid>


    </>
  );
}

export default withStyles(styles)(CreateSite);
