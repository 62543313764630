import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';

import Header from '../Header';
import styles from './styles';


import checkIcon from '../../assets/icons/check.svg';
import profileIcon from '../../assets/icons/profile-icon.svg';
import settingIcon from '../../assets/icons/settings-icon.svg';
import helpIcon from '../../assets/icons/help-icon.svg';
import logoutIcon from '../../assets/icons/logout-icon.svg';

import { Creators as actions } from '../../store/ducks/user';
import ModalCreateSite from '../ModalCreateSite';
import ModalEditProfile from '../ModalEditProfile';
import ConfirmationEmailSend from './ConfirmationEmailSend';


function DrawerComponent({ classes, children }) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user.me);
  const sites = useSelector((state) => state.sites.sites);
  const [sendEmail, setSendEmail] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [picture, setPicture] = useState('');
  const [openSendEmail, setOpenSendEmail] = useState(true);
  const [openEditProfile, setOpenEditProfile] = useState(false);
  const [open, setOpen] = useState(false);

  let inputUpload;

  useEffect(() => {
    if (window.innerWidth > 800) {
      setOpenDrawer(true);
    }
  }, []);

  function resendEmail() {
    dispatch(actions.getResendEmailRequest());
    setSendEmail(true);
  }

  function handleLogout() {
    dispatch(actions.getLogoutRequest());
  }

  useEffect(() => {
    if (!me) {
      dispatch(actions.getMeRequest());
    }
  }, [me]);

  function handleLogout() {
    dispatch(actions.getLogoutRequest());
  }

  const handleUploadPhoto = (event) => {
    setPicture(URL.createObjectURL(event.target.files[0]));

    dispatch(actions.getMeUploadRequest(event.target.files[0]));
  };

  return (
    <div className={classes.root}>

      <AppBar position="fixed" className={classes.appBar}>
        <Header className={classes.appBar} />
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        open={openDrawer}
        style={openDrawer ? {} : { display: 'none' }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} />

        <List className={classes.list}>
          <ListItem className={classes.perfil}>
            <Badge
              overlap="circle"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}

              badgeContent={me && me.emailVerified ? <img src={checkIcon} className={classes.icon} /> : null}
            >
              <Avatar
                onClick={() => inputUpload.click()}
                src={picture !== '' ? picture : (me && (me.photo || 'https://api.adorable.io/avatars/285/abott@adorable.png'))}
                alt={me && me.name ? me.name : 'Sua foto'}
                className={classes.avatar}
              />
              <input
                hidden
                ref={(ref) => { inputUpload = ref; }}
                type="file"
                onChange={handleUploadPhoto}
                accept="image/png, image/jpeg"
              />
            </Badge>

            <div className={classes.perfilInfo}>
              <Typography noWrap align="left" className={classes.word} fullwidth variant="h6" style={{ fontWeight: 'bold' }}>
                {me ? me.name ? me.name : `${me.first_name} ${me.last_name}` : 'Seu nome'}
              </Typography>
              <Typography noWrap align="left" className={classes.word} variant="subtitle2">
                {me ? me.email : 'Seu email'}
              </Typography>
              <Typography noWrap align="left" color="textSecondary" variant="body2">
                {me && me.emailVerified ? 'Conta verificada' : 'Conta não verificada'}
              </Typography>

            </div>
          </ListItem>

          {
            me && !me.emailVerified && !sendEmail && (
              <ListItem className={classes.verify}>
                <Button variant="outlined" onClick={() => resendEmail()} size="small" className={classes.verifyButton}>
                  Verificar Conta
                </Button>
              </ListItem>
            )
          }

          <Divider className={classes.divider} />
          {/* <ListItem button className={classes.ListItem} onClick={() => setOpenEditProfile(true)}>
            <ListItemIcon><img src={profileIcon} className={classes.icon} /></ListItemIcon>
            <ListItemText primary="Editar perfil" />
          </ListItem>

          <ListItem button className={classes.ListItem}>
            <ListItemIcon><img src={settingIcon} className={classes.icon} /></ListItemIcon>
            <ListItemText primary="Configurações" />
          </ListItem>
          <ListItem button className={classes.ListItem}>
            <ListItemIcon><img src={helpIcon} className={classes.icon} /></ListItemIcon>
            <ListItemText primary="Ajuda" />
          </ListItem> */}
          <ListItem button className={classes.ListItem} onClick={() => handleLogout()}>
            <ListItemIcon><img src={logoutIcon} className={classes.icon} /></ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItem>

          <ListItem className={classes.lastButton}>
            <Button variant="contained" onClick={() => setOpen(true)} className={classes.createButton}>
              + Criar Site
            </Button>
          </ListItem>


        </List>


      </Drawer>

      <ModalEditProfile open={openEditProfile} setOpen={setOpenEditProfile} />

      <ModalCreateSite open={open} setOpen={setOpen} />

      {sendEmail && <ConfirmationEmailSend open={openSendEmail} setOpen={setOpenSendEmail} />}


      {window.innerWidth <= 800
        && (
          <Fab color="primary" aria-label="delete" className={classes.menuButton} onClick={() => setOpenDrawer(!openDrawer)}>
            {openDrawer ? <MenuOpenIcon /> : <MenuIcon />}
          </Fab>
        )}


      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

export default withStyles(styles)(DrawerComponent);
