/* eslint-disable no-shadow */
export const Types = {
  LOGOUT_REQUEST: 'user/LOGOUT_REQUEST',
  GET_SITES_REQUEST: 'sites/GET_SITES_REQUEST',
  GET_SITES_SUCCESS: 'sites/GET_SITES_SUCCESS',
  GET_SITES_ERROR: 'sites/GET_SITES_ERROR',
  GET_TEMPLATES_REQUEST: 'sites/GET_TEMPLATES_REQUEST',
  GET_TEMPLATES_SUCCESS: 'sites/GET_TEMPLATES_SUCCESS',
  GET_TEMPLATES_ERROR: 'sites/GET_TEMPLATES_ERROR',
  CREATE_SITE_REQUEST: 'sites/CREATE_SITE_REQUEST',
  CREATE_SITE_SUCCESS: 'sites/CREATE_SITE_SUCCESS',
  CREATE_SITE_FAILURE: 'sites/CREATE_SITE_FAILURE',
  EXCLUE_SITE_REQUEST: 'sites/EXCLUE_SITE_REQUEST',
  EXCLUE_SITE_SUCCESS: 'sites/EXCLUE_SITE_SUCCESS',
  EXCLUE_SITE_FAILURE: 'sites/EXCLUE_SITE_FAILURE',
  RESET_REQUEST: 'sites/RESET_REQUEST',

};

const INITAL_STATE = {
  loading: false,
  sites: [],
  errorGetSites: null,
  errorGetTemplates: null,
  errorCreateSite: null,
  errorExclueSite: null,
  exclueSiteLoading: null,
  loadingTemplates: null,
  templates: [],
};

export default function site(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_REQUEST:
      return {
        ...state,
        loading: false,
        errorGetSites: null,
        errorGetTemplates: null,
        errorCreateSite: null,
        errorExclueSite: null,
        exclueSiteLoading: null,
      };
    case Types.GET_SITES_REQUEST:
      return { ...state, loading: true };
    case Types.GET_SITES_SUCCESS:
      return { ...state, loading: false, sites: action.payload.sites };
    case Types.GET_SITES_ERROR:
      return { ...state, loading: false, errorLogin: true };
    case Types.GET_TEMPLATES_REQUEST:
      return { ...state, loadingTemplates: true };
    case Types.GET_TEMPLATES_SUCCESS:
      return { ...state, loadingTemplates: false, templates: action.payload.templates };
    case Types.GET_TEMPLATES_ERROR:
      return { ...state, loadingTemplates: false, errorGetTemplates: true };
    case Types.CREATE_SITE_REQUEST:
      return { ...state, loading: true, errorCreateSite: null };
    case Types.CREATE_SITE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      return { ...state, loading: false, errorCreateSite: false };
    case Types.CREATE_SITE_FAILURE:
      return { ...state, loading: false, errorCreateSite: true };
    case Types.EXCLUE_SITE_REQUEST:
      return { ...state, exclueSiteLoading: true, errorExclueSite: 'null' };
    case Types.EXCLUE_SITE_SUCCESS:
      return { ...state, exclueSiteLoading: false, errorExclueSite: false };
    case Types.EXCLUE_SITE_FAILURE:
      return { ...state, exclueSiteLoading: false, errorExclueSite: true };
    case Types.LOGOUT_REQUEST:
      return {
        loading: false,
        sites: [],
        errorGetSites: null,
        errorGetTemplates: null,
        errorCreateSite: null,
        errorExclueSite: null,
        exclueSiteLoading: null,
        templates: [],
        planos: [],
      };

    default:
      return state;
  }
}

export const Creators = {
  getReset: () => ({
    type: Types.RESET_REQUEST,
  }),
  getSitesRequest: () => ({
    type: Types.GET_SITES_REQUEST,
  }),
  getSitesSuccess: (sites) => ({
    type: Types.GET_SITES_SUCCESS,
    payload: { sites },
  }),
  getSitesError: () => ({
    type: Types.GET_SITES_ERROR,
  }),
  getTemplatesRequest: () => ({
    type: Types.GET_TEMPLATES_REQUEST,
  }),
  getTemplatesSuccess: (templates) => ({
    type: Types.GET_TEMPLATES_SUCCESS,
    payload: { templates },
  }),
  getTemplatesError: () => ({
    type: Types.GET_TEMPLATES_ERROR,
  }),
  createSiteRequest: (newSite) => ({
    type: Types.CREATE_SITE_REQUEST,
    payload: { newSite },
  }),
  createSiteSuccess: () => ({
    type: Types.CREATE_SITE_SUCCESS,
  }),
  createSiteFailure: () => ({
    type: Types.CREATE_SITE_FAILURE,
  }),
  exclueSiteRequest: (site, index) => ({
    type: Types.EXCLUE_SITE_REQUEST,
    payload: { site, index },
  }),
  exclueSiteSuccess: (index) => ({
    type: Types.EXCLUE_SITE_SUCCESS,
    payload: { index },
  }),
  exclueSiteFailure: () => ({
    type: Types.EXCLUE_SITE_FAILURE,
  }),
};
