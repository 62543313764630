import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import SnackBar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Creators as actions } from '../../../store/ducks/user';
import styles from './styles';
import Ilustration from '../../../assets/img-banner.svg';
import { TextMaskPhone } from '../../../components/MaskInput';

function Banner({ classes }) {
  const dispatch = useDispatch();

  const [user, setUser] = useState({
    name: '', email: '', phone: '', password: '', password2: '',
  });

  const loading = useSelector((state) => state.user.loading);
  const token = useSelector((state) => state.user.token);
  const error = useSelector((state) => state.user.errorCreate);

  const redirect = useHistory();

  useEffect(() => {
    if (token && token !== null) {
      redirect.push('/dashboard');
    }
  }, [token]);

  function handleChange(event, name) {
    setUser({ ...user, [name]: event.target.value });
  }

  function handleSubmit(event) {
    event.preventDefault();
    const { password2, ...rest } = user;
    dispatch(actions.getRegisterRequest(rest));
  }

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (error === true) {
      setOpen(true);
    }
  }, [error]);

  return (
    <Grid container className={classes.root}>

      <Grid item xs={12} md={7} className={classes.bannerText}>
        <Typography variant="h3" component="h2" className={classes.title}><span>Agência!</span> Saia do WordPress</Typography>
        <Typography variant="h4" component="h2" className={classes.subtitle1}>Gerencie, crie, edite e publique</Typography>
        <Typography variant="h4" component="h2" className={classes.subtitle2}>sites em um <span>único lugar.</span></Typography>
        <img src={Ilustration} className={classes.ilustration} />
      </Grid>

      <Grid item xs={12} md={5} className={classes.bannerForm}>

        <ValidatorForm
          onSubmit={(e) => handleSubmit(e)}
          className={classes.form}
        >
          <Grid xs={12}>
            <Typography variant="h4" className={classes.formTitle1}>Crie um site. </Typography>
            <Typography variant="h4" className={classes.formTitle2}>Teste agora!</Typography>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={12}>
              <TextValidator
                variant="outlined"
                margin="normal"
                fullWidth
                label="Agência"
                name="name"
                value={user.name}
                onChange={(e) => handleChange(e, 'name')}
                validators={['required']}
                errorMessages={['this field is required']}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                variant="outlined"
                margin="normal"
                fullWidth
                label="Telefone"
                name="phone"
                value={user.phone}
                onChange={(e) => handleChange(e, 'phone')}
                InputProps={{
                  inputComponent: TextMaskPhone,
                }}
                validators={['required', 'minStringLength:16', 'maxStringLength:16']}
                errorMessages={['this field is required']}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                variant="outlined"
                margin="normal"
                fullWidth
                label="Email"
                name="email"
                value={user.email}
                onChange={(e) => handleChange(e, 'email')}
                validators={['required', 'isEmail']}
                errorMessages={['this field is required', 'email is not valid']}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                variant="outlined"
                margin="normal"
                fullWidth
                label="Senha"
                type="password"

                name="password"
                validators={['required']}
                value={user.password}
                onChange={(e) => handleChange(e, 'password')}
                errorMessages={['this field is required']}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              {
                loading ? <CircularProgress /> : (
                  <Button
                    type="submit"
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                  > Criar Site
                  </Button>
                )
              }
            </Grid>

          </Grid>
        </ValidatorForm>

      </Grid>
      <SnackBar open={open} setOpen={setOpen} message="Verifique seu dados e tente novamente!" variant="error" />

    </Grid>
  );
}

export default withStyles(styles)(Banner);
