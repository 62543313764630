
import theme from '../../assets/themAlex';

const styles = () => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: '50px 15px!important',
    backgroundColor: '#f5f5f5!important',
    boxShadow: 'none!important',
    borderColor: '#d4d4d4!important',
    borderWidth: '0!important',
    borderStyle: 'solid!important',
    borderRadius: '10px!important',
    height: ' 74%',
  },

  title: {
    width: '100%',
    textAlign: 'center',
    fontWeight: 500,
    color: 'rgb(33, 33, 33)',
    fontSize: 30,
  },

  price: {
    marginTop: 10,
    marginBottom: 5,
    width: '100%',
    textAlign: 'center',
    fontWeight: 400,
    color: 'rgb(8, 131, 180)',
    fontSize: 30,
  },

  cto: {
    width: '100%',
    textAlign: 'center',
    color: 'rgb(33, 33, 33)',
    fontWeight: 300,
    fontFamily: 'sans-serif',
    paddingBottom: 15,
    borderBottom: '1px solid #e4e2e2',
  },

  itens: {
    width: '100%',
    margin: '20px auto',
  },

  item: {
    width: '100%',
    textAlign: 'center',
    color: 'rgb(33, 33, 33)',
    margin: '5px auto',
  },

  adicionais: {
    width: '100%',
  },

  adicional: {
    width: '100%',
    textAlign: 'center',
    color: 'rgb(33, 33, 33)',
    margin: '5px auto',
  },

  button: {
    background: '#0e9bd4',
    textDecoration: 'none',
    color: 'white',
    borderRadius: 5,
    textAlign: 'center',
    padding: '12px ',
  },

  allHeight: {
    height: '100%',
    minHeight: 210,
    width: '100%',
    marginBottom: 10,
  },


});

export default styles;
