
const styles = () => ({

  loading: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 50,
  },

  content: {
    color: 'black',
    fontSize: '0.9rem',
  }
});

export default styles;
