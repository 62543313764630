import React, { useState, useRef, useEffect } from 'react';
import {
  withStyles, Select, Grid, Card, CardActionArea, CardMedia, CardContent, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Hidden,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as actions } from '../../../store/ducks/sites';

/* import TemplateCard from './TemplateCard'; */

import templates from '../../../assets/templates';

import styles from './styles';

function TemplateList({ classes, template, setTemplate }) {
  const options = [{
    index: 'CUSTOM',
    name: 'Customizado',
  }, {
    index: 'Blog',
    name: 'Blog',
  }, {
    index: 'Business',
    name: 'Negócios',
  }, {
    index: 'LandingPage',
    name: 'LandingPage',
  }, {
    index: 'ProfessionalServices',
    name: 'Serviços profissionais',
  }, {
    index: 'RestaurantFood',
    name: 'Restaurantes',
  }, {
    index: 'Blank',
    name: 'Em branco',
  }, {
    index: 'OnlineStore',
    name: 'Loja online',
  }, {
    index: 'Events',
    name: 'Eventos',
  }, {
    index: 'PortfolioResume',
    name: 'Portfólio',
  }, {
    index: 'LifestyleHealth',
    name: 'Estilo de vida',
  }, {
    index: 'Travel',
    name: 'Viagens',
  }, {
    index: 'CommunityEducation',
    name: 'Educação',
  }];

  const [option, setOption] = useState('');

  const [temas, setTemas] = useState([]);
  /*
    const dispatch = useDispatch();


  const templates = useSelector((state) => state.sites.templates);

  useEffect(() => {
    if (!template && templates.length > 0) {
      setTemplate(templates[0].template_id);
    }
    if (typeof templates === 'undefined' || templates.length === 0) {
      dispatch(actions.getTemplatesRequest());
    }
  }, [templates]);
 */

  function selectedTheme(value2) {
    return value2.verticals.indexOf(option) !== -1;
  }

  useEffect(() => {
    if (option !== '') {
      setTemas(templates.filter(selectedTheme));
    } else {
      setTemas(templates);
    }
  }, [option]);

  useEffect(() => {
    if (temas[0]) setTemplate(temas[0].id);
  }, [temas]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2} style={{ overflowY: 'hidden', height: ' 100%' }}>
        <Grid item xs={12} sm={2} className={classes.select}>
          <Hidden mdUp style={{ height: ' fit-content' }}>
            <Select
              native

              value={option}
              onChange={(e) => setOption(e.target.value)}

            >
              {
                options && options.map((value) => (
                  <option value={value.index}>{value.name}</option>
                ))
              }
            </Select>
          </Hidden>
          <Hidden xsDown>
            <FormLabel component="legend">Categorias</FormLabel>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={option}
                defaultValue="Blog"
                onChange={(e) => setOption(e.target.value)}
              >
                <FormControlLabel value="" control={<Radio />} label="Todas" />
                {options.map((option) => (
                  <FormControlLabel value={option.index} control={<Radio />} label={option.name} />
                ))}

              </RadioGroup>
            </FormControl>
          </Hidden>

        </Grid>
        <Grid item xs={12} sm={10} className={classes.list}>

          <Grid container spacing={2}>
            {temas.map((value) => (
              <Grid item xs={12} sm={3}>
                <Card
                  className={value.id === template ? classes.cardSelected : classes.card}
                >
                  <CardActionArea
                    onClick={() => setTemplate(value.id)}
                    className={classes.cardActionArea}
                  >
                    <Grid container>
                      <Grid item xs={4} sm={12}>
                        <CardMedia
                          className={classes.cardMedia}
                          component="img"
                          alt="Salão de Beleza"
                          image={value.iconPath}
                          title="Salão de Beleza"
                        />
                      </Grid>
                      <Grid item xs={6} sm={12}>
                        <CardContent className={classes.cardContent}>
                          <Typography variant="subtitle1" component="h2" className={classes.templateName}>
                            {value.name}
                          </Typography>
                        </CardContent>
                      </Grid>

                    </Grid>


                  </CardActionArea>
                </Card>

              </Grid>

            ))}

            {/*   {
              templates && templates.map((value) => (

                <Grid item xs={12} sm={6}>
                  <Card
                    className={value.template_id === template ? classes.cardSelected : classes.card}
                  >
                    <CardActionArea
                      onClick={() => setTemplate(value.template_id)}
                      className={classes.cardActionArea}
                    >
                      <CardMedia
                        className={classes.cardMedia}
                        component="img"
                        alt="Salão de Beleza"
                        image={value.thumbnail}
                        title="Salão de Beleza"
                      />
                      <CardContent className={classes.cardContent}>
                        <Typography variant="subtitle1" component="h2" className={classes.templateName}>
                          {value.name}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))
            } */}

          </Grid>
        </Grid>
      </Grid>

    </div>
  );
}

export default withStyles(styles)(TemplateList);
