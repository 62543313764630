import theme from '../../assets/themAlex';

const ContentSize = {
  root: {
    display: 'flex',
  },
};

/* const bgGrey = {
  backgroundColor: '#eee',
}; */


export default { ContentSize };
