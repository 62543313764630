const templates = [
  {
    name: 'Contador',
    id: 1092790,
    order: -1980,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/608d29533a264674a08a4d94f08e0aef/siteTemplateIcons/OGZrUkt4QY6Ag6lphXTU_Accountant%20Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/608d29533a264674a08a4d94f08e0aef/siteTemplateIcons/RQD0KP2nTaGwJ7qtKmsl_Accountant%203%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/608d29533a264674a08a4d94f08e0aef/siteTemplateIcons/6ZT6r4HqRyymEIxHB4rv_Accountant Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/608d29533a264674a08a4d94f08e0aef/siteTemplateIcons/hAt1hQnQTc6odTOpEZaZ_Accountant Mobile.png',
    demoSiteUrl: '/site/dm-theme-1092790-pt-421?preview=true',
    verticals: [
      'ProfessionalServices',
      'Blog',
      'Business',
    ],
    colors: [
      'white',
      'red',
      'gray',
    ],
    isNew: 'true',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: true,
    hasStore: false,
    numOfPages: 5,
    isCustom: false,
  },
  {
    name: 'Academic',
    id: 1072891,
    order: -1970,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/a8cf44d1a8f24567a96ddfda84c6cb14/siteTemplateIcons/e9NVVeJDQgMREcgyv0ag_Academics_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/a8cf44d1a8f24567a96ddfda84c6cb14/siteTemplateIcons/Ot88v0U2RKre52CU9KL0_Academics_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/a8cf44d1a8f24567a96ddfda84c6cb14/siteTemplateIcons/HTPA9SBXSz6y60Ov5XeF_Academics_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/a8cf44d1a8f24567a96ddfda84c6cb14/siteTemplateIcons/67MKpYiJQV2Lu8YZPnxB_Academics_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1072891-pt-408?preview=true',
    verticals: [
      'CommunityEducation',
      'Blog',
    ],
    colors: [
      'white',
      'blue',
    ],
    isNew: 'true',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: true,
    hasStore: false,
    numOfPages: 13,
    isCustom: false,
  },
  {
    name: 'Plumber',
    id: 1069287,
    order: -1960,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/db3ca79dcf894ef08830ba51cc209ab4/siteTemplateIcons/gzKM5SllSzyWVgRlPOO8_Plumber_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/db3ca79dcf894ef08830ba51cc209ab4/siteTemplateIcons/FUpGU22RM9oNggwE30QQ_Plumber_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/db3ca79dcf894ef08830ba51cc209ab4/siteTemplateIcons/EPYHre72TE2tRWt7Bchf_Plumber_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/db3ca79dcf894ef08830ba51cc209ab4/siteTemplateIcons/AXmeLdXlTga5cqzChk0G_Plumber_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1069287-pt-408?preview=true',
    verticals: [
      'ProfessionalServices',
      'Blog',
    ],
    colors: [
      'white',
      'black',
      'yellow',
      'gray',
    ],
    isNew: 'true',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: true,
    hasStore: false,
    numOfPages: 8,
    isCustom: false,
  },
  {
    name: 'Gym',
    id: 1075961,
    order: 2147483647,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/fe32213d18584702b6483b30e63baf08/siteTemplateIcons/AfuCY77SfaE4IXSjfEGE_gym_template_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/fe32213d18584702b6483b30e63baf08/siteTemplateIcons/nd86P4LjSjuuP7vCI7Tw_gym_template__3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/fe32213d18584702b6483b30e63baf08/siteTemplateIcons/NzPaVWBR4uuNcBw5aa7x_gym_template_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/fe32213d18584702b6483b30e63baf08/siteTemplateIcons/QtFOYIaGR22GClUOHJRs_gym_template_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1075961-pt-408?preview=true',
    verticals: [
      'LifestyleHealth',
      'Blog',
      'Business',
    ],
    colors: [
      'white',
      'black',
      'yellow',
    ],
    isNew: 'true',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: true,
    hasStore: false,
    numOfPages: 6,
    isCustom: false,
  },
  {
    name: 'Eyewear Store',
    id: 1062978,
    order: -1955,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/03de722a79634690837e8d32ddc148ce/siteTemplateIcons/yvp6XxMRNqn38cA3N14A_Eyewear_Store_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/03de722a79634690837e8d32ddc148ce/siteTemplateIcons/7PVA4fWlSvGEOysleO9M_Eyewear_Store_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/03de722a79634690837e8d32ddc148ce/siteTemplateIcons/FmkIRatDSVemEckdCxZ5_Eyewear_Store_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/03de722a79634690837e8d32ddc148ce/siteTemplateIcons/aU93yziKRQ2OB3AMaNDK_Eyewear_Store_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1062978-pt-408?preview=true',
    verticals: [
      'LifestyleHealth',
      'OnlineStore',
    ],
    colors: [
      'green',
      'black',
      'light-blue',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: true,
    numOfPages: 6,
    isCustom: false,
  },
  {
    name: 'Hamburgueria',
    id: 1049888,
    order: -1950,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/c7a634f1579d47deb6967e3ddd4b9c21/siteTemplateIcons/96itr48SdCBan4ClLmfp_Burger_restaurant_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/c7a634f1579d47deb6967e3ddd4b9c21/siteTemplateIcons/ajt4Q7ENQWIuTmj9z4md_Burger_restaurant_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/c7a634f1579d47deb6967e3ddd4b9c21/siteTemplateIcons/CByy9AAoS2p6HIHxrVjO_Burger_restaurant_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/c7a634f1579d47deb6967e3ddd4b9c21/siteTemplateIcons/egyBCXpsSGSBLzCq8pwT_Burger_Restaurant_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1049888-pt-376?preview=true',
    verticals: [
      'RestaurantFood',
    ],
    colors: [
      'white',
      'black',
      'pink',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 5,
    isCustom: false,
  },
  {
    name: 'Webinar Landing Page',
    id: 1057637,
    order: -1945,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/6c930a7758e8476f835e0ef815872a43/siteTemplateIcons/2fM1QRpHTA1kPhJbdfT7_Webinar_LP_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/6c930a7758e8476f835e0ef815872a43/siteTemplateIcons/jsarzDLTE2pEiNVilA4r_Webinar_LP_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/6c930a7758e8476f835e0ef815872a43/siteTemplateIcons/stRtOsTFGtd6x5zmfV8w_Webinar_LP_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/6c930a7758e8476f835e0ef815872a43/siteTemplateIcons/M4MoQ6ZjQqm5NfnUbK4s_Webinar_LP_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1057637-pt-389?preview=true',
    verticals: [
      'Events',
      'LandingPage',
      'Business',
    ],
    colors: [
      'white',
      'blue',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 1,
    isCustom: false,
  },
  {
    name: 'Evento corporativo',
    id: 1048358,
    order: -1940,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/692851cacf5243fd8f5b304572393189/siteTemplateIcons/zoTbtG9gQnyhoh0siaU6_Corporate_event_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/692851cacf5243fd8f5b304572393189/siteTemplateIcons/8pz9fhMITzaPWnnBpjtS_Corporate_event_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/692851cacf5243fd8f5b304572393189/siteTemplateIcons/uytWfDeLTUyi3XKvIGEn_Corporate_event_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/692851cacf5243fd8f5b304572393189/siteTemplateIcons/cGq0cwfbQIy4HkNzPRf7_Corporatwe_event_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1048358-pt-389?preview=true',
    verticals: [
      'Events',
      'CommunityEducation',
    ],
    colors: [
      'white',
      'purple',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 1,
    isCustom: false,
  },
  {
    name: 'Eletricista',
    id: 1041738,
    order: -1920,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/f7bfac2723454c4699da338a529de3e2/siteTemplateIcons/yCTqXBtFTfakHAIEhtgc_Electrician_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/f7bfac2723454c4699da338a529de3e2/siteTemplateIcons/T0YlkPWjQEiodVVBtmAf_Electrician_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/f7bfac2723454c4699da338a529de3e2/siteTemplateIcons/Fef2sPhUS8u2SGH3Lbp6_Electrician_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/f7bfac2723454c4699da338a529de3e2/siteTemplateIcons/85M7VGbWTOWP9Msnc1ug_Electrician_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1041738-pt-389?preview=true',
    verticals: [
      'ProfessionalServices',
      'Business',
    ],
    colors: [
      'white',
      'blue',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Músico',
    id: 1038893,
    order: -1900,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/84da4cfe484e45a48b87b16939e93256/siteTemplateIcons/jCP4GtZIST6r6YDlr1ep_Musician_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/84da4cfe484e45a48b87b16939e93256/siteTemplateIcons/7Tle0KZS8KWEn8uJgmOA_Musicican_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/84da4cfe484e45a48b87b16939e93256/siteTemplateIcons/gbNQ0DQSfg8U8xmxtKgt_Musician_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/84da4cfe484e45a48b87b16939e93256/siteTemplateIcons/ATCY3sQiThSPZT9nn064_Musician_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1038893-pt-376?preview=true',
    verticals: [
      'Events',
      'CommunityEducation',
      'ProfessionalServices',
      'PortfolioResume',
    ],
    colors: [
      'green',
      'black',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 1,
    isCustom: false,
  },
  {
    name: 'Igreja',
    id: 1038889,
    order: -1880,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/f3f34afaf35a4e9591161da3491d140a/siteTemplateIcons/IjUoIkQ0SLS3Z17gIjiN_Church_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/f3f34afaf35a4e9591161da3491d140a/siteTemplateIcons/NCBE7RsvTTCVmMoQx93Y_Church_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/f3f34afaf35a4e9591161da3491d140a/siteTemplateIcons/zmCibApTT8WtykmikFoW_Curch_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/f3f34afaf35a4e9591161da3491d140a/siteTemplateIcons/tnOeKU2R0a48LSYIrT28_Church_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1038889-pt-389?preview=true',
    verticals: [
      'Events',
      'CommunityEducation',
      'Blog',
    ],
    colors: [
      'white',
      'brown',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: true,
    hasStore: false,
    numOfPages: 5,
    isCustom: false,
  },
  {
    name: 'Página de destino da vinícola',
    id: 1034487,
    order: -1860,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/1f298376b22d4d359f237661402178b7/siteTemplateIcons/EukjO7zYQZGHRrlXIF2m_Winery_lp_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/1f298376b22d4d359f237661402178b7/siteTemplateIcons/GgkhlrIaRPWOLztsoiBg_winery_lp_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/1f298376b22d4d359f237661402178b7/siteTemplateIcons/pSZtJxELTtGNU8XAaW3f_Winery_lp_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/1f298376b22d4d359f237661402178b7/siteTemplateIcons/oHws7Vn2TlGura9IUmHJ_Winery_lp_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1034487-pt-389?preview=true',
    verticals: [
      'Travel',
      'Events',
      'LandingPage',
      'Business',
    ],
    colors: [
      'light-green',
      'purple',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 1,
    isCustom: false,
  },
  {
    name: 'Coaching pessoal',
    id: 1031172,
    order: -1840,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/73f6bf9785874b59b16f17ff2975cce2/siteTemplateIcons/f3kT23WaQYpgszEVYTNN_Personal_coachDesktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/73f6bf9785874b59b16f17ff2975cce2/siteTemplateIcons/oFkrDmaOT7Sq176MN08K_Personal_coach_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/73f6bf9785874b59b16f17ff2975cce2/siteTemplateIcons/pgSn895cSUW3461Httfi_Personal_coach_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/73f6bf9785874b59b16f17ff2975cce2/siteTemplateIcons/7EXr1U82RVy8cru65w70_Personal_coach_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1031172-pt-389?preview=true',
    verticals: [
      'LifestyleHealth',
      'ProfessionalServices',
      'Blog',
      'PortfolioResume',
      'Business',
    ],
    colors: [
      'white',
      'blue',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: true,
    hasStore: false,
    numOfPages: 5,
    isCustom: false,
  },
  {
    name: 'Jardineiro',
    id: 1031169,
    order: -1820,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/e49e1f3d49e84749a63da16dc751c409/siteTemplateIcons/wubir1KSlOaxov6ioQbw_Gardener_Desktop2.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/e49e1f3d49e84749a63da16dc751c409/siteTemplateIcons/TnDIlZAtQbWClgnHIvx1_Gardener_3_devices.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/e49e1f3d49e84749a63da16dc751c409/siteTemplateIcons/3mpDuPvQQlWjLwxpBoxk_Gardener_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/e49e1f3d49e84749a63da16dc751c409/siteTemplateIcons/V1DO77JOR1CnoAbfTQiz_Gardener_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1031169-pt-389?preview=true',
    verticals: [
      'ProfessionalServices',
      'Business',
    ],
    colors: [
      'green',
      'white',
      'orange',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 6,
    isCustom: false,
  },
  {
    name: 'Espaço de trabalho',
    id: 1022517,
    order: -1780,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/218e11badd4149389e6f6c37d43b6414/siteTemplateIcons/kVq56lJpQ8yPwwQ7b6Cg_Workspace_Desktop2.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/218e11badd4149389e6f6c37d43b6414/siteTemplateIcons/yTasxvWeTqqaVPeVMjpP_Workspace_3Screens_Devices.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/218e11badd4149389e6f6c37d43b6414/siteTemplateIcons/j3j7pvQRCiDcItBhdRHj_Workspace_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/218e11badd4149389e6f6c37d43b6414/siteTemplateIcons/3imNylRr29zzEb2kk7AC_Workspace_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1022517-pt-389?preview=true',
    verticals: [
      'Events',
      'CommunityEducation',
      'Business',
    ],
    colors: [
      'white',
      'black',
      'yellow',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 5,
    isCustom: false,
  },
  {
    name: 'Estúdio de tatuagem',
    id: 1031173,
    order: -1760,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/89750e11c8e142358bc24ab6a7731a2a/siteTemplateIcons/Kvc9SUXTFCO5eUkwLg7W_Tattoo_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/89750e11c8e142358bc24ab6a7731a2a/siteTemplateIcons/GMBFa35DToeW3NUWSLqA_Tattoo_3%20Sreens%20Devices.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/89750e11c8e142358bc24ab6a7731a2a/siteTemplateIcons/0FtIJI83TbuuWP8xS1xD_Tattoo_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/89750e11c8e142358bc24ab6a7731a2a/siteTemplateIcons/6N7zWNhbQd6go25jpvGo_Tattoo_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1031173-pt-389?preview=true',
    verticals: [
      'LifestyleHealth',
      'PortfolioResume',
      'Business',
    ],
    colors: [
      'white',
      'black',
      'red',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 5,
    isCustom: false,
  },
  {
    name: 'Arquiteto',
    id: 1076030,
    order: -1740,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/36c9cca9c48e48e89ff0433c00481d9c/siteTemplateIcons/tnIEqlTXSbCBYW1ULzAd_Architect_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/36c9cca9c48e48e89ff0433c00481d9c/siteTemplateIcons/9i3KYLzTjCejl3MkSa4w_Architecture_3_devices.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/36c9cca9c48e48e89ff0433c00481d9c/siteTemplateIcons/kpzs5nFVR7qLQdsacENM_Architect_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/36c9cca9c48e48e89ff0433c00481d9c/siteTemplateIcons/3nrAXhJXSIeMTH7FLjv4_Architecture_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1076030-pt-408?preview=true',
    verticals: [
      'ProfessionalServices',
      'Blog',
      'PortfolioResume',
      'Business',
    ],
    colors: [
      'white',
      'blue',
      'black',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: true,
    hasStore: false,
    numOfPages: 7,
    isCustom: false,
  },
  {
    name: 'Barra lateral',
    id: 1027437,
    order: -1720,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/bfb2c611ec904bb1b4c03d73e7f0ab9c/siteTemplateIcons/W7V4fDAcRGevEgUGCgQb_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/bfb2c611ec904bb1b4c03d73e7f0ab9c/siteTemplateIcons/BAh0vYz5QpG6Dud7LBcm_3%20Screens%20Devices.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/bfb2c611ec904bb1b4c03d73e7f0ab9c/siteTemplateIcons/ZwcXxR1PTouYwG7o4mMo_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/bfb2c611ec904bb1b4c03d73e7f0ab9c/siteTemplateIcons/WZxHUhJVQ9bdQmS1G1gw_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1027437-pt-389?preview=true',
    verticals: [
      'Blank',
    ],
    colors: [
      'white',
      'black',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 5,
    isCustom: false,
  },
  {
    name: 'Aplicativo móvel',
    id: 1031175,
    order: -1700,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/c41268fe6e764edeb9047d178b975858/siteTemplateIcons/CAZy4XyISg6RLxkUS4nX_Mobile_App_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/c41268fe6e764edeb9047d178b975858/siteTemplateIcons/13Dsood2SCurfYJfa6xs_Mobile_app_3%20Sreens%20Devices.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/c41268fe6e764edeb9047d178b975858/siteTemplateIcons/kh9QWSp2AvhavKTihvwd_mobile_app_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/c41268fe6e764edeb9047d178b975858/siteTemplateIcons/cW4LL60vTSH9sGHdwpSV_Mobile_app_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1031175-pt-389?preview=true',
    verticals: [
      'CommunityEducation',
      'Business',
    ],
    colors: [
      'white',
      'purple',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Fisioterapeuta',
    id: 1017022,
    order: -1680,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/c41833c722d14a3c9072a6a2e3eb89c4/siteTemplateIcons/lCvfb6MAQ6SKlrPYZrbt_physiotherapist_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/c41833c722d14a3c9072a6a2e3eb89c4/siteTemplateIcons/ncrkzIP9R8yDsTTp3OAC_Physiotherapist_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/c41833c722d14a3c9072a6a2e3eb89c4/siteTemplateIcons/M8qjijr2R1GreB9oD2Lm_Physiotherapist_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/c41833c722d14a3c9072a6a2e3eb89c4/siteTemplateIcons/wIBbwS0BTxeoFB9w0T6s_Physiotherapist_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1017022-pt-389?preview=true',
    verticals: [
      'LifestyleHealth',
      'ProfessionalServices',
      'Blog',
    ],
    colors: [
      'white',
      'blue',
      'light-blue',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: true,
    hasStore: false,
    numOfPages: 5,
    isCustom: false,
  },
  {
    name: 'SPA',
    id: 1029064,
    order: -1660,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/fb9b338c937a4ab082bb27242259532d/siteTemplateIcons/OTgwSmS9QgqvsK61ijnX_Spa_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/fb9b338c937a4ab082bb27242259532d/siteTemplateIcons/NXidq8rRqeVPU9lb0dXV_Spa_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/fb9b338c937a4ab082bb27242259532d/siteTemplateIcons/SBh1LC5VSXiv5PA148d6_Spa_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/fb9b338c937a4ab082bb27242259532d/siteTemplateIcons/z3bsHjsRsaVys2Wkbo2C_Spa_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1029064-pt-389?preview=true',
    verticals: [
      'LifestyleHealth',
      'Business',
    ],
    colors: [
      'white',
      'blue',
      'light-green',
      'yellow',
      'light-blue',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Pré-escola',
    id: 1012895,
    order: -1640,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/d0fc2baf70b74558a061f65decefe2bd/siteTemplateIcons/8lJEhdtRNiygki5cRSiY_Preschool_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/d0fc2baf70b74558a061f65decefe2bd/siteTemplateIcons/oPCFlcY1TFOxWHRLpkD8_Preschool_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/d0fc2baf70b74558a061f65decefe2bd/siteTemplateIcons/8WMubXfTyrM0TdDpjnw1_Preschool_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/d0fc2baf70b74558a061f65decefe2bd/siteTemplateIcons/Rsf0MYDT6a7c9odAfHeu_Preschool_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1012895-pt-372?preview=true',
    verticals: [
      'CommunityEducation',
    ],
    colors: [
      'blue',
      'yellow',
      'orange',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 6,
    isCustom: false,
  },
  {
    name: 'Loja de moda',
    id: 1015026,
    order: -1620,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/3f46c147efb34760b6a8b7a919ef14ac/siteTemplateIcons/kIQy6UcRA661dLWCg6E6_fashion2_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/3f46c147efb34760b6a8b7a919ef14ac/siteTemplateIcons/b5cqMiBmTnimgveVRZ3Q_Fashion_store__BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/3f46c147efb34760b6a8b7a919ef14ac/siteTemplateIcons/1psa4utSxSMIUtaZwbBA_FAshion_store_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/3f46c147efb34760b6a8b7a919ef14ac/siteTemplateIcons/EDVwTQdaQzKf8L7iQypJ_Fashion_store_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1015026-pt-379?preview=true',
    verticals: [
      'LifestyleHealth',
      'OnlineStore',
      'Business',
    ],
    colors: [
      'white',
      'black',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: true,
    numOfPages: 7,
    isCustom: false,
  },
  {
    name: 'Portfólio criativo',
    id: 1013306,
    order: -1600,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/6ccb70e47fa44c5f9def17b8cebe4d89/siteTemplateIcons/XlrCLAOPSHiaXGy3B1Hc_Creative_portfolio_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/6ccb70e47fa44c5f9def17b8cebe4d89/siteTemplateIcons/KwRp0wFeTkSIxqdMirP1_Creative_portfolio_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/6ccb70e47fa44c5f9def17b8cebe4d89/siteTemplateIcons/ylmfEbCuTGKHb0Bhhz7U_Creative_portfolio_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/6ccb70e47fa44c5f9def17b8cebe4d89/siteTemplateIcons/zcZTaHu5RoWuU251Qx0u_Creative_portfolio_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1013306-pt-372?preview=true',
    verticals: [
      'PortfolioResume',
    ],
    colors: [
      'white',
      'blue',
      'pink',
      'yellow',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 15,
    isCustom: false,
  },
  {
    name: 'Imóveis',
    id: 1011557,
    order: -1580,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/f4e7822f2a774d099eeb98784ccd0c10/siteTemplateIcons/UyFym2SdTmqKApqNdc7a_real_estate_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/f4e7822f2a774d099eeb98784ccd0c10/siteTemplateIcons/XHAxQoNQASzV395dAeEQ_Realestate_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/f4e7822f2a774d099eeb98784ccd0c10/siteTemplateIcons/c7kstwMjTbjdE62E2wpS_real_estate_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/f4e7822f2a774d099eeb98784ccd0c10/siteTemplateIcons/GnykdfEjT32CV0G3pIp5_real_estate_mobile.png',
    demoSiteUrl: '/site/dm-theme-1011557-pt-389?preview=true',
    verticals: [
      'Blog',
      'Business',
    ],
    colors: [
      'white',
      'light-blue',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: true,
    hasStore: false,
    numOfPages: 7,
    isCustom: false,
  },
  {
    name: 'Concessionário',
    id: 1015027,
    order: -1560,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/39ce38d78a904c4a80d8da5269a66007/siteTemplateIcons/TcX2tPTudKmGYeDXwizh_desktop_cardealership.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/39ce38d78a904c4a80d8da5269a66007/siteTemplateIcons/vdcGx9JPTueHefliUg8j_car_dealer_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/39ce38d78a904c4a80d8da5269a66007/siteTemplateIcons/Sb1DjNy4SkuUKrAswV2L_Luxury_car_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/39ce38d78a904c4a80d8da5269a66007/siteTemplateIcons/ibj8yReiQxeYubYAcyFJ_car_dealer_mobile.png',
    demoSiteUrl: '/site/dm-theme-1015027-pt-389?preview=true',
    verticals: [
      'Travel',
      'Blog',
      'Business',
    ],
    colors: [
      'white',
      'black',
      'red',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: true,
    hasStore: false,
    numOfPages: 7,
    isCustom: false,
  },
  {
    name: 'Serviço de limpeza',
    id: 1010811,
    order: -1540,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/ab48ba2169784101aae1a8f276cf75e4/siteTemplateIcons/WkktgfyIRlSOZR9Tqs8z_JdSckgGSXawCtQgASJfx_Cleaning_service_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/ab48ba2169784101aae1a8f276cf75e4/siteTemplateIcons/P7TdYrkKSomHjD7fH1Wt_Cleaning_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/ab48ba2169784101aae1a8f276cf75e4/siteTemplateIcons/0MkWgUzlScWe1SjjaQ2G_enmcMcYT1irAbMWtQWbD_cleaning_service_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/ab48ba2169784101aae1a8f276cf75e4/siteTemplateIcons/eboR9aAQNCLgCsRsn3yP_7AmKBSALQZeJpckZhcvC_Cleaning_service_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1010811-pt-372?preview=true',
    verticals: [
      'ProfessionalServices',
      'Business',
    ],
    colors: [
      'white',
      'blue',
      'yellow',
      'light-blue',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Salão de cabeleireiro',
    id: 1013307,
    order: -1520,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/b6292a47e7ad4c08bae919bc607cadd8/siteTemplateIcons/kyOUwwFhRyqomNshMhjs_Hair_salon_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/b6292a47e7ad4c08bae919bc607cadd8/siteTemplateIcons/0gUSZtd7Qima2gOYxpai_Hair_Salon_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/b6292a47e7ad4c08bae919bc607cadd8/siteTemplateIcons/tW6tQaj8SkaEgTWnRVG8_Hair_salon_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/b6292a47e7ad4c08bae919bc607cadd8/siteTemplateIcons/KjgU1oFcTEG2zJiVpVb3_Hair_salon_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1013307-pt-372?preview=true',
    verticals: [
      'LifestyleHealth',
      'Business',
    ],
    colors: [
      'blue',
      'pink',
      'purple',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 7,
    isCustom: false,
  },
  {
    name: 'Moving Company',
    id: 1009458,
    order: -1500,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/9d34deed28eb4aed8c1ffa02fecc53c8/siteTemplateIcons/YYWOo4e2SyRJG1BJtazA_moving_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/9d34deed28eb4aed8c1ffa02fecc53c8/siteTemplateIcons/qGtku1AHSdqeNUO5Zpz5_Moving_company_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/9d34deed28eb4aed8c1ffa02fecc53c8/siteTemplateIcons/p8PYm3b0QlSOc9X6jbvx_moving_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/9d34deed28eb4aed8c1ffa02fecc53c8/siteTemplateIcons/IWNAlD7TTFyHFZsTKhUy_moving_mobile.png',
    demoSiteUrl: '/site/dm-theme-1009458-pt-372?preview=true',
    verticals: [
      'ProfessionalServices',
      'Business',
    ],
    colors: [
      'green',
      'white',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Hotel da cidade',
    id: 1009459,
    order: -1480,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/f56b9160ed4c4bd68a67df53491e50d9/siteTemplateIcons/tCDUeFfCRFaCJZUNi2QR_city_hotel_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/f56b9160ed4c4bd68a67df53491e50d9/siteTemplateIcons/MzLxtXwHRKOYq6dTEHh2_Boutique_hotel_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/f56b9160ed4c4bd68a67df53491e50d9/siteTemplateIcons/t6LLRDX1RoWTzYDJa38F_city_hotel_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/f56b9160ed4c4bd68a67df53491e50d9/siteTemplateIcons/Rab7HLAQla7hU7rZ4r23_city_hotel_mobile.png',
    demoSiteUrl: '/site/dm-theme-1009459-pt-372?preview=true',
    verticals: [
      'Travel',
      'Business',
    ],
    colors: [
      'blue',
      'yellow',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 6,
    isCustom: false,
  },
  {
    name: 'Carpinteiro',
    id: 1026311,
    order: -1460,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/78315cb7f6bd4374be15b461bf10f863/siteTemplateIcons/J0IcgiapTHKTiCUpev2u_Carpenter_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/78315cb7f6bd4374be15b461bf10f863/siteTemplateIcons/O3t1VbpSBW2uYxGrZycY_Carpenter_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/78315cb7f6bd4374be15b461bf10f863/siteTemplateIcons/b9hKYvJdQ4v5MGVP4GYM_Carpenter_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/78315cb7f6bd4374be15b461bf10f863/siteTemplateIcons/9Va0LXfrRUuc8HjqNXqf_Carpenter_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1026311-pt-389?preview=true',
    verticals: [
      'ProfessionalServices',
      'Business',
    ],
    colors: [
      'white',
      'brown',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Página em branco',
    id: 1012450,
    order: -1440,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/967f2fd827924dd69db4042e708aee6b/siteTemplateIcons/StG5GdUkQKa883ORF6lI_Blank_one_page_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/967f2fd827924dd69db4042e708aee6b/siteTemplateIcons/Sl12dHx3RD2pvG2LsSJ2_Blank_lp_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/967f2fd827924dd69db4042e708aee6b/siteTemplateIcons/aKEFZ9uSQX6kME21fXjQ_Blank_one_page_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/967f2fd827924dd69db4042e708aee6b/siteTemplateIcons/0lhYbnRvTAKdB2EnT1OG_Blank_one_page_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1012450-pt-372?preview=true',
    verticals: [
      'Blank',
    ],
    colors: [
      'white',
      'black',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 1,
    isCustom: false,
  },
  {
    name: 'Sorveteria',
    id: 1008302,
    order: -1420,
    siteTemplateId: -1,
    iconPath: 'https://cdn.dwhitelabel.com/e504029522244071a09505a62bcfe8d9/siteTemplateIcons/fUaMHW2ET7GeS7pk7gzR_ice_cream_shop_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/e504029522244071a09505a62bcfe8d9/siteTemplateIcons/YEXS3kSlRQ2ylZIMs6mr_Icecream_shop_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/e504029522244071a09505a62bcfe8d9/siteTemplateIcons/nxaJ1bBKQ0aWCfzqcEnI_ice_cream_shop_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/e504029522244071a09505a62bcfe8d9/siteTemplateIcons/Alq5ts29RunpvC5Y5Ojh_ice_cream_shop_mobile.png',
    demoSiteUrl: '/site/dm-theme-1008302-pt-372?preview=true',
    verticals: [
      'RestaurantFood',
      'Business',
    ],
    colors: [
      'white',
      'yellow',
      'light-blue',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 6,
    isCustom: false,
  },
  {
    name: 'Restaurante orgânico',
    id: 1027884,
    order: -1380,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/4880d79ae69541e6bfd46bbd33b6d82c/siteTemplateIcons/Y6fJtkmTNqJ8WU7mzlfj_Organic_restaurant_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/4880d79ae69541e6bfd46bbd33b6d82c/siteTemplateIcons/3DDHjNR2aNT3aGVUFzQQ_Organic_restaurant_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/4880d79ae69541e6bfd46bbd33b6d82c/siteTemplateIcons/8o5H5crORLuxBbF81ShA_Organic_restaurant_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/4880d79ae69541e6bfd46bbd33b6d82c/siteTemplateIcons/dEUvmLftSgqkFIaMiNkD_Organic_restaurant_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1027884-pt-389?preview=true',
    verticals: [
      'RestaurantFood',
      'Business',
    ],
    colors: [
      'green',
      'white',
      'light-green',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 5,
    isCustom: false,
  },
  {
    name: 'Template vazio',
    id: 1015028,
    order: -1360,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/0ee33230d9db493993a2cd03115703e6/siteTemplateIcons/Ox7GYLzdQSm66f0IHSzM_Empty_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/0ee33230d9db493993a2cd03115703e6/siteTemplateIcons/NgI2SQdTXmhlyoo7ljQT_Empty_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/0ee33230d9db493993a2cd03115703e6/siteTemplateIcons/rgi2tNrmQeGmZRz7EO25_Empty_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/0ee33230d9db493993a2cd03115703e6/siteTemplateIcons/IZrkQVPQDOD30VuSj2pW_Empty_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1015028-pt-381?preview=true',
    verticals: [
      'Blank',
    ],
    colors: [
      'white',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 5,
    isCustom: false,
  },
  {
    name: 'Dance Studio',
    id: 1009838,
    order: -1340,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/1002053ab08746c9ad450925c30ce76d/siteTemplateIcons/KsQGkEggRBu3Ts9bPKCS_dance_studio_desktop[.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/1002053ab08746c9ad450925c30ce76d/siteTemplateIcons/EKSbLKI4RSKUX64BqOjc_Dance_Studio_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/1002053ab08746c9ad450925c30ce76d/siteTemplateIcons/QrTLvO97T9ivYz6GqckL_dance_studio_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/1002053ab08746c9ad450925c30ce76d/siteTemplateIcons/HJ4uoDVSFijTgayGNqn1_dance_studio_mobile-1.png',
    demoSiteUrl: '/site/dm-theme-1009838-pt-381?preview=true',
    verticals: [
      'LifestyleHealth',
      'Events',
      'Business',
    ],
    colors: [
      'white',
      'black',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 5,
    isCustom: false,
  },
  {
    name: 'Página de destino do aplicativo',
    id: 1009457,
    order: -1320,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/ce0bb35f932b47bb809d0e37905542ba/siteTemplateIcons/L3GCeVNQNeo8HAt4LD39_app_landing_page_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/ce0bb35f932b47bb809d0e37905542ba/siteTemplateIcons/gl8GS0HDRVuoQh2MymkF_App-Lp_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/ce0bb35f932b47bb809d0e37905542ba/siteTemplateIcons/qb1bGmV4RkCOeJDyOllh_app_landing_page_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/ce0bb35f932b47bb809d0e37905542ba/siteTemplateIcons/x316LibAT0WV542UxG5g_app_landing_page_mobile.png',
    demoSiteUrl: '/site/dm-theme-1009457-pt-389?preview=true',
    verticals: [
      'CommunityEducation',
      'LandingPage',
      'Business',
    ],
    colors: [
      'blue',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 3,
    isCustom: false,
  },
  {
    name: 'Casamento',
    id: 1031576,
    order: -1300,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/083eb835fa584b758ec08c79c9f8f7d0/siteTemplateIcons/stZN84HTVq0iWkGOOdvQ_Wedding_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/083eb835fa584b758ec08c79c9f8f7d0/siteTemplateIcons/DjzWLrOWQCTExo5VrYXo_Wedding_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/083eb835fa584b758ec08c79c9f8f7d0/siteTemplateIcons/CZjqsQ5kQbKQOSYNNtIa_Wedding_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/083eb835fa584b758ec08c79c9f8f7d0/siteTemplateIcons/97U2HUKSEOaxWakCbwjk_Wedding_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1031576-pt-389?preview=true',
    verticals: [
      'Events',
      'CommunityEducation',
      'Business',
    ],
    colors: [
      'white',
      'brown',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 1,
    isCustom: false,
  },
  {
    name: 'Decoração para a casa',
    id: 1007731,
    order: -1280,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/eb5fffe4934f43aeb0c0e0d800be9e8f/siteTemplateIcons/jK4XL8TVSiK6dL1fBig8_home_decor_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/eb5fffe4934f43aeb0c0e0d800be9e8f/siteTemplateIcons/5ApUmQMRPORWrHa3cNQW_BigPreview_home_decor.jpg',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/eb5fffe4934f43aeb0c0e0d800be9e8f/siteTemplateIcons/y43KFSSaQPy5xpCiLZPR_home_decor_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/eb5fffe4934f43aeb0c0e0d800be9e8f/siteTemplateIcons/hbIp7CyTJidnHh5tazIi_home_decor_mobile.png',
    demoSiteUrl: '/site/dm-theme-1007731-pt-381?preview=true',
    verticals: [
      'LifestyleHealth',
      'Business',
    ],
    colors: [
      'white',
      'black',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 6,
    isCustom: false,
  },
  {
    name: 'Serviço de táxi',
    id: 1031176,
    order: -1260,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/7e8fe9c6dab84b35bea31685fdc0f619/siteTemplateIcons/xBHCPQBiTJqaIk22ht98_Cab_service_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/7e8fe9c6dab84b35bea31685fdc0f619/siteTemplateIcons/GIyF2VZeSkm0TdTJ0uO3_Cab_service_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/7e8fe9c6dab84b35bea31685fdc0f619/siteTemplateIcons/GymOYFjTbO0EAdXWdvns_Cab_service_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/7e8fe9c6dab84b35bea31685fdc0f619/siteTemplateIcons/3D8mKyyzScGdH2DUC5aO_Cab_service_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1031176-pt-389?preview=true',
    verticals: [
      'Travel',
      'Business',
    ],
    colors: [
      'white',
      'black',
      'yellow',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Consultório médico',
    id: 1007980,
    order: -1240,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/927d602194dc4e6691d2c6548ff3680e/siteTemplateIcons/f13cDoWTQ5ak3hIy5lOc_medical_clinic_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/927d602194dc4e6691d2c6548ff3680e/siteTemplateIcons/diiRHOWiRF6JqhvTEqSv_Medical_clinic_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/927d602194dc4e6691d2c6548ff3680e/siteTemplateIcons/7exRTQweSBWdlTTZlcIj_medical_clinic_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/927d602194dc4e6691d2c6548ff3680e/siteTemplateIcons/q8Ki9EDmRd2jZEaXnYTm_medical_clinic_mobile.png',
    demoSiteUrl: '/site/dm-theme-1007980-pt-381?preview=true',
    verticals: [
      'LifestyleHealth',
      'Business',
    ],
    colors: [
      'white',
      'blue',
      'light-blue',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'CHAVEIRO',
    id: 1007979,
    order: -1220,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/93bbfc246dee4a5aa3c3252c9f59d68b/siteTemplateIcons/CsPRUkPT8yEJnWkaniBF_locksmith_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/93bbfc246dee4a5aa3c3252c9f59d68b/siteTemplateIcons/2nrePG7YSiqKvYOgHyLv_Locksmith_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/93bbfc246dee4a5aa3c3252c9f59d68b/siteTemplateIcons/8KybeehQRGH8TcO5YhZa_locksmith_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/93bbfc246dee4a5aa3c3252c9f59d68b/siteTemplateIcons/5gDC4aoxQZeM6vQ7qLvP_locksmith_mobile.png',
    demoSiteUrl: '/site/dm-theme-1007979-pt-389?preview=true',
    verticals: [
      'ProfessionalServices',
      'Blog',
      'Business',
    ],
    colors: [
      'white',
      'orange',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: true,
    hasStore: false,
    numOfPages: 5,
    isCustom: false,
  },
  {
    name: 'Clínica geral',
    id: 1037395,
    order: -1200,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/d0585885c7164a61b808e9995c68da67/siteTemplateIcons/iPHpjpISKXdWv3B2NwXw_General_clinic_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/d0585885c7164a61b808e9995c68da67/siteTemplateIcons/3T6rziMTk2lgpczTQcmP_General_clinic_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/d0585885c7164a61b808e9995c68da67/siteTemplateIcons/QI0GrLb9Qby44cxwMRqk_General_clinic_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/d0585885c7164a61b808e9995c68da67/siteTemplateIcons/KgihdFx5RgGLwc4vv3BB_General_clinic_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1037395-pt-389?preview=true',
    verticals: [
      'LifestyleHealth',
      'Business',
    ],
    colors: [
      'green',
      'blue',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Página de destino de consultor',
    id: 1026287,
    order: -1180,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/c1e7738d32ae46538527c1fd4e95a9b9/siteTemplateIcons/jASytIEDRJWRG0dxSbJj_Consultant_landing_page_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/c1e7738d32ae46538527c1fd4e95a9b9/siteTemplateIcons/4CXbg61CTvKDPTEyC7EQ_Consultant_landing_page_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/c1e7738d32ae46538527c1fd4e95a9b9/siteTemplateIcons/4XZxi7HCTWaPx7oTc6fW_Consultant_landing_page_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/c1e7738d32ae46538527c1fd4e95a9b9/siteTemplateIcons/8V63M24zRYaJG96sWSdu_Consultant_landing_page_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1026287-pt-389?preview=true',
    verticals: [
      'ProfessionalServices',
      'LandingPage',
      'Business',
    ],
    colors: [
      'green',
      'white',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 1,
    isCustom: false,
  },
  {
    name: 'Local do evento',
    id: 1009165,
    order: -1160,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/8a73f3b5aeb74d4f84139e899a7941a5/siteTemplateIcons/VU9SsuSLuecUFZ7Zw9ww_event_venue_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/8a73f3b5aeb74d4f84139e899a7941a5/siteTemplateIcons/nnoToqMeQQSJXlvCqfpz_Event_venue_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/8a73f3b5aeb74d4f84139e899a7941a5/siteTemplateIcons/Ll2u8WaTZGNkyuU0UE3Q_event_venue_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/8a73f3b5aeb74d4f84139e899a7941a5/siteTemplateIcons/hH8rxKoGQfuDIcEsfxjq_event_venue_mobile.png',
    demoSiteUrl: '/site/dm-theme-1009165-pt-372?preview=true',
    verticals: [
      'Events',
      'Business',
    ],
    colors: [
      'white',
      'brown',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 5,
    isCustom: false,
  },
  {
    name: 'Logística',
    id: 1009922,
    order: -1140,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/30b8e3b9418b433fbf2144aa8c3d8be5/siteTemplateIcons/XnQt1SSy9Tay3BbuP5wy_logistics_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/30b8e3b9418b433fbf2144aa8c3d8be5/siteTemplateIcons/2iRNMdmRKKCPjZgp3Z6o_Logistics_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/30b8e3b9418b433fbf2144aa8c3d8be5/siteTemplateIcons/2UITJJ50Rme9Y4TZ1d3m_logistics_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/30b8e3b9418b433fbf2144aa8c3d8be5/siteTemplateIcons/mpjHJzhbQsqI4rAgIADM_logistics_mobile.png',
    demoSiteUrl: '/site/dm-theme-1009922-pt-389?preview=true',
    verticals: [
      'ProfessionalServices',
      'Blog',
      'Business',
    ],
    colors: [
      'white',
      'blue',
      'red',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: true,
    hasStore: false,
    numOfPages: 5,
    isCustom: false,
  },
  {
    name: 'Restaurante local',
    id: 1007187,
    order: -1120,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/ba45e11cbea846a58f3bb64c5983d4c4/siteTemplateIcons/xpPwsrukShqI5xx88EdZ_local_restaurant_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/ba45e11cbea846a58f3bb64c5983d4c4/siteTemplateIcons/yjbkB1agQoy8UDjEv16l_Local_Restaurant_BigPreview%20(1).jpg',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/ba45e11cbea846a58f3bb64c5983d4c4/siteTemplateIcons/QQju1esZQUOIf9HPV5RY_local_restaurant_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/ba45e11cbea846a58f3bb64c5983d4c4/siteTemplateIcons/X2FgAgHSTWIhZgHQeKc7_local_restaurant_mobile.png',
    demoSiteUrl: '/site/dm-theme-1007187-pt-372?preview=true',
    verticals: [
      'RestaurantFood',
    ],
    colors: [
      'white',
      'black',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 5,
    isCustom: false,
  },
  {
    name: 'PARQUE NACIONAL',
    id: 1008303,
    order: -1100,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/182e71edaa334696ba0cb33e9a1ec9da/siteTemplateIcons/rXDMuYqdT6G1o7aSRFgI_national_park_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/182e71edaa334696ba0cb33e9a1ec9da/siteTemplateIcons/FHsZr8GZT3esbbWLdeOs_National_park_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/182e71edaa334696ba0cb33e9a1ec9da/siteTemplateIcons/NGRre0jQFWplDCqxbR0f_national_park_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/182e71edaa334696ba0cb33e9a1ec9da/siteTemplateIcons/vkYLEV6uS1Cw60wvmSkC_national_park_mobile.png',
    demoSiteUrl: '/site/dm-theme-1008303-pt-372?preview=true',
    verticals: [
      'Travel',
      'CommunityEducation',
      'Business',
    ],
    colors: [
      'white',
      'blue',
      'red',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 6,
    isCustom: false,
  },
  {
    name: 'Fundação sem fins lucrativos',
    id: 1007730,
    order: -1080,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/6b94874085794cb9af89790e8d6994ad/siteTemplateIcons/ss5GmfE6TTSnjZ3yFjp0_non_profit_foundation_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/6b94874085794cb9af89790e8d6994ad/siteTemplateIcons/GjR0pq9KTQK6oHepFHbP_non_profit_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/6b94874085794cb9af89790e8d6994ad/siteTemplateIcons/WruY0GllRfOPEbqGQwsg_non_profit_foundation_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/6b94874085794cb9af89790e8d6994ad/siteTemplateIcons/pxOZSiBSRamPpoIhD16m_non_profit_foundation_mobile.png',
    demoSiteUrl: '/site/dm-theme-1007730-pt-372?preview=true',
    verticals: [
      'CommunityEducation',
    ],
    colors: [
      'green',
      'white',
      'red',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 6,
    isCustom: false,
  },
  {
    name: 'Conferência',
    id: 1004580,
    order: -1060,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/c3e028f9d55f47dfbde4c4c237ffda9f/siteTemplateIcons/10xwaGtIQX6GQziGsTXs_conference_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/c3e028f9d55f47dfbde4c4c237ffda9f/siteTemplateIcons/aTClofQ6RCmZ2xeWUB10_Conference_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/c3e028f9d55f47dfbde4c4c237ffda9f/siteTemplateIcons/RezGoJXWQWWbOkd73RBI_conference_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/c3e028f9d55f47dfbde4c4c237ffda9f/siteTemplateIcons/r4gs9oeLQz8JItdoilAL_conference_mobile.png',
    demoSiteUrl: '/site/dm-theme-1004580-pt-381?preview=true',
    verticals: [
      'Events',
    ],
    colors: [
      'white',
      'light-green',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 3,
    isCustom: false,
  },
  {
    name: 'Agência de marketing',
    id: 1005710,
    order: -1040,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/5d4bb0b699934c0caf32f3b3f6bc674d/siteTemplateIcons/5AoaZ7uSKudNEE2d5Z9g_marketing_agency_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/5d4bb0b699934c0caf32f3b3f6bc674d/siteTemplateIcons/kOOpz5FDQk2DcDFzw47t_Marketing_agency_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/5d4bb0b699934c0caf32f3b3f6bc674d/siteTemplateIcons/t28fyfdiSU2t3sD5XNxR_marketing_agency_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/5d4bb0b699934c0caf32f3b3f6bc674d/siteTemplateIcons/NMLsHIN2S5eudl0713Qz_marketing_agencu_mobile.png',
    demoSiteUrl: '/site/dm-theme-1005710-pt-381?preview=true',
    verticals: [
      'Blog',
      'Business',
    ],
    colors: [
      'white',
      'light-blue',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: true,
    hasStore: false,
    numOfPages: 12,
    isCustom: false,
  },
  {
    name: 'Advocacia',
    id: 1007423,
    order: -1020,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/bf6eb7f77bb641aea5fb60dfcae80928/siteTemplateIcons/304h53BmT4y0a6qyhzfZ_boutique_law_firm_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/bf6eb7f77bb641aea5fb60dfcae80928/siteTemplateIcons/pE4nkCcRQeKUhfH2RNIh_Boutique_Law_firm_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/bf6eb7f77bb641aea5fb60dfcae80928/siteTemplateIcons/11iQdMeOQGOEppAvnJm1_boutique_law_firm_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/bf6eb7f77bb641aea5fb60dfcae80928/siteTemplateIcons/XWoi9NR9RTOpccbLg5c6_boutique_low_firm_mobile.png',
    demoSiteUrl: '/site/dm-theme-1007423-pt-389?preview=true',
    verticals: [
      'Blog',
      'Business',
    ],
    colors: [
      'white',
      'brown',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: true,
    hasStore: false,
    numOfPages: 9,
    isCustom: false,
  },
  {
    name: 'Padaria',
    id: 1003041,
    order: -1000,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/2b2d8914/siteTemplateIcons/WTeQi3KoS72QQRbFSamJ_bakery_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/2b2d8914/siteTemplateIcons/d1OnMAaaSnaLWrZuqXUX_Bakery_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/2b2d8914/siteTemplateIcons/75WXO2VLQifSfbtihAXQ_bakery_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/2b2d8914/siteTemplateIcons/fwu21RRAO2wj4fjHaJKQ_bakery_mobile.png',
    demoSiteUrl: '/site/dm-theme-1003041-pt-381?preview=true',
    verticals: [
      'RestaurantFood',
    ],
    colors: [
      'white',
      'blue',
      'black',
      'yellow',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 1,
    isCustom: false,
  },
  {
    name: 'Template financeiro',
    id: 1027444,
    order: -980,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/f7af3fb52726475db486991c6e813c74/siteTemplateIcons/xTy43ZbtS6CLfLaoUEn1_Financial_template_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/f7af3fb52726475db486991c6e813c74/siteTemplateIcons/uhej7itSqe3ZalYFHRaq_Financial_template_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/f7af3fb52726475db486991c6e813c74/siteTemplateIcons/rnoTm7KmSSjihVLTduDw_Financial_template_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/f7af3fb52726475db486991c6e813c74/siteTemplateIcons/A6edn9hkQqm0sgEBOJkY_Financial_template_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1027444-pt-376?preview=true',
    verticals: [
      'Business',
    ],
    colors: [
      'white',
      'blue',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Em branco expansível',
    id: 1031575,
    order: -960,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/4d9bae4f377042d384d38e7171b68e47/siteTemplateIcons/7CHCizV1Tvq5oJ51QxIt_Blank_expandable_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/4d9bae4f377042d384d38e7171b68e47/siteTemplateIcons/X8OR2QBXSomp73b8Lot8_Blank_expandable_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/4d9bae4f377042d384d38e7171b68e47/siteTemplateIcons/1DUvtftTNaDgaydif4fU_Blank_expandable_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/4d9bae4f377042d384d38e7171b68e47/siteTemplateIcons/FfdMB3R0SbGIGLl3ccpo_Blank_expandable_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1031575-pt-372?preview=true',
    verticals: [
      'Blank',
      'Blog',
    ],
    colors: [
      'white',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: true,
    hasStore: false,
    numOfPages: 6,
    isCustom: false,
  },
  {
    name: 'Amber',
    id: 1029063,
    order: -960,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/2f2e1ece6eaf4d849467345aeb7be024/siteTemplateIcons/OjG06l3wQnWH1nOJyZUA_Stylist_landing_page_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/2f2e1ece6eaf4d849467345aeb7be024/siteTemplateIcons/SMAT3NOQQ5Wb9GpGPXFm_Stylist_landing_page_3screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/2f2e1ece6eaf4d849467345aeb7be024/siteTemplateIcons/cKFGBAdETGgbP5AuqX69_Stylist_landing_page_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/2f2e1ece6eaf4d849467345aeb7be024/siteTemplateIcons/JUBcnb37Tc6GJ0vso2mz_Stylist_landing_page_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1029063-pt-372?preview=true',
    verticals: [
      'LifestyleHealth',
      'LandingPage',
    ],
    colors: [
      'white',
      'red',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 1,
    isCustom: false,
  },
  {
    name: 'Reparo de automóveis',
    id: 1005442,
    order: -920,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/0f8a84df3a244e0f9249423e0588397f/siteTemplateIcons/EYHkIIRiSgyaXFO457pE_auto_repair_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/0f8a84df3a244e0f9249423e0588397f/siteTemplateIcons/kMmhpVUROqIr5cF3dC16_Auto_repair_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/0f8a84df3a244e0f9249423e0588397f/siteTemplateIcons/rIHNVCcJRVKdZa8dd5hn_auto-repair_ipad.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/0f8a84df3a244e0f9249423e0588397f/siteTemplateIcons/uZVdgKgASIaaVS4Fwtmd_auto-repair-mobile.png',
    demoSiteUrl: '/site/dm-theme-1005442-pt-381?preview=true',
    verticals: [
      'ProfessionalServices',
    ],
    colors: [
      'black',
      'red',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [
      {
        iconUrl: 'https://irp-cdn.multiscreensite.com/cd832622/screenshots/Screenshot.png?timestamp=1573406767000&updated=true',
        siteUrl: 's.multiscreensite.com/site/cd832622?preview=true',
      },
    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 5,
    isCustom: false,
  },
  {
    name: 'Sushi Restaurant',
    id: 1027883,
    order: -900,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/3b9afaabe73f4a36847a5544febecc0b/siteTemplateIcons/kY4cPQbMTZunOOOc2R2J_Sushi_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/3b9afaabe73f4a36847a5544febecc0b/siteTemplateIcons/M8fAD3xS2OmHtcWRmznA_Sushi_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/3b9afaabe73f4a36847a5544febecc0b/siteTemplateIcons/rwlnyAeWRK6yb1l30zDl_Sushi_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/3b9afaabe73f4a36847a5544febecc0b/siteTemplateIcons/1Rffd4LzRbeoaMD3Z25g_Sushi_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1027883-pt-389?preview=true',
    verticals: [
      'RestaurantFood',
      'Business',
    ],
    colors: [
      'white',
      'black',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 5,
    isCustom: false,
  },
  {
    name: 'Guia turístico',
    id: 1005711,
    order: -880,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/814abcedccf84126a35f142e1bed0cb9/siteTemplateIcons/KYgpmrERHimcrYL2nVCn_tour_guide_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/814abcedccf84126a35f142e1bed0cb9/siteTemplateIcons/bV1eJlpSQFSDEvyD9HqG_Tour_guide_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/814abcedccf84126a35f142e1bed0cb9/siteTemplateIcons/hvRdaEunTPCTpfYjnzTX_tour_guide_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/814abcedccf84126a35f142e1bed0cb9/siteTemplateIcons/Yti0r2wUQ46nvDtByftd_tour_guide_mobile.png',
    demoSiteUrl: '/site/dm-theme-1005711-pt-381?preview=true',
    verticals: [
      'Travel',
      'CommunityEducation',
      'ProfessionalServices',
      'Business',
    ],
    colors: [
      'green',
      'blue',
      'brown',
      'orange',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Landing Page da imobiliária',
    id: 1024395,
    order: -860,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/96331dce89ba4ab7998bdf4afac37e74/siteTemplateIcons/3lS8FDJQPO0fzR2Ho9cM_Real_estate_landing_page_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/96331dce89ba4ab7998bdf4afac37e74/siteTemplateIcons/0N2iV1NATDKVSvhYU8Zi_Real_estate_landing_pafe_3-screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/96331dce89ba4ab7998bdf4afac37e74/siteTemplateIcons/jDtuya2SZmCOPgsPDEzg_Real_estate_landing_page_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/96331dce89ba4ab7998bdf4afac37e74/siteTemplateIcons/P1Jlv4ZRSr2xEVDvxiX2_Real_estate_landing_page_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1024395-pt-389?preview=true',
    verticals: [
      'LandingPage',
      'Business',
    ],
    colors: [
      'white',
      'blue',
      'pink',
      'purple',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 1,
    isCustom: false,
  },
  {
    name: 'HANDYMAN',
    id: 1004643,
    order: -840,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/7b0914fe449b4fdc82e91c99a7d0cbcc/siteTemplateIcons/3AbIUQCgQhSZF5HetLvI_handuman_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/7b0914fe449b4fdc82e91c99a7d0cbcc/siteTemplateIcons/NVALXau0ThSJTMMnpa6k_Handyman_BigPreview.jpg',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/7b0914fe449b4fdc82e91c99a7d0cbcc/siteTemplateIcons/kkQkTpRQSj4MIWFusApw_handyman_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/7b0914fe449b4fdc82e91c99a7d0cbcc/siteTemplateIcons/kOf7IW8wQwmW6ouvQhPP_handyman_mobile.png',
    demoSiteUrl: '/site/dm-theme-1004643-pt-381?preview=true',
    verticals: [
      'ProfessionalServices',
      'Business',
    ],
    colors: [
      'yellow',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 3,
    isCustom: false,
  },
  {
    name: 'Parallax',
    id: 1023873,
    order: -820,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/b5adfa7b80094ff0b3c71082f0f0baa2/siteTemplateIcons/mZUjHftCShmVWPHNiCPC_Parallax_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/b5adfa7b80094ff0b3c71082f0f0baa2/siteTemplateIcons/AGl9ebHsQOaLMjTK4f1m_Parallax_3_devices.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/b5adfa7b80094ff0b3c71082f0f0baa2/siteTemplateIcons/B7OhWl9UTYyP8r6Js4qI_Parallax_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/b5adfa7b80094ff0b3c71082f0f0baa2/siteTemplateIcons/1zrEdoPZQAeelbY4DXxX_Parallax_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1023873-pt-372?preview=true',
    verticals: [
      'Business',
    ],
    colors: [
      'white',
      'blue',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [
      {
        iconUrl: 'https://irp-cdn.multiscreensite.com/6f80a0ef/screenshots/Screenshot.png?timestamp=1572903763000&updated=true',
        siteUrl: 's.multiscreensite.com/site/6f80a0ef?preview=true',
      },
    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Consultoria',
    id: 1005051,
    order: -800,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/8c1c745105934b2aa9af1647a7fa32a6/siteTemplateIcons/tqPac3yhToOA3xpi8HTU_consulting_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/8c1c745105934b2aa9af1647a7fa32a6/siteTemplateIcons/ayTcTU4Syq5RpRFV7dqA_Consulting_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/8c1c745105934b2aa9af1647a7fa32a6/siteTemplateIcons/hXQYkMlQl6UevC4jjpqA_consulting_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/8c1c745105934b2aa9af1647a7fa32a6/siteTemplateIcons/ec4EjPAGSI6SREx5VV8M_consulting_mobile.png',
    demoSiteUrl: '/site/dm-theme-1005051-pt-381?preview=true',
    verticals: [
      'Business',
    ],
    colors: [
      'white',
      'blue',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 3,
    isCustom: false,
  },
  {
    name: 'Dentista',
    id: 1003737,
    order: -780,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/6d8cd7005c5e49b689e45cc4c9329570/siteTemplateIcons/pd4W8qTmTtuXSNg8nOfU_dentist_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/6d8cd7005c5e49b689e45cc4c9329570/siteTemplateIcons/L0clbsx9SP6XRRW7y3AW_Dentist_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/6d8cd7005c5e49b689e45cc4c9329570/siteTemplateIcons/74WTyIZ8RfeVxM9OWCVG_dentist_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/6d8cd7005c5e49b689e45cc4c9329570/siteTemplateIcons/R2pnSR0fQ56L6P2lDt6v_dentist_mobile.png',
    demoSiteUrl: '/site/dm-theme-1003737-pt-381?preview=true',
    verticals: [
      'LifestyleHealth',
      'ProfessionalServices',
      'Business',
    ],
    colors: [
      'white',
      'light-green',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 3,
    isCustom: false,
  },
  {
    name: 'Sala de fuga',
    id: 1005663,
    order: -760,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/2ef143f2ad5a4efda4add642c6a235f5/siteTemplateIcons/NEkXzI1SS6yKv6zjoy21_escape_room_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/2ef143f2ad5a4efda4add642c6a235f5/siteTemplateIcons/23Yh3jbyT3myjxbMM8w2_Escape_room_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/2ef143f2ad5a4efda4add642c6a235f5/siteTemplateIcons/NUjyqOzQSRiqg62yaytM_escape_room_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/2ef143f2ad5a4efda4add642c6a235f5/siteTemplateIcons/2svRSaonTZWLP5vJ09oP_escape_room_mobile.png',
    demoSiteUrl: '/site/dm-theme-1005663-pt-372?preview=true',
    verticals: [
      'Events',
      'Business',
    ],
    colors: [
      'brown',
      'black',
      'orange',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 1,
    isCustom: false,
  },
  {
    name: 'Exposição',
    id: 1002714,
    order: -740,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/exhibition_template/siteTemplateIcons/WD9m8TURUWxqVkvJIepk_exhibition_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/exhibition_template/siteTemplateIcons/4ebt92qcR6cKBik9bQHs_Exhibition_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/exhibition_template/siteTemplateIcons/yOW4x5bpTbKBJ886Nl31_exhibition_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/exhibition_template/siteTemplateIcons/37hwCeiUSK2ISEg2qs5A_exhibition_mobile.png',
    demoSiteUrl: '/site/dm-theme-1002714-pt-381?preview=true',
    verticals: [
      'Events',
      'PortfolioResume',
    ],
    colors: [
      'white',
      'black',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 1,
    isCustom: false,
  },
  {
    name: 'Fitness Club',
    id: 1003736,
    order: -720,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/324409d14a894386bc943579b67bf1db/siteTemplateIcons/DCI6g9GLSSW1FWTGMKXV_fitness_club_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/324409d14a894386bc943579b67bf1db/siteTemplateIcons/Be2Bs3NTFqBqAqf1oow8_Fitness_club_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/324409d14a894386bc943579b67bf1db/siteTemplateIcons/SBWokg98SLmh3tk7rJgC_fitness_club_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/324409d14a894386bc943579b67bf1db/siteTemplateIcons/OodU8t1QLKQxYIvbtq0Q_fitmess_club_mobile.png',
    demoSiteUrl: '/site/dm-theme-1003736-pt-372?preview=true',
    verticals: [
      'LifestyleHealth',
      'Business',
    ],
    colors: [
      'black',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: true,
    hasStore: false,
    numOfPages: 3,
    isCustom: false,
  },
  {
    name: 'Blank parallax',
    id: 1003459,
    order: -700,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/aa37d83d94df4a009c75e79f9786aac8/siteTemplateIcons/UqXdOLCTRr2vtoPMWA1G_blank_parallax_e_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/aa37d83d94df4a009c75e79f9786aac8/siteTemplateIcons/RSaSBkbRWmrI2olYzUVm_Blank_parallax_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/aa37d83d94df4a009c75e79f9786aac8/siteTemplateIcons/gnsdEI9dS3a8Bc8JigDg_blank_parallax_e_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/aa37d83d94df4a009c75e79f9786aac8/siteTemplateIcons/N9rUe92RQTyrFfSrG9RQ_blank_parallax_e_mobile.png',
    demoSiteUrl: '/site/dm-theme-1003459-pt-372?preview=true',
    verticals: [
      'Blank',
    ],
    colors: [
      'white',
      'black',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Linha em branco',
    id: 1023876,
    order: -680,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/72cc4441bb834deca1d4701a61a5f60b/siteTemplateIcons/aukeT3HQIeroKBVWXgQV_Blank_row_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/72cc4441bb834deca1d4701a61a5f60b/siteTemplateIcons/JNx6wfKlSQWasqNeWBBr_Blank_row_3_devices.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/72cc4441bb834deca1d4701a61a5f60b/siteTemplateIcons/gmlMkOnTStKXbQh8Kkj4_Blank_row_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/72cc4441bb834deca1d4701a61a5f60b/siteTemplateIcons/hUj7I2a1Qni711WAk42J_Blank_row_mobile.png',
    demoSiteUrl: '/site/dm-theme-1023876-pt-376?preview=true',
    verticals: [
      'Blank',
    ],
    colors: [
      'white',
      'blue',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Landscape Photography',
    id: 1059485,
    order: -680,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/923c925486ce42b48c57dfd6b910c482/siteTemplateIcons/sVBmRlElSMGSQcM8MHQr_Landscape_photography_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/923c925486ce42b48c57dfd6b910c482/siteTemplateIcons/wQt021TSvmblMFBkM8sI_Landscape_photography_3%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/923c925486ce42b48c57dfd6b910c482/siteTemplateIcons/CWhebnSXQX6IesfUYOjm_Landscape_photography_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/923c925486ce42b48c57dfd6b910c482/siteTemplateIcons/OpD51H0RaeJ2RJzEIWga_Landscape_photography_Mobile.png',
    demoSiteUrl: '/site/dm-theme-1059485-pt-389?preview=true',
    verticals: [
      'PortfolioResume',
    ],
    colors: [
      'white',
      'blue',
      'black',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [
      {
        iconUrl: 'https://irp-cdn.multiscreensite.com/6edb4d71/screenshots/Screenshot.png?timestamp=1572570498000&updated=true',
        siteUrl: 's.multiscreensite.com/site/6edb4d71?preview=true',
      },
    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Serviços em Branco',
    id: 1003738,
    order: -660,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/fac18167cc14456196ba71cef32d4bc0/siteTemplateIcons/JcFd47BRSmOv8utqovg3_blank_services_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/fac18167cc14456196ba71cef32d4bc0/siteTemplateIcons/rCFYf1hwSu2MZ81RZQaA_Blank_services_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/fac18167cc14456196ba71cef32d4bc0/siteTemplateIcons/Mva1521TEK3O936gia2F_blank_services_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/fac18167cc14456196ba71cef32d4bc0/siteTemplateIcons/xNEqNg6ZR56eI3ngAxvE_blank_services_mobile.png',
    demoSiteUrl: '/site/dm-theme-1003738-pt-372?preview=true',
    verticals: [
      'Blank',
    ],
    colors: [
      'white',
      'black',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 3,
    isCustom: false,
  },
  {
    name: 'Loja em branco',
    id: 1004113,
    order: -640,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/bd0481fe5cb342e8b4d727104c7457b2/siteTemplateIcons/KkRkUWKuQ6eDACeCDZrU_blank_store_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/bd0481fe5cb342e8b4d727104c7457b2/siteTemplateIcons/W7WojmcDRFWocRbvkzr5_Blank_store_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/bd0481fe5cb342e8b4d727104c7457b2/siteTemplateIcons/W5McxQMnRWc2TTYb4Kfw_blank_store_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/bd0481fe5cb342e8b4d727104c7457b2/siteTemplateIcons/jpiWTIU3RQKZEissvqT8_blank_store_mobile.png',
    demoSiteUrl: '/site/dm-theme-1004113-pt-379?preview=true',
    verticals: [
      'Blank',
      'OnlineStore',
    ],
    colors: [
      'white',
      'black',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: true,
    numOfPages: 3,
    isCustom: false,
  },
  {
    name: 'TEMPLATE EM BRANCO',
    id: 1024394,
    order: -620,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/653c8ee011cd41acaf550387a89f092f/siteTemplateIcons/eSQyxC9fRmeDwNLRPhIy_Blank_template_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/653c8ee011cd41acaf550387a89f092f/siteTemplateIcons/twZ7rTEfQyyl3wy30AEe_Blank_template_3devices.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/653c8ee011cd41acaf550387a89f092f/siteTemplateIcons/32UAevExQ9S0tkumbd54_Blank_template_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/653c8ee011cd41acaf550387a89f092f/siteTemplateIcons/5XGe7AdnRaqrR6Ho4Wxn_Blank_template_mobile.png',
    demoSiteUrl: '/site/dm-theme-1024394-pt-376?preview=true',
    verticals: [
      'Blank',
    ],
    colors: [
      'white',
      'blue',
      'black',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Loja Scarlet',
    id: 1031170,
    order: -600,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/380352259df94b6d965eb04e2f55d0ad/siteTemplateIcons/knhYwdSsSJ2ISEDCQNH9_Scarlet_shop_Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/380352259df94b6d965eb04e2f55d0ad/siteTemplateIcons/0xa51PD0TKyJDb9DGrfl_Scarlet_shop_3Devices.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/380352259df94b6d965eb04e2f55d0ad/siteTemplateIcons/75NzEq8nRECXf6ozBHPo_Scarlet_shop_Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/380352259df94b6d965eb04e2f55d0ad/siteTemplateIcons/aVgscq2iQEq4lfT5JSNT_Scarlet_shop_mobile.png',
    demoSiteUrl: '/site/dm-theme-1031170-pt-389?preview=true',
    verticals: [
      'LifestyleHealth',
      'OnlineStore',
    ],
    colors: [
      'orange',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: true,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Barbearia',
    id: 1003075,
    order: -580,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/fad888e0/siteTemplateIcons/JP4Wm7oYQqZaijr4a6JD_barber_shop_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/fad888e0/siteTemplateIcons/BvbhqfI8TTGvihpgcePr_Barber_shop_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/fad888e0/siteTemplateIcons/iyH0AO4S7MMt22vwuhJA_barber_shop_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/fad888e0/siteTemplateIcons/EqCJkAW7RvyuCR8T7hkX_barber_shop_mobile.png',
    demoSiteUrl: '/site/dm-theme-1003075-pt-381?preview=true',
    verticals: [
      'LifestyleHealth',
      'Business',
    ],
    colors: [
      'white',
      'brown',
      'black',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 1,
    isCustom: false,
  },
  {
    name: 'Baristro',
    id: 1002820,
    order: -560,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/28a712cc/siteTemplateIcons/Hqk9x5iBSmKPm2D0qCj5_baristo_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/28a712cc/siteTemplateIcons/OrZN7y37T9mnqrxhVMk6_Baristo_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/28a712cc/siteTemplateIcons/nvjQ87V4SWyupCyrG5n0_baristo_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/28a712cc/siteTemplateIcons/Kjoq9ytTVGM3LkuJ9ibR_baristo_mobile.png',
    demoSiteUrl: '/site/dm-theme-1002820-pt-372?preview=true',
    verticals: [
      'RestaurantFood',
    ],
    colors: [
      'white',
      'black',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: 'www.thecamel.co',
    isCommunity: true,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Controle de pragas',
    id: 1005445,
    order: -540,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/42dbb25715654c5ba1e4bd39736194bd/siteTemplateIcons/7MS3kDSSyS9O0uz5Z7pS_pest_control_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/42dbb25715654c5ba1e4bd39736194bd/siteTemplateIcons/3Del5Nt9RMO23TnB681h_Pest_control_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/42dbb25715654c5ba1e4bd39736194bd/siteTemplateIcons/ULVeBKOrSFuNH7CrOMaq_pest_control_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/42dbb25715654c5ba1e4bd39736194bd/siteTemplateIcons/Xn22sRATTOfH3s5LNPDP_pest_control_mobile.png',
    demoSiteUrl: '/site/dm-theme-1005445-pt-372?preview=true',
    verticals: [
      'Business',
    ],
    colors: [
      'white',
      'orange',
      'light-blue',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 3,
    isCustom: false,
  },
  {
    name: 'Registrar loja',
    id: 1003458,
    order: -520,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/a8eba94ae7134ba39b3f277a75b152fe/siteTemplateIcons/87j25DiqTKqxTLheVPAp_record_store_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/a8eba94ae7134ba39b3f277a75b152fe/siteTemplateIcons/MH1RGUX6RKKzk2k3u4nL_Record_store_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/a8eba94ae7134ba39b3f277a75b152fe/siteTemplateIcons/qKEpNIjKTg2soHzth6Mx_recor_store_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/a8eba94ae7134ba39b3f277a75b152fe/siteTemplateIcons/1yX9L5NQ8OHtDFz0BUcK_record_store_mobile.png',
    demoSiteUrl: '/site/dm-theme-1003458-pt-379?preview=true',
    verticals: [
      'OnlineStore',
      'Business',
    ],
    colors: [
      'white',
      'black',
      'red',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: true,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Soap & Suds',
    id: 1005052,
    order: -500,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/a2cf0f4608254457a15c7b9c6564b052/siteTemplateIcons/uOYGLBxkRKSgwGCbliPq_soap_suds_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/a2cf0f4608254457a15c7b9c6564b052/siteTemplateIcons/ejIGQx0QRQyBWc18Eokg_Soap_and_suds_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/a2cf0f4608254457a15c7b9c6564b052/siteTemplateIcons/7ldGQ1cRTZiSp3F40YDK_soap_suds_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/a2cf0f4608254457a15c7b9c6564b052/siteTemplateIcons/xCB7H9Y6QS63hGlCfL4m_soap_suds_mobile.png',
    demoSiteUrl: '/site/dm-theme-1005052-pt-372?preview=true',
    verticals: [
      'LifestyleHealth',
      'Business',
    ],
    colors: [
      'white',
      'light-blue',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 3,
    isCustom: false,
  },
  {
    name: 'Loja de brinquedos',
    id: 1004111,
    order: -480,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/4625136619264c5d8b22d671f9da34ea/siteTemplateIcons/L6BtRCEARIuc6yFJF32q_toy_store_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/4625136619264c5d8b22d671f9da34ea/siteTemplateIcons/CFRT7gTJOWDbkNTFqPQO_Toy_store_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/4625136619264c5d8b22d671f9da34ea/siteTemplateIcons/DK5YXTwRBOJc4QXNdmLg_toy_store_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/4625136619264c5d8b22d671f9da34ea/siteTemplateIcons/xZVaV93SySmolvClFZo6_toy_store_mobile.png',
    demoSiteUrl: '/site/dm-theme-1004111-pt-379?preview=true',
    verticals: [
      'OnlineStore',
      'Business',
    ],
    colors: [
      'green',
      'white',
      'blue',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: true,
    numOfPages: 3,
    isCustom: false,
  },
  {
    name: 'Férias',
    id: 1000772,
    order: -460,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/61d351216a1342b9bb02e11f5fef2927/siteTemplateIcons/AP56WPZ0QsK9AMSgAY4r_vacation_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/61d351216a1342b9bb02e11f5fef2927/siteTemplateIcons/Q0cI9ADiRwyW51bSMFvK_Vacation_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/61d351216a1342b9bb02e11f5fef2927/siteTemplateIcons/Nx8VohfASSyp4Izzz5bz_vacation_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/61d351216a1342b9bb02e11f5fef2927/siteTemplateIcons/tpH71RsXTayjiTXLfqCg_vacation_mobile.png',
    demoSiteUrl: '/site/dm-theme-1000772-pt-372?preview=true',
    verticals: [
      'Travel',
      'Business',
    ],
    colors: [
      'white',
      'brown',
      'black',
      'red',
      'orange',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 1,
    isCustom: false,
  },
  {
    name: 'Clínica veterinária',
    id: 1004112,
    order: -440,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/656df6b092a643478c7bbaa98ef220a0/siteTemplateIcons/UfUZatK9SiC7Tk73sSRR_veterinary_clinic__desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/656df6b092a643478c7bbaa98ef220a0/siteTemplateIcons/Q2yqWvFcTq6QouCifhTA_Vet_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/656df6b092a643478c7bbaa98ef220a0/siteTemplateIcons/by3ci5dTWWbMUwlOFgqg_veterinary_clinic_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/656df6b092a643478c7bbaa98ef220a0/siteTemplateIcons/otxF4XIgS6q1wrfIGNpe_veterinary_clinic_mobile.png',
    demoSiteUrl: '/site/dm-theme-1004112-pt-372?preview=true',
    verticals: [
      'LifestyleHealth',
      'Business',
    ],
    colors: [
      'white',
      'light-blue',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Ioga',
    id: 1002716,
    order: -420,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/yoga_template/siteTemplateIcons/CGI2lQqcSyVDJjGENYJr_yoga_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/yoga_template/siteTemplateIcons/9qIvfQGhSAu3hYvSQWmw_Yoga_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/yoga_template/siteTemplateIcons/eJz6rbwQ8G3HOcIVQulb_yoga_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/yoga_template/siteTemplateIcons/CBSKlOpSWuIRQE27zs6g_yoga_mobile.png',
    demoSiteUrl: '/site/dm-theme-1002716-pt-372?preview=true',
    verticals: [
      'LifestyleHealth',
      'ProfessionalServices',
    ],
    colors: [
      'white',
      'yellow',
      'orange',
      'light-blue',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 3,
    isCustom: false,
  },
  {
    name: 'Esteticista',
    id: 1003739,
    order: -400,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/67dd84bbbc50483986e33fe0d6005fe4/siteTemplateIcons/Va4SreyRQf2k1jleJD1v_beautican_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/67dd84bbbc50483986e33fe0d6005fe4/siteTemplateIcons/J54DNSzmTTmR4dueN2J9_Beautican_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/67dd84bbbc50483986e33fe0d6005fe4/siteTemplateIcons/ZILYFo4SOeQeTlPGKhOE_beautican_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/67dd84bbbc50483986e33fe0d6005fe4/siteTemplateIcons/zdjIiZRkQqmgZw0fxXQt_beautican_mobile.png',
    demoSiteUrl: '/site/dm-theme-1003739-pt-372?preview=true',
    verticals: [
      'LifestyleHealth',
      'ProfessionalServices',
      'Business',
    ],
    colors: [
      'white',
      'brown',
      'pink',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 3,
    isCustom: false,
  },
  {
    name: 'Meu Blog Bicicleta',
    id: 1002785,
    order: -380,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/23c76aa6/siteTemplateIcons/fzgEkAoQRkKBTMansMZz_Bicycle%20blog%20Desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/23c76aa6/siteTemplateIcons/VPb5Vu1SniAxas4dF6ZN_Bicycle%20Blog%203%20screens.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/23c76aa6/siteTemplateIcons/kBktYb84Ro6OPvdMiLTA_bicycle blog Tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/23c76aa6/siteTemplateIcons/p5wR3q5wQem3xpAHTdB2_Bicycle blog Mobile.png',
    demoSiteUrl: '/site/dm-theme-1002785-pt-389?preview=true',
    verticals: [
      'Blog',
    ],
    colors: [
      'white',
      'black',
      'yellow',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: true,
    hasStore: false,
    numOfPages: 1,
    isCustom: false,
  },
  {
    name: 'Consultor nutricional',
    id: 1003734,
    order: -360,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/c73d3be441b544c0a9275e8ed3ed0006/siteTemplateIcons/wIgsjhYNRQCKu0IpHihE_nutrition_advisor_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/c73d3be441b544c0a9275e8ed3ed0006/siteTemplateIcons/JzRlZcSwRH29Fwskrr0N_Nutrition_advisor_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/c73d3be441b544c0a9275e8ed3ed0006/siteTemplateIcons/01gnbKcZTPqXwdaZKiuk_nutrition_advisor_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/c73d3be441b544c0a9275e8ed3ed0006/siteTemplateIcons/2EyG8HTNRJSa5uWFEHzp_nutrition_advisor_mobile.png',
    demoSiteUrl: '/site/dm-theme-1003734-pt-389?preview=true',
    verticals: [
      'LifestyleHealth',
      'ProfessionalServices',
      'Blog',
    ],
    colors: [
      'green',
      'white',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: true,
    hasStore: false,
    numOfPages: 3,
    isCustom: false,
  },
  {
    name: 'Portfolio',
    id: 1002713,
    order: -340,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/portfolio_template/siteTemplateIcons/3CpFYdxSRRusBH57iud4_portfolio_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/portfolio_template/siteTemplateIcons/9ue4td0Sf2lhiSslViRI_Portfolio_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/portfolio_template/siteTemplateIcons/eBZS0mS5Krg4ZuR5oPgi_portfolio_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/portfolio_template/siteTemplateIcons/2E1WQPUSoyxduIRPgkfL_portfolio_mobile.png',
    demoSiteUrl: '/site/dm-theme-1002713-pt-372?preview=true',
    verticals: [
      'PortfolioResume',
    ],
    colors: [
      'white',
      'black',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Produto',
    id: 1003040,
    order: -320,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/ce1f372c/siteTemplateIcons/HTcxpDXET06cCzxbzSPa_product_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/ce1f372c/siteTemplateIcons/pltDu1S0SIi3FJTqulSD_Product_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/ce1f372c/siteTemplateIcons/H9pF70hmTFfqHJKG1CdM_product_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/ce1f372c/siteTemplateIcons/3YXEQlI7RX5OvHWoOIgB_product_mobile.png',
    demoSiteUrl: '/site/dm-theme-1003040-pt-372?preview=true',
    verticals: [
      'PortfolioResume',
      'Business',
    ],
    colors: [
      'white',
      'purple',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 2,
    isCustom: false,
  },
  {
    name: 'Jazz Festival',
    id: 1005444,
    order: -280,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/4b7659248d9b479a90ee091236170e0d/siteTemplateIcons/oRThlJ3TOSgU1RZ0wzZw_jazz_festival_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/4b7659248d9b479a90ee091236170e0d/siteTemplateIcons/yvbkn4RKQK85BHfOdLDe_Jazz_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/4b7659248d9b479a90ee091236170e0d/siteTemplateIcons/ANcf7AJtS7GBSy4h5EeN_jazz_festival_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/4b7659248d9b479a90ee091236170e0d/siteTemplateIcons/MkaRkMtS3KrMcBAZqKNa_jazz_festival_mobile.png',
    demoSiteUrl: '/site/dm-theme-1005444-pt-372?preview=true',
    verticals: [
      'Events',
      'Business',
    ],
    colors: [
      'black',
      'red',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 3,
    isCustom: false,
  },
  {
    name: 'LifeStyle',
    id: 1002715,
    order: -260,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/lifestyle_template/siteTemplateIcons/alKecN3rThKVf6zINI7n_lifestyle_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/lifestyle_template/siteTemplateIcons/1wqgUoBTnamtu0M1UVwR_Lifestyl_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/lifestyle_template/siteTemplateIcons/1vZcXbEyTXVrNISGV73Y_lifestyle_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/lifestyle_template/siteTemplateIcons/TDkyTx61TsCBuV8N7O37_lifestyle_mobile.png',
    demoSiteUrl: '/site/dm-theme-1002715-pt-372?preview=true',
    verticals: [
      'LifestyleHealth',
      'Blog',
    ],
    colors: [
      'white',
      'yellow',
      'orange',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: true,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Floricultura',
    id: 1004110,
    order: -240,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/aee0f8c56d634e6f969aeed4c5bc9fc4/siteTemplateIcons/eYs4FiVQkmUoOyxxNcPd_flower_store_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/aee0f8c56d634e6f969aeed4c5bc9fc4/siteTemplateIcons/xyh3pVfHQ6C7RssrH2ZH_Flower_store_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/aee0f8c56d634e6f969aeed4c5bc9fc4/siteTemplateIcons/NK8oQFkOTEG0FbHqhqXX_flower_store_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/aee0f8c56d634e6f969aeed4c5bc9fc4/siteTemplateIcons/tW6yISIT5yHHRg0oakt7_flower_store_mobile.png',
    demoSiteUrl: '/site/dm-theme-1004110-pt-379?preview=true',
    verticals: [
      'OnlineStore',
    ],
    colors: [
      'white',
      'blue',
      'pink',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: true,
    numOfPages: 3,
    isCustom: false,
  },
  {
    name: 'Serviços de limusine',
    id: 1004642,
    order: -220,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/853a570662834d54b6f9dfef2ad7f425/siteTemplateIcons/B3yhuKXURW4nVbMcOtyi_limo_service_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/853a570662834d54b6f9dfef2ad7f425/siteTemplateIcons/yT06ELYRzmTgbKuqIXZw_Limo_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/853a570662834d54b6f9dfef2ad7f425/siteTemplateIcons/5aMlXO2nTv6xwmdI7NqR_limo_service_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/853a570662834d54b6f9dfef2ad7f425/siteTemplateIcons/ehO2xv37QBmriiS24RrM_limo_service_mobile.png',
    demoSiteUrl: '/site/dm-theme-1004642-pt-372?preview=true',
    verticals: [
      'Travel',
      'ProfessionalServices',
      'Business',
    ],
    colors: [
      'white',
      'red',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 3,
    isCustom: false,
  },
  {
    name: 'Blank Parallax - RTL',
    id: 1003460,
    order: 0,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/c73e0ea30329419b8e04edc9c3873e64/siteTemplateIcons/SAFT4tTUSpuO4BWNVnY9_blank_parallax_rtl_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/c73e0ea30329419b8e04edc9c3873e64/siteTemplateIcons/SruLyLCR1WeIzfOhGCTw_RTL_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/c73e0ea30329419b8e04edc9c3873e64/siteTemplateIcons/bJqqlsZAQXGRDIBQswey_blank_parallax_rtl_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/c73e0ea30329419b8e04edc9c3873e64/siteTemplateIcons/410De7UTQbG5u3aGOP60_blank_parallax_rtl_mobile.png',
    demoSiteUrl: '/site/dm-theme-1003460-pt-311?preview=true',
    verticals: [
      'Blank',
    ],
    colors: [
      'white',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 4,
    isCustom: false,
  },
  {
    name: 'Life Coach',
    id: 1003735,
    order: 0,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/5ee6e750308d4497b2410e796941dff9/siteTemplateIcons/EbozxzdnT8mb6qQvmduk_lifecoach_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/5ee6e750308d4497b2410e796941dff9/siteTemplateIcons/jXC8hxstSGK7cXeZOg5p_Lifecoach_BigPreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/5ee6e750308d4497b2410e796941dff9/siteTemplateIcons/3Up9Sb9TluMcIp28C1Uo_life_coach_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/5ee6e750308d4497b2410e796941dff9/siteTemplateIcons/wP7COZQiRumGknp55DNj_life_coach_mobile.png',
    demoSiteUrl: '/site/dm-theme-1003735-pt-311?preview=true',
    verticals: [
      'Blog',
      'Business',
    ],
    colors: [
      'green',
      'blue',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: true,
    hasStore: false,
    numOfPages: 3,
    isCustom: false,
  },
  {
    name: 'Jane Photography',
    id: 1060503,
    order: 2147483647,
    siteTemplateId: -1,
    iconPath: 'https://irp-cdn.multiscreensite.com/6a618b8d/siteTemplateIcons/HiepSLPFRYm1s4KnWzd0_jane_photography_desktop.png',
    thumbPath: 'https://irp-cdn.multiscreensite.com/6af0b9363ac94721bc91b536a28e27f5/siteTemplateIcons/1b3YyLTsSwHVs1CVf4Aw_Jane_photography_bigpreview.png',
    tabletIconPath: 'https://irp-cdn.multiscreensite.com/6a618b8d/siteTemplateIcons/ikRA88JS02KlFUpnLKGg_jane_photography_tablet.png',
    mobileIconPath: 'https://irp-cdn.multiscreensite.com/6a618b8d/siteTemplateIcons/leZZSEfQC28Njo9JSpTA_jane_photography_mobile.png',
    demoSiteUrl: '/site/dm-theme-1060503-pt-389?preview=true',
    verticals: [
      'PortfolioResume',
    ],
    colors: [
      'white',
      'black',
      'gray',
    ],
    isNew: 'false',
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: false,
    editSiteUrl: null,
    hasBlog: false,
    hasStore: false,
    numOfPages: 1,
    isCustom: false,
  },
  {
    name: 'Guincho',
    id: 1088929,
    order: 2147483647,
    siteTemplateId: 1088929,
    iconPath: 'https://irp-cdn.multiscreensite.com/830b52c9/screenshots/Screenshot.png?timestamp=1570560325000&updated=true',
    thumbPath: null,
    tabletIconPath: null,
    mobileIconPath: null,
    demoSiteUrl: '/site/830b52c9?preview=true',
    verticals: [
      'CUSTOM',
    ],
    colors: [

    ],
    isNew: null,
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: null,
    editSiteUrl: '/home/site/830b52c9',
    hasBlog: false,
    hasStore: false,
    numOfPages: 1,
    isCustom: true,
  },
  {
    name: 'VIDRAÇARIA',
    id: 1090451,
    order: 2147483647,
    siteTemplateId: 1090451,
    iconPath: 'https://irp-cdn.multiscreensite.com/93a770e7/screenshots/Screenshot.png?timestamp=1573472240454&updated=true',
    thumbPath: null,
    tabletIconPath: null,
    mobileIconPath: null,
    demoSiteUrl: '/site/93a770e7?preview=true',
    verticals: [
      'CUSTOM',
    ],
    colors: [

    ],
    isNew: null,
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: null,
    editSiteUrl: '/home/site/93a770e7',
    hasBlog: false,
    hasStore: false,
    numOfPages: 1,
    isCustom: true,
  },
  {
    name: 'SALAOBELEZA',
    id: 1091752,
    order: 2147483647,
    siteTemplateId: 1091752,
    iconPath: 'https://irp-cdn.multiscreensite.com/9db0724f/screenshots/Screenshot.png?timestamp=1573472240495&updated=true',
    thumbPath: null,
    tabletIconPath: null,
    mobileIconPath: null,
    demoSiteUrl: '/site/9db0724f?preview=true',
    verticals: [
      'CUSTOM',
    ],
    colors: [

    ],
    isNew: null,
    canBuildFromUrl: false,
    exampleSites: [

    ],
    attributionLink: null,
    isCommunity: null,
    editSiteUrl: '/home/site/9db0724f',
    hasBlog: false,
    hasStore: false,
    numOfPages: 1,
    isCustom: true,
  },
];

export default templates;
