
const styles = (theme) => ({

  root: {
    height: 380,
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  speedDial: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },

});

export default styles;
