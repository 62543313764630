import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

// eslint-disable-next-line import/no-extraneous-dependencies
import reducers from './ducks';
import sagas from './sagas';
import PersisReducer from './persistReducer';

const middlewares = [];

const sagaMiddleware = createSagaMiddleware();

middlewares.push(sagaMiddleware);

const composer = process.env.NODE_ENV
  ? compose(
    applyMiddleware(...middlewares),
  )
  : applyMiddleware(...middlewares);

const store = createStore(PersisReducer(reducers), composer);
const persistor = persistStore(store);

sagaMiddleware.run(sagas);

const bucket = {
  store,
  persistor,
};


export default bucket;
