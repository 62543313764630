/* eslint-disable no-shadow */
export const Types = {
  LOGOUT_REQUEST: 'user/LOGOUT_REQUEST',
  GET_PLANOS_REQUEST: 'sites/GET_PLANOS_REQUEST',
  GET_PLANOS_SUCCESS: 'sites/GET_PLANOS_SUCCESS',
};

const INITAL_STATE = {
  loading: false,
  planos: [],
};

export default function plano(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.GET_PLANOS_REQUEST:
      return { ...state, loading: true };
    case Types.GET_PLANOS_SUCCESS:
      return { ...state, loading: false, planos: action.payload.planos };
    case Types.LOGOUT_REQUEST:
      return {
        loading: false,
        planos: [],
      };

    default:
      return state;
  }
}

export const Creators = {
  getPlanosRequest: () => ({
    type: Types.GET_PLANOS_REQUEST,
  }),
  getPlanosSuccess: (planos) => ({
    type: Types.GET_PLANOS_SUCCESS,
    payload: { planos },
  }),
};
