import themeEvah from '../../assets/theme';
import {darken} from 'polished';

const drawerWidth = 280;

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    maxWidth: drawerWidth,
    flexShrink: 0,
    
  },
  drawerPaper: {
    width: drawerWidth,
    maxWidth: drawerWidth,
    border: 'none',
    boxShadow: '5px 0px 6px #0000003B',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),

   overflowY: 'hidden',
  },
  toolbar: theme.mixins.toolbar,
  list: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: '#F5F5F5 0% 0% no-repeat padding-box',
    opacity: 1,
    alignItems: 'center',
  },
  avatar: {
    width: 50,
    height: 50,
  },
  perfil: {
    paddingTop: 20,
    paddingBottom: 0,
    paddingLeft: 20,
    paddingRight: 20,
  },
  perfilInfo: {
    marginLeft: 15,
  },

  word: {
    lineHeight: 'normal',
  },
  verify:{
    alignItems: 'center',
    justifyContent: 'center',
  },
  verifyButton: {
    border: '2px solid #02569C',
    color: '#02569C',
    fontSize: 12,
  },
  divider: {
    border: 'none',
    width: '80%',
    margin: '0 auto',
    marginTop:20,
    marginBottom:10,
    height: 1,
    margin: 0,
    flexShrink: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  icon : {
    width:20,
    height:20,
  },
  ListItem: {
    paddingLeft: 35,
    paddingRight: 35,
    '&:hover': {
      backgroundColor: '#0E9BD433'
    },
  },

  lastButton: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: '100%'
  },

  createButton: {
    background: darken(0.03, '#0ED464'),
    color: '#FFFFFF',
    paddingRight: 15,
    paddingRight: 15,
    '&:hover': {
      backgroundColor: darken(0.08, '#0ED464')
    },
  },

  menuButton: {
    color: 'white',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }

});

export default styles;
