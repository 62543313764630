import themeEvah from '../../../../../assets/theme';

const styles = (theme) => ({
  root: {
    maxWidth: themeEvah.contentSize.maxWidth,
    margin: themeEvah.contentSize.margin,
    padding: '50px 30px',
  },
  templateName: {
    fontWeight: 500,
    color: '#313131',
  },
  card: {
    height: '100%',
    display: 'flex',
    filter: 'grayscale(0.7)',
    transition: 'all .3s ease-in-out',
  },
  cardSelected: {
    height: '100%',
    display: 'flex',
    transform: 'scale(1.015)',
    boxShadow: '1px 1px 20px rgba(51, 51, 51, .4)',
  },
  cardMedia: {
    height: 'calc(100% - 60px)',
  },
  cardActionArea: {
    height: '100%',
  },
  price: {
    marginTop: 10,
    marginBottom: 5,
    width: '100%',
    textAlign: 'center',
    fontWeight: 400,
    color: 'rgb(8, 131, 180)',
    fontSize: 25,
  },
});


export default styles;
