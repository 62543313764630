import React from 'react';
import Grid from '@material-ui/core/Grid';


import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import styles from './styles';
import PlanCard from '../../../components/PlanCard';

const vender = [
  {
    title: 'Negócios',
    price: 'R$ 89,90/mês',
    cto: 'Compre por site',
    itens: ['1 Website', 'Hospedagem AWS', 'Suporte via E-mail'],
    adicionais: ['+ R$97,00 Loja Virtual'],
  }, {
    title: 'Agências',
    price: 'R$ 392,00/mês',
    cto: 'Economize',
    itens: ['8 Websites', 'Hospedagem AWS', 'Suporte via E-mail e Telefone', 'Gerente de contas'],
    adicionais: ['+ R$49,00 Site Adicional', '+ R$49,00 Loja Virtual'],
  }];

const promover = [
  {
    title: 'Google Ads',
    price: 'R$ 209,00/mês',
    cto: 'Compre por site',
    itens: ['4 Anúncios', '12 Palavras Chaves', 'Rede de Pesquisa'],
    adicionais: ['+ R$209,00 Rede de Display', '+ R$209,00 Google Shopping'],
  }, {
    title: 'Remarketing',
    price: 'R$ 209,00/mês',
    cto: 'Compre por site',
    itens: ['Perfeito para novas empresas', 'Até 10 produtos '],
    adicionais: ['+ R$209,00 Facebook Timeline', '+ R$209,00 Instagram Stories'],
  }];


function OptionSelector({ classes }) {
  return (
    <Grid container spacing={2} direction="row" alignItems="stretch" className={classes.container}>
      <Typography className={classes.title}>Conheça nossos planos</Typography>
      <Typography className={classes.subTitle}>Você cuida dos negócios. Nós
      encontramos os clientes.
      </Typography>

      <Grid container spacing={4} direction="row" alignItems="stretch">
        <Grid item xs={12} sm={6} className={classes.flexCenter}>
          <Typography className={classes.planTitle}>Vender</Typography>
          <Grid container spacing={2} direction="row" className={classes.height100} alignItems="stretch">
            {vender.map((venda) => (
              <Grid key={venda.title} item xs={12} sm={6} className={classes.flexCenter}>
                <PlanCard title={venda.title} price={venda.price} cto={venda.cto} itens={venda.itens} adicionais={venda.adicionais} />
              </Grid>

            ))}

          </Grid>

        </Grid>

        <Grid item xs={12} sm={6} className={classes.flexCenter}>
          <Typography className={classes.planTitle}>Promover</Typography>
          <Grid container spacing={2} direction="row" className={classes.height100} alignItems="stretch">
            {promover.map((venda) => (
              <Grid key={venda.title} item xs={12} sm={6} className={classes.flexCenter}>
                <PlanCard title={venda.title} price={venda.price} cto={venda.cto} itens={venda.itens} adicionais={venda.adicionais} />
              </Grid>

            ))}
          </Grid>

        </Grid>
      </Grid>

    </Grid>
  );
}

export default withStyles(styles)(OptionSelector);
