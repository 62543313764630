import themeEvah from '../../../assets/theme';

const styles = (theme) => ({
  outer_container: {
    height: 420,
    margin: '50px auto 150px',
    width: '100%',
    maxWidth: 900,

    '@media (max-width: 900px)': {
      maxWidth: 'unset',
      height: 1070,
      margin: '20px 0 150px',
    },
  },

  containerTitle: {
    textAlign: 'center',
    fontWeight: '700',
    fontFamily: 'Open Sans',
    fontSize: 39,
    color: '#333',
    marginBottom: 50,

    '@media (max-width: 960px)': {
      fontSize: 30,
      lineHeight: '1.2',
      marginBottom: 30,

    },
  },

  container: {
    margin: '0 10px',
    padding: '20px 0',
    width: 275,
    height: 410,
    borderRadius: 10,
    float: 'left',
    position: 'relative',
    top: 0,
    zIndex: 1,
    cursor: 'pointer',
    transition: 'all 0.4s ease',

    '& a': {
      textDecoration: 'none',
      color: '#fff',
      fontFamily: 'Open Sans',
      fontWeight: '600',
      fontSize: 18,
      width: 170,
      lineHeight: '40px',
      display: 'block',
      borderRadius: 30,
      position: 'absolute',
      bottom: 35,
      left: 0,
      right: 0,
      margin: '0 auto',
      textAlign: 'center',
      transition: 'all 0.4s ease',
    },

    '&:hover': {
      zIndex: 3,
      top: '-2.5px',
      height: 415,
      color: '#fff',
      width: 300,
      marginLeft: '-15px',
      marginRight: '-15px',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, .16)',
    },

    '@media (max-width: 900px)': {
      float: 'unset',
      margin: '10px auto',
      height: 390,

      '&:hover': {
        width: 275,
        height: 420,
        marginTop: '-5px',
        marginBottom: '-5px',
        marginLeft: 'auto',
        marginRight: 'auto',
      },

    },
  },

  container1: {
    background: '#F5F5F5',
    color: '#333333',
    transition: 'all 0.6s ease',

    '&:hover': {
      background: 'transparent linear-gradient(142deg, #7AD6FF 0%, #41B1E3 100%) 0% 0% no-repeat padding-box',

      '& a': {
        background: '#fff',
        color: '#28B6F6',
      },
    },

    '& a': {
      background: '#28B6F6',
    },

  },

  container2: {
    background: '#F5F5F5',
    color: '#333333',
    transition: 'all 0.6s ease',

    '&:hover': {
      background: 'transparent linear-gradient(142deg, #11BDFF 0%, #009BD6 100%) 0% 0% no-repeat padding-box',

      '& a': {
        background: '#fff',
        color: '#009BD6',
      },
    },

    '& a': {
      background: '#009BD6',
    },
  },

  container3: {
    color: '#333333',
    background: '#F5F5F5',
    transition: 'all 0.6s ease',

    '&:hover': {
      background: 'transparent linear-gradient(142deg, #0371CC 0%, #055AA0 100%) 0% 0% no-repeat padding-box',

      '& a': {
        background: '#fff',
        color: '#02569C',
      },
    },

    '& a': {
      background: '#02569C',
    },

    '@media (max-width: 900px)': {
      height: 350,
    },
  },

  flag: {
    fontFamily: 'Open Sans',
    background: '#02569C',
    color: '#fff',
    padding: '7px 0',
    position: 'absolute',
    top: '30px',
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: '1.1rem',

    '@media (max-width: 900px)': {
      top: '18px',
    },
  },

  title: {
    fontFamily: 'Open Sans',
    fontSize: '1.4rem',
    textAlign: 'center',
    marginTop: 65,

    '@media (max-width: 900px)': {
      marginTop: 50,
    },
  },

  subtitle: {
    fontFamily: 'Open Sans',
    fontSize: '1.3rem',
    fontWeight: 700,
    textAlign: 'center',
    marginTop: 15,
  },

  main_number: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    marginTop: 30,
    textAlign: 'center',
    fontSize: 30,
  },
  consulte: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    marginTop: 40,
    textAlign: 'center',
    fontSize: 28,
  },

  number: {
    fontFamily: 'Open Sans',
    fontSize: 40,
  },

  container_text: {
    marginTop: 20,
    textAlign: 'center',

    '& span': {
      display: 'block',
    },
  },

  container_text1: {
    fontFamily: 'Open Sans',
    fontSize: 18,
  },

  container_text2: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    marginTop: 40,
    fontWeight: 600,
  },

});

export default styles;
