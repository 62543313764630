import themeEvah from '../../../assets/theme';

const styles = (theme) => ({
  root: {
    padding: themeEvah.contentSize.padding,
    paddingTop: 70,
    paddingBottom: 0,

    [theme.breakpoints.down('sm')]: {
      marginBottom: 70,
    },
  },
  mainTitle: {
    marginBottom: '140px',
    fontFamily: 'Open Sans',
    fontWeight: '700',
    color: '#333',
    fontSize: 39,

    [theme.breakpoints.down('sm')]: {
      marginBottom: 40,
      fontSize: 30,
      lineHeight: '1.2',
    },
  },
  title: {
    fontFamily: 'Avenir Roman',
    marginBottom: theme.spacing(2),
    fontSize: 30,

    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
    },

  },
  text: {
    color: '#333',
    fontSize: 18,

    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },

    '& span': {
      fontWeight: 700,
    },
  },
  item: {
    position: 'relative',

    '&:nth-child(odd)': {
      '& div:has(img)': {
        background: 'blue',
      },
    },
  },
  itemText: {
    padding: '0px 80px 150px',
    textAlign: 'right',

    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px 100px',
      textAlign: 'left',
      '&:nth-child(even)': {
        borderLeft: 'none',
      },
    },
  },
  itemImage: {
    padding: '0px 80px 150px',

    borderLeft: '7px solid #CFCFCF',

    '& img': {
      marginTop: '-70px',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px 70px',
      '&:nth-child(even)': {
        borderLeft: 'none',
        textAlign: 'center',
      },
    },
  },
  lastImage: {
    '&:nth-child(even)': {
      borderLeft: 'none',
    },
  },
  circle: {
    '&::after': {
      content: '" "',
      background: '#009BD6',
      width: 40,
      height: 40,
      borderRadius: '50%',
      position: 'absolute',
      top: '0',
      left: '50%',
      transform: 'translateX(-42%)',
    },
    [theme.breakpoints.down('sm')]: {
      '&::after': {
        display: 'none',
      },
    },
  },
  mdOrder0: {
    [theme.breakpoints.up('md')]: {
      order: '0',
      borderLeft: 'none',
    },
  },
  mdOrder1: {
    [theme.breakpoints.up('md')]: {
      order: '1',
      textAlign: 'left',
      borderLeft: '7px solid #CFCFCF',
    },
  },
  img: {
    width: '100%',
    height: 'auto',
    maxWidth: '400px',
  },
});


export default styles;
