export const Types = {
  LOGOUT_REQUEST: 'user/LOGOUT_REQUEST',
  PAY_REQUEST: 'payment/PAY_REQUEST',
  PAY_SUCCESS: 'payment/PAY_SUCCESS',
  PAY_FAILURE: 'payment/PAY_FAILURE',
  UNPUBLISH_REQUEST: 'payment/UNPUBLISH_REQUEST',
  UNPUBLISH_SUCCESS: 'payment/UNPUBLISH_SUCCESS',
  UNPUBLISH_FAILURE: 'payment/UNPUBLISH_FAILURE',
  RESET_REQUEST: 'payment/RESET_REQUEST',
};

const INITAL_STATE = {
  loading: false,
  error: null,
  errorUnpublish: null,

};

export default function payment(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_REQUEST:
      return {
        ...state,
        loading: false,
        error: null,
        errorUnpublish: null,
      };
    case Types.PAY_REQUEST:
      return { ...state, loading: true };
    case Types.PAY_SUCCESS:
      return { ...state, loading: false, error: false };
    case Types.PAY_FAILURE:
      return { ...state, loading: false, error: true };
    case Types.UNPUBLISH_REQUEST:
      return { ...state, loading: true };
    case Types.UNPUBLISH_SUCCESS:
      return { ...state, loading: false, errorUnpublish: false };
    case Types.UNPUBLISH_FAILURE:
      return { ...state, loading: false, errorUnpublish: true };
    case Types.LOGOUT_REQUEST:
      return {
        loading: false,
        error: null,
        errorUnpublish: null,
      };


    default:
      return state;
  }
}

export const Creators = {
  getReset: () => ({
    type: Types.RESET_REQUEST,
  }),
  paymentRequest: (body, site) => ({
    type: Types.PAY_REQUEST,
    payload: { body, site },
  }),
  paymentSuccess: () => ({
    type: Types.PAY_SUCCESS,
  }),
  paymentFailure: () => ({
    type: Types.PAY_FAILURE,
  }),
  unpublishSiteRequest: (site) => ({
    type: Types.UNPUBLISH_REQUEST,
    payload: { site },
  }),
  unpublishSiteSuccess: () => ({
    type: Types.UNPUBLISH_SUCCESS,
  }),
  unpublishSiteFailure: () => ({
    type: Types.UNPUBLISH_FAILURE,
  }),

};
