import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import styles from './styles';

function WhyUse({ classes }) {
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Typography variant="h3" component="h2" className={classes.containerTitle}>Por que usar a EVAH?</Typography>
        <Grid container spacing={4} className={classes.cardWrapper}>

          <Grid item xs={4} className={classes.item}>
            <div className={classes.card}>
              <Typography variant="h5" className={classes.cardTitle}>Facilidade de uso</Typography>
              <Typography variant="body1" className={classes.cardText}>Dispense o uso de plugins, os tema e tambén não precisa se preocupar com segurança. Nosso Editor robusto possui os mais recentes recursos.</Typography>
            </div>
          </Grid>

          <Grid item xs={4} className={classes.item}>
            <div className={classes.card}>
              <Typography variant="h5" className={classes.cardTitle}>Facilidade de gestão</Typography>
              <Typography variant="body1" className={classes.cardText}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantiu</Typography>
            </div>
          </Grid>

          <Grid item xs={4} className={classes.item}>
            <div className={classes.card}>
              <Typography variant="h5" className={classes.cardTitle}>Facilidade de instalação</Typography>
              <Typography variant="body1" className={classes.cardText}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantiu</Typography>
            </div>
          </Grid>

          <Grid item xs={4} className={classes.item}>
            <div className={classes.card}>
              <Typography variant="h5" className={classes.cardTitle}>Hospedagem e Domínio</Typography>
              <Typography variant="body1" className={classes.cardText}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantiu</Typography>
            </div>
          </Grid>

          <Grid item xs={4} className={classes.item}>
            <div className={classes.card}>
              <Typography variant="h5" className={classes.cardTitle}>Métricas do site</Typography>
              <Typography variant="body1" className={classes.cardText}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantiu</Typography>
            </div>
          </Grid>

          <Grid item xs={4} className={classes.item}>
            <div className={classes.card}>
              <Typography variant="h5" className={classes.cardTitle}>Permissões</Typography>
              <Typography variant="body1" className={classes.cardText}>Nosso editor possui usuários e permissões. Não precisa dar um acesso completo ao site para o cliente e todos os sites estão em um lugar só.</Typography>
            </div>
          </Grid>

        </Grid>
      </Container>
    </div>
  );
}

export default withStyles(styles)(WhyUse);
