import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import {
  Grid, Typography, withStyles, Hidden,
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';

import { Link, useHistory } from 'react-router-dom';

import styles from './styles';

import SignUp from '../../components/SignUp';

import Logo from '../../assets/logo-evah.svg';

import mp4Steps from '../../assets/videos/3-passos.mp4';
import webmSteps from '../../assets/videos/3-passos.webm';
import mp4Customize from '../../assets/videos/personalize.mp4';
import webmCustomize from '../../assets/videos/personalize.webm';
import mp4Publish from '../../assets/videos/publique.mp4';
import webmPublish from '../../assets/videos/publique.webm';
import SignInGoogle from '../../components/SignInGoogle';

const videos = [
  {
    title: 'Escolha um Template',
    text: 'Escolha um de nossos template, de o nome e domínio para projeto e coloque alguns dados opcionais do projeto.',
    webmPath: webmSteps,
    mp4Path: mp4Steps,
  },
  {
    title: 'Personalize Seu Site',
    text: 'Crie, edite e altere tanto o layout quanto conteúdo do site. Tudo isso sem custos.',
    webmPath: webmCustomize,
    mp4Path: mp4Customize,
  },
  {
    title: 'Configure e Publique',
    text: 'Agora com o projeto aprovado pelo cliente é só publicar o projeto e configurar o domínio. Pague somente nessa etapa!',
    webmPath: webmPublish,
    mp4Path: mp4Publish,
  },
];

function SignInSide({ classes }) {
  const [selectedValue, setSelectedValue] = React.useState(0);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const changeVideo = () => {
    if (selectedValue >= 0 && selectedValue < 2) {
      setSelectedValue(selectedValue + 1);
    } else {
      setSelectedValue(0);
    }
  };

  return (

    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} md={4} className={classes.leftPaper} component={Paper} elevation={6} square>


        <div className={classes.paper}>
          {/* <div className={classes.logoWrapper}>
                <Link to="/">
                  <img src={Logo} className={classes.logo} alt="logo EVAH" />
                </Link>
              </div> */}
          <div className={classes.signUpWrapper}>
            <Typography component="h5" align="center" variant="h5" className={classes.formTitle}>
                  Registro
            </Typography>
            {/*     <SignInGoogle />
            <Typography className={classes.observation2} align="center" variant="body1">
                Ou
            </Typography> */}
            <SignUp />
            <Typography className={classes.observation} align="center" variant="body1">
                Ja tem Conta? <a href="/login" className={classes.register}> Faça Login Aqui.</a>
            </Typography>
          </div>
        </div>

      </Grid>
      <Hidden smDown>
        <Grid item md={8} className={classes.image}>

          <Paper className={classes.container}>

            <Grid item align="center" className={classes.videoWrapper}>
              <Typography variant="h5" className={classes.containerTitle}>{videos[selectedValue].title}</Typography>
              <Typography variant="body1" align="left" className={classes.containerText}>
                {videos[selectedValue].text}
              </Typography>
              <video className={classes.video} src={videos[selectedValue].webmPath} width="600" onEnded={() => changeVideo()} autoPlay muted />
            </Grid>
            <Grid item align="center" className={classes.selector}>
              <Radio
                checked={selectedValue == 0}
                onChange={handleChange}
                value={0}
                color="default"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 0 }}
              />

              <Radio
                checked={selectedValue == 1}
                onChange={handleChange}
                value={1}
                color="default"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 1 }}
              />

              <Radio
                checked={selectedValue == 2}
                onChange={handleChange}
                value={2}
                color="default"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 2 }}
              />
            </Grid>

          </Paper>

        </Grid>
      </Hidden>
    </Grid>

  );
}

export default withStyles(styles)(SignInSide);
