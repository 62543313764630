import themeEvah from '../../../assets/theme';
import circle1 from '../../../assets/circle1.png';
import circle2 from '../../../assets/circle2.png';
import circle3 from '../../../assets/circle3.png';

const styles = (theme) => ({
  root: {
    marginTop: 100,

    [theme.breakpoints.down('sm')]: {
      marginTop: 60,
    },
  },
  containerTitle: {
    color: '#333333',
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: 39,
    textAlign: 'center',
    marginBottom: 20,

    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
      lineHeight: '1.2',
      marginBottom: 30,
    },
  },
  section: {
    padding: '50px 0',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginBottom: 50,
    },
  },
  card: {
    boxShadow: '0px 3px 6px rgba(0,0,0, .16)',
    padding: '10px 30px 15px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 210,
    maxWidth: 400,

    [theme.breakpoints.down('sm')]: {
      height: 'unset',
      padding: '30px 30px 25px 20px',
    },
  },
  card1: {
    borderLeft: '8px solid #28B6F6',
    position: 'relative',

    '&::before': {
      content: '" "',
      background: `url(${circle1}) no-repeat`,
      backgroundSize: 'contain',
      width: 80,
      height: 80,
      right: '-20px',
      top: '-20px',
      position: 'absolute',
    },

    [theme.breakpoints.down('sm')]: {
      '&::before': {
        right: '-15px',
      },
    },
  },
  card2: {
    borderLeft: '8px solid #009BD6',
    position: 'relative',

    '&::before': {
      content: '" "',
      background: `url(${circle2}) no-repeat`,
      backgroundSize: 'contain',
      width: 70,
      height: 70,
      right: '-40px',
      top: '-20px',
      position: 'absolute',
    },

    [theme.breakpoints.down('sm')]: {
      '&::before': {
        right: '-15px',
      },
    },
  },
  card3: {
    borderLeft: '8px solid #02569C',
    position: 'relative',

    '&::before': {
      content: '" "',
      background: `url(${circle3}) no-repeat`,
      backgroundSize: 'contain',
      width: 80,
      height: 80,
      right: '-10px',
      top: '-10px',
      position: 'absolute',
    },

    [theme.breakpoints.down('sm')]: {
      '&::before': {
        top: '-25px',
      },
    },
  },
  cardTitle: {
    fontFamily: 'Avenir Medium',
    fontSize: 28,
    marginBottom: theme.spacing(1),
  },
  cardText: {
    fontFamily: 'Open Sans',
    fontSize: 18,
    color: '#333333',

    '& span': {
      fontWeight: 600,
    },
  },
  video: {
    boxShadow: '2px 2px 15px rgba(0, 0, 0, .16)',
    borderRadius: '5px',
  },
  mdOrder0: {
    [theme.breakpoints.up('md')]: {
      order: '0',
    },
  },
  mdOrder1: {
    [theme.breakpoints.up('md')]: {
      order: '1',
    },
  },
});


export default styles;
