import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
  Grid, Typography, Button, DialogActions, DialogContentText, DialogContent,
} from '@material-ui/core';
import { useSelector } from 'react-redux';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';


import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './styles';
import {
  TextMaskCardNumber, TextMaskCardMonth, TextMaskCardYear, TextMaskCardCVC,
} from '../../../../MaskInput';

function CreditCard({
  classes, card, handleChange, error, setPaginate, paginate, handlePay,
}) {
  const loading = useSelector((state) => state.payment.loading);


  function handlepay(e) {
    e.preventDefault();
    handlePay();
  }


  return (
    <>

      <DialogContent>
        <DialogContentText>
          Preencha os campos abaixo com os dados do cartão.
        </DialogContentText>
        <ValidatorForm onSubmit={(e) => handlepay(e)}>
          <Grid container justify="center" spacing={2}>


            <Grid item xs={12} sm={6}>
              <TextValidator
                value={card.number}
                onChange={(e) => handleChange(e, 'number')}
                margin="dense"
                id="number"
                label="Número de cartão"
                type="text"
                fullWidth
                validators={['required']}
                errorMessages={['Este campo é obrigatorio']}
                InputProps={{
                  inputComponent: TextMaskCardNumber,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} />

            <Grid item xs={6} sm={4}>
              <TextValidator
                value={card.exp_month}
                onChange={(e) => handleChange(e, 'exp_month')}
                margin="dense"
                id="exp_month"
                label="Mês"
                type="number"
                fullWidth
                validators={['required', 'minNumber:0', 'maxNumber:12']}
                errorMessages={['Este campo é obrigatorio', 'Este campo só é possivel entre 0 e 12', 'Este campo só é possivel entre 0 e 12']}
                InputProps={{
                  inputComponent: TextMaskCardMonth,
                }}
              />

            </Grid>

            <Grid item xs={6} sm={4}>
              <TextValidator
                value={card.exp_year}
                onChange={(e) => handleChange(e, 'exp_year')}
                margin="dense"
                id="exp_year"
                label="Ano"
                type="number"
                fullWidth
                validators={['required', 'minNumber:2018']}
                errorMessages={['Este campo é obrigatorio', 'O ano de expiração deve ser valido']}
                InputProps={{
                  inputComponent: TextMaskCardYear,
                }}
              />

            </Grid>

            <Grid item xs={12} sm={4}>
              <TextValidator
                value={card.cvc}
                onChange={(e) => handleChange(e, 'cvc')}
                margin="dense"
                id="cvc"
                label="CVC"
                type="number"
                fullWidth
                validators={['required']}
                errorMessages={['Este campo é obrigatorio']}
                InputProps={{
                  inputComponent: TextMaskCardCVC,
                }}
              />

            </Grid>


            <Grid item xs={12}>
              <TextValidator
                value={card.name}
                onChange={(e) => handleChange(e, 'name')}
                margin="dense"
                id="name"
                label="Proprietário do cartão"
                type="text"
                validators={['required']}
                errorMessages={['Este campo é obrigatorio']}
                fullWidth
              />
            </Grid>


          </Grid>


          {error ? (
            <Typography variant="span" color="error" component="body1">
          Ocoreu algum erro, verifique seus dados!
            </Typography>
          ) : null}

          <DialogActions>

            {
                  loading ? (
                    <Grid item xs={12} className={classes.loading}>
                      <CircularProgress />
                    </Grid>
                  ) : (
                    <>
                      <Button onClick={() => { setPaginate(paginate - 1); }} color="primary">
                        Voltar
                      </Button>
                      <Button color="primary" type="submit">
                        Efetuar Pagamento
                      </Button>
                    </>
                  )
                }
          </DialogActions>
        </ValidatorForm>

      </DialogContent>


    </>
  );
}

export default withStyles(styles)(CreditCard);
