
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';


import { Creators as actions } from '../../../../store/ducks/payment';
import styles from './styles';

function ModalUnpublishSite({
  classes, open, setOpen, site,
}) {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.payment.errorUnpublish);
  const loading = useSelector((state) => state.payment.loading);


  useEffect(() => {
    if (error === false && open) { setOpen(false); }
  }, [error]);

  function handleExclue() {
    dispatch(actions.unpublishSiteRequest(site));
  }

  function resetRedux() {
    dispatch(actions.getReset());
  }


  return (


    <Dialog open={open} onEnter={() => resetRedux()} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Despublicar esse site?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          A publicação deste site pode ser feita novamente mais tarde !
        </DialogContentText>

        { error ? (
          <Typography variant="span" color="error" component="body1">
          Ocoreu algum erro, tente novamente mais tarde!
          </Typography>
        ) : null}
      </DialogContent>


      {loading

        ? (
          <Grid item xs={12} className={classes.loading}>
            <CircularProgress />
          </Grid>
        )

        : (
          <DialogActions>
            <Button onClick={() => setOpen(!open)} color="primary">
            Cancelar
            </Button>
            <Button color="primary" onClick={() => handleExclue()}>
            Despublicar Site
            </Button>
          </DialogActions>
        )}
    </Dialog>

  );
}

export default withStyles(styles)(ModalUnpublishSite);
