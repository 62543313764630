/* eslint-disable no-unused-vars */
import { call, put } from 'redux-saga/effects';


import { Creators as actions } from '../ducks/payment';

import api from '../../services/api';

export function* paymentRequest({ payload }) {
  try {
    

    const { body, site } = payload;


    const response = yield call(api.post, `site/publish/${site}`, body);

    yield put(actions.paymentSuccess());
  } catch (error) {
    yield put(actions.paymentFailure());
  }
}

export function* unpublishSiteRequest({ payload }) {
  try {
    const { site } = payload;


    const response = yield call(api.post, `site/unpublish/${site.site_name}`);

    console.log(response);

    yield put(actions.unpublishSiteSuccess());
  } catch (error) {
    yield put(actions.unpublishSiteFailure());
  }
}
