import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import {
  Grid, Card, CardActionArea, CardContent, Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Creators as actions } from '../../../../../store/ducks/plano';

import styles from './styles';

function Planos({
  open, classes, plano, setPlano,
}) {
  const dispatch = useDispatch();

  const planos = useSelector((state) => state.plano.planos);
  const loading = useSelector((state) => state.plano.loading);

  useEffect(() => {
    if (open) {
      dispatch(actions.getPlanosRequest());
    }
  }, [open]);

  useEffect(() => {
    if (planos[0]) setPlano(planos[0].id);
  }, [planos]);

  return (
    <Grid container>


      {
        loading
          ? (
            <Grid item xs={12} style={{ padding: 15, alignItems: 'center', textAlign: 'center' }}>
              <CircularProgress />
            </Grid>
          )


          : (planos && planos.map((value) => (
            <Grid item xs={12} sm={planos.length > 1 ? 6 : 12} style={{ padding: 15 }}>
              <Card
                className={value.id === plano ? classes.cardSelected : classes.card}
              >
                <CardActionArea
                  onClick={() => setPlano(value.id)}
                  className={classes.cardActionArea}
                >
                  <CardContent className={classes.cardContent}>
                    <Typography variant="subtitle1" component="h2" className={classes.price}>
                      {(value.amount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}/{value.interval === 'year' ? 'anual' : 'mensal'}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))

          )
      }

    </Grid>
  );
}

export default withStyles(styles)(Planos);
