
const styles = () => ({
  container: {
    backgroundColor: '#FFFFFF',
    padding: 20,
    height: '100%',

    overflowY: 'scroll',
  },

  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
    padding: 10,
  },

  modelAdress: {
    marginTop: 20,
  },


  Card: {
    padding: 10,
    minHeight: 200,
  },

  message: {
    marginTop: 50,
    textAlign: 'center',
    overflow: 'hidden',
  },

  progress: {
    marginTop: 100,
  },

  titleEmptyDash: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    color: '#333',
  },

  imgEmptyDash: {
    width: '100%',
    height: 'auto',
    maxWidth: 400,
    marginTop: 30,
  },

  siteComponentWrapper: {
    height: 'fit-content',
  },

});

export default styles;
