import React from 'react';
import { withStyles } from '@material-ui/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import EditProfile from './EditProfile';

import styles from './styles';

function ModalEditProfile({ classes, open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
    >


      <IconButton aria-label="close" className={classes.closeButton} onClick={() => setOpen(false)}>
        <CloseIcon />
      </IconButton>

      <DialogTitle className={classes.modalTitle}>Editar Perfil</DialogTitle>

      <DialogContent className={classes.dialog}>
        <EditProfile open={open} setOpen={setOpen} />
      </DialogContent>

    </Dialog>
  );
}

export default withStyles(styles)(ModalEditProfile);
