import React from 'react';
import { withStyles } from '@material-ui/styles';

import Header from './Header';
import Banner from './Banner';
import Difference from './Difference';
import WhyUse from './WhyUse';
import Highlights from './Highlights';
import CTA from './CTA';
import HowItWorks from './HowItWorks';
import PriceCards from './PriceCards';
import Footer from '../../components/Footer';

import styles from './styles';

function HomeCatherine({ classes }) {
  return (
    <div className={classes.root}>
      <Header />

      <Banner />

      <Difference />

      <CTA version={1} />

      <Highlights />

      <CTA version={2} />

      <HowItWorks />

      <CTA version={3} />

      <PriceCards />

      <Footer />
    </div>
  );
}


export default withStyles(styles)(HomeCatherine);
