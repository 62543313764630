import themeEvah from '../../../assets/theme';

const styles = (theme) => ({
  root: {
    maxWidth: themeEvah.contentSize.maxWidth,
    margin: themeEvah.contentSize.margin,
    height: '100%',
  },
  templateName: {
    fontWeight: 500,
    color: '#313131',
  },
  card: {
    height: '100%',
    display: 'flex',
    filter: 'grayscale(0.7)',
    transition: 'all .3s ease-in-out',
  },
  cardSelected: {
    height: '100%',
    display: 'flex',
    transform: 'scale(1.015)',
    boxShadow: '1px 1px 20px rgba(51, 51, 51, .4)',
  },
  cardMedia: {
    height: 'calc(100%)',
  },
  cardActionArea: {
    height: '100%',
  },
  list: {
    height: 'calc(100% - 50px)',

    overflow: 'scroll',
    overflowX: 'hidden',
  },

  formControl: {
    overflow: 'scroll',
    overflowX: 'hidden',
    height: 'calc(100% - 50px)',
  },

  select: {
    position: 'sticky',
    top: 0,
    [theme.breakpoints.down('md')]: {
      height: 'fit-content',
    },
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },

  },
});


export default styles;
