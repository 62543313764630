import themeEvah from '../../assets/theme';

const styles = (theme) => ({
  container: {
    marginTop: 50,
    marginBottom: 100,
    backgroundColor: '#eee',

  },
  bgGrey: {
    backgroundColor: '#eee',
  },
});


export default styles;
