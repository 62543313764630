
import theme from '../../../assets/themAlex';

const styles = () => ({
  font: {
    fontSize: theme.font.fontFamily,
    fontFamily: theme.font.fontFamily,
  },

  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    padding: '0px 0px 10px 5px',
    fontSize: 30,
    color: '#212121',
    fontWeight: 'bold',
  },

  subtitle: {
    fontSize: 20,
    color: '#212121',
    fontWeight: 'bold',
  },

  text: {
    marginTop: 10,
    color: 'rgb(33, 33, 33)',
  },

  radio: {
    marginRight: '15px',
    color: '#0099d6',
  },


  formControl: {
    margin: '0 24px',
  },

  img: {
    // width: '100%',
  },

  label: {
    margin: '5px auto',
    alignItems: 'end',

  },

  icon: {
    width: 30,
    height: 30,
  },

});

export default styles;
