/* eslint-disable no-unused-vars */
import { call, put } from 'redux-saga/effects';


import { Creators as actions } from '../ducks/sites';

import api from '../../services/api';

export function* getSites() {
  try {
    const response = yield call(api.get, 'user/find');

    yield put(actions.getSitesSuccess(response.data));
  } catch (error) {
    yield put(actions.getSitesError());
  }
}


export function* getTemplates() {
  try {
    const { data } = yield call(api.get, 'template/list');

    yield put(actions.getTemplatesSuccess(data));
  } catch (error) {
    yield put(actions.getTemplatesError());
  }
}


export function* createSiteRequest({ payload }) {
  try {
    console.log(payload);

    const response = yield call(api.post, '/user/create/site', payload.newSite);

    yield put(actions.createSiteSuccess());

    const newTab = window.open();
    newTab.location.href = response.data.url;
  } catch (error) {
    yield put(actions.createSiteFailure());
  }
}

export function* exclueSIteRequest({ payload }) {
  try {
    const { site, index } = payload;
    const response = yield call(api.delete, `user/delete/site/${site.site_name}`);


    yield put(actions.exclueSiteSuccess());
  } catch (error) {
    yield put(actions.exclueSiteFailure());
  }
}

export function* getPlanos() {
  try {
    const { data } = yield call(api.get, '/stripe/plan');

    yield put(actions.getPlanosSuccess(data));
  } catch (error) {
    yield put(actions.getTemplatesError());
  }
}
