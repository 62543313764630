
import theme from '../../assets/themAlex';

const styles = () => ({
  card: {
    fontFamily: theme.font.fontFamily,
    padding: '20px 30px 30px!important',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'center',
    alignContent: 'center',
    height: '89%',
    boxShadow: 'rgba(0,0,0,.16) -3px 3px 11px 0!important',
  },

  img: {
    width: '100%',
    height: 175,
  },

  title: {
    marginTop: 10,
    marginBottom: 10,
    alignSelf: 'center',
    fontWeight: 600,
    color: 'rgb(33, 33, 33)',
    fontSize: 20,

  },

  content: {
    fontSize: theme.font.fontSize,
    fontWeight: 400,
    color: 'rgb(33, 33, 33)',
  },

});

export default styles;
