import React, { useState } from 'react';

import { withStyles, Button, CircularProgress } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import styles from './styles';
import { Creators as actions } from '../../store/ducks/user';


function RecoveryPass({ classes }) {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const loading = useSelector((state) => state.user.loading);
  const errorRecovery = useSelector((state) => state.user.errorRecovery);

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(actions.getRecoveryPassRequest({ email }));
  }

  return (
    <Container component="main" maxWidth="100%" className={classes.container}>

      <Card className={classes.card}>

        <Container component="main" maxWidth="xs">
          <CardContent className={classes.CardContent}>
            <Typography component="h5" align="left" variant="h5" className={classes.title}>
              Redefinição de senha
            </Typography>
            <Typography align="left" variant="body1">
              Digite seu endereço de e-mail e enviaremos instruções sobre como redefinir sua senha
            </Typography>
            <ValidatorForm className={classes.form} onSubmit={(e) => handleSubmit(e)}>
              <TextValidator
                variant="filled"
                margin="normal"

                fullWidth
                id="email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                autoComplete="email"
                autoFocus
                validators={['required', 'isEmail']}
                errorMessages={['Este campo é obrigatorio', 'Email não valido']}
              />
              {loading

                ? (
                  <Grid item xs={12} className={classes.loading}>
                    <CircularProgress />
                  </Grid>
                )
                : (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Redefinir
                  </Button>
                )}
              {
                errorRecovery === false && (
                  <Typography align="center" variant="body1">
                    Email enviado com sucesso
                  </Typography>
                )

              }
              {

                errorRecovery === true && (
                  <Typography align="center" color="danger" className={classes.error} variant="body1">
                    Email não encontrado em nosso sistema
                  </Typography>
                )
              }

            </ValidatorForm>


          </CardContent>
        </Container>
      </Card>

    </Container>


  );
}


export default withStyles(styles)(RecoveryPass);
