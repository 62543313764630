import themeEvah from '../../../assets/theme';

const styles = (theme) => ({
  root: {
    padding: themeEvah.contentSize.padding,
    paddingTop: 40,
    paddingBottom: 30,
  },
  imageWrapper: {
    marginBottom: 30,
  },
  CTAImage: {
    minHeight: 280,
  },
  CTAtitle: {
    fontWeight: 500,
    fontSize: 30,

    [theme.breakpoints.only('xs')]: {
      fontSize: 25,
    },
  },
  blue: {
    color: themeEvah.colors.secondary,
    fontWeight: 700,
  },
  bold: {
    fontWeight: 700,
  },
  regular: {
    fontWeight: 400,
  },
  buttonWrapper: {
    margin: '30px 0',
  },
  button: {
    fontSize: 18,
    textTransform: 'none',
    padding: '7px 32px',
  },
  blueButton: {
    backgroundColor: themeEvah.colors.secondary,
    color: '#fff',

    '&:hover': {
      backgroundColor: '#0b7ba8',
    },
  },

});

export default styles;
