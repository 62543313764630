import { isAbsolute } from 'path';
import themeEvah from '../../../assets/theme';

const styles = (theme) => ({

  card: {
    padding: 5,
    borderRadius: 4,
    position: 'relative',
    alignContent: 'space-between',
    background: '#F5F5F5',
  },


  EditButton: {
    padding: '0px 10px',
    margin: '20px 0 10px',
  },

  centerText: {
    fontFamily: 'Open Sans',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
    textAlign: 'left',
  },
  finalButtons: {
    margin: '10px auto',
    maxWidth: '250px',
    display: 'inline-flex',
    justifyContent: 'flex-end',
    padding: '0 15px',

    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: 0,
      right: 15,
    },
  },

  icon: {
    color: '#000',
    padding: 4,
    marginLeft: 6,
  },

  name: {
    color: '#343434',
    fontWeight: '600',
    fontFamily: 'Open Sans',
    marginBottom: 4,
    display: 'flex',
    alignItems: 'center',
  },

  textLowercase: {
    fontFamily: 'Open Sans',
    textTransform: 'none',
  },

  img: {
    maxWidth: 120,
    maxHeight: 120,
    height: 'auto',
    width: '100%',
  },

  SiteMenuIcon: {
    position: 'absolute',
    right: 5,
    top: 15,
    minWidth: 'unset',
    width: 26,
  },

  statusDiv: {
    display: 'flex',
  },

  status: {
    [theme.breakpoints.down('xs')]: {
      margin: '2px 60px',
    },
    padding: 4,
    borderRadius: 18,
    width: 130,
    fontFamily: 'Open Sans',
  },

  tooltip: {
    fontSize: 20,
  },

  unpublishButton: {
    fontFamily: 'Open Sans',
    background: '#C63B3B',
    color: '#fff',

    '&:hover': {
      background: '#d16161',
      borderColor: '#d16161',

    },
  },

  publishButton: {
    fontFamily: 'Open Sans',
    background: '#02569C',
    borderColor: '#02569C',
    color: '#fff',

    '&:hover': {
      background: '#037ee2',
      borderColor: '#037ee2',

    },
  },

  editButton: {
    fontFamily: 'Open Sans',
    background: 'transparent',
    borderColor: '#343434',
    boxSizing: 'border-box',
  },

  siteImageContainer: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
      maxWidth: 180,
    },
  },

  siteInfoContainer: {
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
  },

  siteTextContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
    },
    padding: '10px 15px',
  },

  link: {
    '& a': {
      color: '#343434',
      textTransform: 'lowercase',
    },
  },

  tagNew: {
    marginLeft: 15,
  },

  deleteIcon: {
    marginRight: 15,
  },

  customDomainWrapper: {
    padding: '0 15px',
    [theme.breakpoints.up('md')]: {
      padding: '0 8px',
      position: 'absolute',
      right: 15,
      top: 45,
    },
  },

  customDomain: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: 15,
    textTransform: 'none',
    color: '#02569C',
  },

  menuItem: {
    fontFamily: 'Open Sans',
  },
});

export default styles;
