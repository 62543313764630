/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';


import Typography from '@material-ui/core/Typography';
import { Creators as actions } from '../../store/ducks/sites';
import styles from './styles';

import SiteComponent from './SiteComponent';
import EmptyDash from '../../assets/img-empty-dash.png';


function SitesDashboard({ classes }) {
  const dispatch = useDispatch();

  const sites = useSelector((state) => state.sites.sites);
  const loading = useSelector((state) => state.sites.loading);
  const [preloading, setPreloading] = useState(false);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function getSites() {
      dispatch(actions.getSitesRequest());
    }

    getSites();
  }, []);


  useEffect(() => {
    if (preloading && !open && !loading && sites.length === 0) {
      setOpen(true);
    }

    setPreloading(loading);
  }, [loading]);


  return (
    <Grid container justify="center" className={classes.container}>
      {loading ? <CircularProgress className={classes.progress} /> : (

        <>

          {sites && sites.map((site, index) => (
            <Grid item xs={12} key={site.business_name} className={classes.siteComponentWrapper}>
              <SiteComponent site={site} index={index} />
            </Grid>
          ))}

          { sites && sites.length === 0
            ? (
              <Grid item xs={12} className={classes.message}>
                <Typography component="h1" align="center" variant="h5" className={classes.titleEmptyDash}>
                    Você ainda não tem sites criados!
                </Typography>
                <img src={EmptyDash} className={classes.imgEmptyDash} />

              </Grid>
            )
            : null}


        </>
      )}
    </Grid>
  );
}

export default withStyles(styles)(SitesDashboard);
