import { all, takeLatest } from 'redux-saga/effects';

import setToken from '../../services/auth';

import {
  userLogin, userRegister, userMe, controlRedux, resendMe, recovery, uploadMe,
} from './user';

import {
  getSites, createSiteRequest, exclueSIteRequest, getTemplates,
} from './sites';
import { paymentRequest, unpublishSiteRequest } from './payment';
import { getPlanos } from './plano';


import { Types as UserTypes } from '../ducks/user';
import { Types as SiteTypes } from '../ducks/sites';
import { Types as paymentTypes } from '../ducks/payment';
import { Types as planoTypes } from '../ducks/plano';

export default function* rootSaga() {
  yield all([
    takeLatest(planoTypes.GET_PLANOS_REQUEST, getPlanos),
    takeLatest(UserTypes.LOGIN_REQUEST, userLogin),
    takeLatest(UserTypes.REGISTER_REQUEST, userRegister),
    takeLatest(UserTypes.LOGIN_SUCCESS, userMe),
    takeLatest(UserTypes.REGISTER_SUCCESS, userMe),
    takeLatest(UserTypes.GET_ME_REQUEST, userMe),
    takeLatest(UserTypes.RESEND_EMAIL_REQUEST, resendMe),
    takeLatest(UserTypes.RECOVERY_REQUEST, recovery),
    takeLatest(UserTypes.GET_ME_UPLOAD_REQUEST, uploadMe),
    takeLatest(SiteTypes.GET_SITES_REQUEST, getSites),
    takeLatest(SiteTypes.GET_TEMPLATES_REQUEST, getTemplates),
    takeLatest(SiteTypes.CREATE_SITE_REQUEST, createSiteRequest),
    takeLatest(SiteTypes.EXCLUE_SITE_REQUEST, exclueSIteRequest),
    takeLatest(SiteTypes.EXCLUE_SITE_SUCCESS, getSites),
    takeLatest(SiteTypes.CREATE_SITE_SUCCESS, getSites),
    takeLatest(paymentTypes.PAY_REQUEST, paymentRequest),
    takeLatest(paymentTypes.PAY_SUCCESS, getSites),
    takeLatest(paymentTypes.UNPUBLISH_REQUEST, unpublishSiteRequest),
    takeLatest(paymentTypes.UNPUBLISH_SUCCESS, getSites),
    takeLatest('persist/REHYDRATE', setToken),
    takeLatest('persist/REHYDRATE', controlRedux),


  ]);
}
