/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';


import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

import anuncio1 from '../../../assets/icon-anuncio.svg';
import anuncio2 from '../../../assets/icon-anuncios-deselecionado.svg';
import loja1 from '../../../assets/icon-lojas.svg';
import loja2 from '../../../assets/icon-lojas-deselecionado.svg';
import marketing1 from '../../../assets/icon-remarketing.svg';
import marketing2 from '../../../assets/icon-remarketing-deselecionado.svg';
import site1 from '../../../assets/icon-site.svg';
import site2 from '../../../assets/icon-sites-deselecionado.svg';

import styles from './styles';


const tutorialSteps = [
  {
    label: 'Sites',
    imgPath:
      'https://irp-cdn.multiscreensite.com/a2c49a4e/dms3rep/multi/desktop/dashboard.gif',
  },
  {
    label: 'Lojas On-line',
    imgPath:
      'https://irp-cdn.multiscreensite.com/a2c49a4e/dms3rep/multi/desktop/img-lojas.png',
  },
  {
    label: 'Anúncios no Google',
    imgPath:
      'https://irp-cdn.multiscreensite.com/a2c49a4e/dms3rep/multi/desktop/img-anuncios.png',
  },
  {
    label: 'Remarketing',
    imgPath:
      'https://irp-cdn.multiscreensite.com/a2c49a4e/dms3rep/multi/desktop/img-remarketing.png',
  },
];

const options = [
  {
    name: 'Sites',
    text: 'Crie sites personalizados que convertem mais do que qualquer outro sem necessidade de programação.',
    icon: [site1, site2],
  }, {
    name: 'Lojas On-line',
    text: 'Transforme compartilhamento social em venda social. Adicione e venda produtos nos seus feeds do Facebook e do Instagram facilmente para permitir que compradores naveguem e façam compras.',
    icon: [loja1, loja2],
  }, {
    name: 'Anúncios no Google',
    text: 'Leve seus produtos ao topo do motor de busca mais poderoso do mundo com os anúncios automatizados para o Google da Evah.',
    icon: [anuncio1, anuncio2],
  }, {
    name: 'Remarketing',
    text: 'Campanhas do Facebook e Instagram em minutos para alcançar usuários que já visitaram seu site.',
    icon: [marketing1, marketing2],
  },
];


function OptionSelector({ classes }) {
  const [value, setValue] = React.useState('0');

  const handleChange = (event) => {
    setValue(event.target.value);
  };


  return (
    <Grid container direction="row" alignItems="stretch" className={classes.container}>
      <Grid item xs={12} sm={6} className={classes.flexCenter}>
        <img
          className={classes.img}
          src={tutorialSteps[value].imgPath}
          alt={tutorialSteps[value].label}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl component="fieldset" className={classes.formControl}>
          <Typography className={classes.title}>Uma única opção para</Typography>
          <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>

            {options && options.map((option, index) => (
              <FormControlLabel
                className={classes.label}
                value={`${index}`}
                control={(
                  <Radio
                    color="black"
                    className={classes.radio}
                    checkedIcon={<img src={option.icon[0]} className={classes.icon} />}
                    icon={<img src={option.icon[1]} className={classes.icon} />}
                  />
)}

                label={(
                  <div>
                    <Typography className={classes.subtitle}>{option.name}</Typography>
                    <Typography className={classes.text}>{option.text}
                    </Typography>
                  </div>
            )}
              />

            ))}


          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(OptionSelector);
