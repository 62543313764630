import themeEvah from '../../assets/theme';

const styles = (theme) => ({
  root: {
    position: 'relative',
    overflowX: 'hidden',
  },
});


export default styles;
