import themeEvah from '../../assets/theme';

const styles = (theme) => ({
  footer: {
    backgroundColor: '#000000',
    color: '#fff',
    padding: '70px 0 20px',
    // position: 'absolute',
    width: '100%',
    bottom: 0,
    [theme.breakpoints.only('xs')]: {
      padding: '40px 0 20px',
    },
  },
  contentSize: {
    maxWidth: themeEvah.contentSize.maxWidth,
    margin: themeEvah.contentSize.margin,
    padding: themeEvah.contentSize.padding,
  },
  upperFooter: {
    marginBottom: 10,
  },
  title: {
    fontWeight: 600,
  },
  list: {
    listStyle: 'none',
    paddingLeft: 0,
  },
  listItem: {
    marginBottom: 5,
  },
  navLink: {
    color: '#fff',
    cursor: 'pointer',
  },
  seloPTI: {
    maxHeight: 90,

    [theme.breakpoints.down('sm')]: {
      maxHeight: 100,
    },
  },
  seloInovativa: {
    maxHeight: 90,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 110,
    },
  },
  socialIcon: {
    maxHeight: 25,
    cursor: 'pointer',
  },
  bottomFooter: {
    paddingTop: 20,
  },
  copyright: {
    [theme.breakpoints.only('xs')]: {
      marginTop: 20,
      order: 2,
    },
  },
  logo: {
    width: '100%',
    height: 'auto',
    maxWidth: 220,
  },
  seloPTIWrapper: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  seloInovativaWrapper: {
    textAlign: 'center',
  },
  logoWrapper: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 50,
    },
  },
});

export default styles;
