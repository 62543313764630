import React from 'react';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/styles';

import styles from './styles';


function card({
  classes, title, price, cto, itens, adicionais,
}) {
  return (
    <div className={classes.card}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.price}>{price}</Typography>
      <Typography className={classes.cto}>{cto}e</Typography>
      <div className={classes.allHeight}>


        <div className={classes.itens}>
          {itens && itens.map((item) => (
            <Typography className={classes.item}>{item}</Typography>

          ))}
        </div>


        <div className={classes.adicionais}>
          {adicionais && adicionais.map((adicional) => (
            <Typography className={classes.adicional}>{adicional}</Typography>
          ))}

        </div>


      </div>

      <a href="https://evah.io/#ContactUs" className={classes.button}>
        Comprar Agora
      </a>
    </div>


  );
}


export default withStyles(styles)(card);
