import themeEvah from '../../assets/theme';

const styles = (theme) => ({
  container: {
    height: '100%',
    width: '100%',
  },
  subContainer: {
    height: '100%',
  },
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});


export default styles;
