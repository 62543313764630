/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import CircularProgress from '@material-ui/core/CircularProgress';


import { Creators as actions } from '../../../../store/ducks/sites';
import styles from './styles';

function ModalExclueSite({
  classes, open, setOpen, site, index,
}) {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.sites.errorExclueSite);
  const loading = useSelector((state) => state.sites.exclueSiteLoading);


  useEffect(() => {
    if (error === false && open) { console.log('deu certo'); setOpen(false); }
  }, [error]);

  function handleExclue() {
    dispatch(actions.exclueSiteRequest(site, index));
  }


  return (


    <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Excluir este site?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Não sera possivel restaurar esse site futuramente !
        </DialogContentText>
      </DialogContent>

      {loading

        ? (
          <Grid item xs={12} className={classes.loading}>
            <CircularProgress />
          </Grid>
        )

        : (
          <DialogActions>
            <Button onClick={() => setOpen(!open)} color="primary">
            Cancelar
            </Button>
            <Button color="primary" onClick={() => handleExclue()}>
            Excluir Site
            </Button>
          </DialogActions>
        )}
    </Dialog>

  );
}

export default withStyles(styles)(ModalExclueSite);
