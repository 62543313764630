import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import styles from './styles';

function CTA({ version, classes }) {
  return (
    <div className={classes.root}>
      {
        (version === 1)
          ? (
            <div className={classes.cta1}>
              <Container maxWidth="md" className={classes.container}>
                <Typography variant="h3" component="h2" className={classes.title1}> Entenda a diferença testando. </Typography>
                <Typography variant="h3" component="h2" className={classes.subtitle1}> Deixe o WordPress para trás!</Typography>
                <Button href="/register" variant="contained" size="large" className={classes.button1}>
                  Experimente grátis
                </Button>
              </Container>
            </div>
          )
          : (version === 2)
            ? (
              <div className={classes.cta2}>
                <Container maxWidth="md" className={classes.container}>
                  <Typography variant="h3" component="h2" className={classes.title2}> Descubra na prática nossas diferenças e <br /><span>nunca mais volte para o WordPress!</span></Typography>
                  <Button href="/register" variant="contained" size="large" className={classes.button2}>
                    Experimente grátis
                  </Button>
                </Container>
              </div>
            )
            : (version === 3)
              ? (
                <div className={classes.cta3}>
                  <Container maxWidth="md" className={classes.container}>
                    <Typography variant="h3" component="h2" className={classes.title3}> Construa um site agora e valide as vantagens.</Typography>
                    <Typography variant="h3" component="h2" className={classes.subtitle3}> WordPress nunca mais!</Typography>
                    <Button href="/register" variant="contained" size="large" className={classes.button3}>
                    Experimente grátis
                    </Button>
                  </Container>
                </div>
              ) : ''

      }
    </div>
  );
}

export default withStyles(styles)(CTA);
