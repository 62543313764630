/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import CreditCard from './CreditCard';
import Planos from './Planos';

import { Creators as actions } from '../../../../store/ducks/payment';

import styles from './styles';

function ModalCreateSite({
  classes, open, setOpen, site,
}) {
  const me = useSelector((state) => state.user.me);

  const dispatch = useDispatch();
  const [paginate, setPaginate] = useState(0);
  const [plano, setPlano] = useState(0);

  const error = useSelector((state) => state.payment.error);

  const [card, setCard] = useState({
    number: '',
    exp_month: '',
    exp_year: '',
    cvc: '',
    name: '',
  });

  function handleChange(event, name) {
    setCard({ ...card, [name]: event.target.value });
  }


  function handlePay() {
    // eslint-disable-next-line no-bitwise
    const payload = card;
    payload.number = card.number.replace(/\s/g, '');

    dispatch(actions.paymentRequest({ card: payload, plan_id: plano }, site.site_name));
  }

  useEffect(() => {
    if (error === false && open) { setOpen(false); }
  }, [error]);

  function resetModal() {
    setCard({
      number: '',
      exp_month: '',
      exp_year: '',
      cvc: '',
      name: '',
    });
    setPaginate(0);
  }

  return (


    <Dialog open={open} onEnter={() => resetModal()} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{me.emailVerified ? 'Efetuar pagamento' : 'Email Não Verificado'  }</DialogTitle>


      {
        me.emailVerified ? (
          paginate === 0 ? <Planos open={open} plano={plano} setPlano={setPlano} />
            : (
              <CreditCard
                card={card}
                handleChange={handleChange}
                error={error}
                paginate={paginate}
                setPaginate={setPaginate}
                handlePay={handlePay}
              />
            )
        ) : (
          <Grid container>
            <Grid
              item
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
              style={{ padding: 20 }}
            >
              <Typography style={{ textAlign: 'center' }}>Para continuar com a publicação é necessario que o email seja verificado</Typography>

            </Grid>
          </Grid>

        )

      }

      {
        me.emailVerified ? (
          <DialogActions>
            {
              paginate === 0
                ? (
                  <Button onClick={() => setOpen(!open)} color="primary">
                    Cancelar
                  </Button>
                ) : (
                  null
                )
            }
            {
              paginate === 0
                ? (
                  <Button onClick={() => { setPaginate(1); }} color="primary">
                    Proximo
                  </Button>
                ) : (
                  null
                )
            }

          </DialogActions>
        ) : (
          <DialogActions>
            <Button onClick={() => setOpen(!open)} color="primary">
                Cancelar
            </Button>
          </DialogActions>

        )
      }


    </Dialog>

  );
}

export default withStyles(styles)(ModalCreateSite);
