import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import styles from './styles';


function PriceCards({ classes }) {
  return (
    <div className={classes.root}>
      <Typography variant="h3" component="h2" className={classes.containerTitle}>Nossos planos e assinaturas</Typography>
      <div className={classes.outer_container}>
        <div className={[classes.container, classes.container1].join(' ')}>
          <div className={classes.inner_container}>
            <div className={classes.title}>Basic</div>
            <div className={classes.subtitle}>De 1 até 4 sites</div>
            <div className={classes.main_number}>R$<span className={classes.number}>89</span>,00</div>
            <div className={classes.container_text}>
              <div className={classes.container_text1}>Por site publicado</div>
              <div className={classes.container_text2} />
            </div>
          </div>
          <a href="/register"><span>Adquira</span></a>
        </div>
        <div className={[classes.container, classes.container2].join(' ')}>
          <div className={classes.inner_container}>
            <div className={classes.flag}>Recomendado</div>
            <div className={classes.title}>Assinatura</div>
            <div className={classes.subtitle}>De 5 até 8 sites</div>
            <div className={classes.main_number}>R$<span className={classes.number}>389</span>,00</div>
            <div className={classes.container_text}>
              <div className={classes.container_text1}>por mês</div>
              <div className={classes.container_text2} />
            </div>
          </div>
          <a href="/register"><span>Adquira</span></a>
        </div>
        <div className={[classes.container, classes.container3].join(' ')}>
          <div className={classes.inner_container}>
            <div className={classes.title}>Personalizado</div>
            <div className={classes.subtitle}>A partir de 8 sites</div>
            <div className={classes.consulte}>Consulte-nos</div>
            <div className={classes.container_text}>
              <div className={classes.container_text1} />
              <div className={classes.container_text2} />
            </div>
          </div>
          <a href="/register"><span>Adquira</span></a>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(PriceCards);
