import themeEvah from '../../assets/theme';

const styles = (theme) => ({

  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#0e9bd4',
    '&:hover': {
      backgroundColor: '#0b7ba8',
    },
  },

  loading: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    minHeight: 50,
  },
  register: {
    textDecoration: 'none',
    color: '#0e9bd4',
    fontWeight: 'bold',
    '&:hover': {
      color: '#0b7ba8',
    },
  },
});

export default styles;
