import api from './api';


export default function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.user;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}
