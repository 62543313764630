
/* import theme from '../../../assets/themAlex'; */

const styles = () => ({

  container: {
    margin: '50px auto',
  },

  height100: {
    height: '100%',
  },


  title: {
    color: 'rgb(33, 33, 33)',
    width: '100%',
    textAlign: 'center',
    fontSize: 30,
    fontWeight: 500,

  },

  subTitle: {
    color: 'rgb(33, 33, 33)',
    width: '100%',
    textAlign: 'center',
    fontFamily: 'sans-serif',
    fontSize: 20,
    marginBottom: 20,
  },

  planTitle: {
    color: 'rgb(33, 33, 33)',
    width: '100%',
    textAlign: 'center',
    fontFamily: 'sans-serif',
    fontSize: 18,
    marginBottom: 15,

    fontWeight: 500,
  },

});

export default styles;
