import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import imgDash from '../../../assets/img-dash.svg';
import imgWidgets from '../../../assets/img-widgets.svg';
import imgSpeedTest from '../../../assets/img-otimizacao.png';
import imgLayouts from '../../../assets/img-layout.png';
import imgROI from '../../../assets/img-passos.png';

import styles from './styles';

function Difference({ classes }) {
  return (
    <Container maxWidth="lg" className={classes.root}>
      <Typography variant="h3" align="center" className={classes.mainTitle}>O que nos diferencia do WordPress?</Typography>

      <Grid item container className={classes.item}>
        <Grid item xs={12} md={6} className={classes.itemText}>
          <Typography variant="h4" className={classes.title}>
            Crie Sites Mais Rápido
          </Typography>
          <Typography variant="body1" className={classes.text}>
          Construa sites em uma fração do tempo que levaria para construí-lo no WordPress. Sem necessidade de plugins que podem quebrar sites em atualizações.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={[classes.itemImage, classes.circle]}>
          <img src={imgDash} className={classes.img} />
        </Grid>
      </Grid>

      <Grid item container className={classes.item}>
        <Grid item xs={12} md={6} className={[classes.itemText, classes.mdOrder1]}>
          <Typography variant="h4" className={classes.title}>
          Diminua o Tempo de Desenvolvimento
          </Typography>
          <Typography variant="body1" className={classes.text}>
          Reduza tempo e custos com seus clientes centralizados em um só lugar. Basta de problemas com senhas, segurança e outros incovenientes de escalabilidade com WordPress.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={[classes.itemImage, classes.circle, classes.mdOrder0]}>
          <img src={imgWidgets} className={classes.img} />
        </Grid>
      </Grid>

      <Grid item container className={classes.item}>
        <Grid item xs={12} md={6} className={classes.itemText}>
          <Typography variant="h4" className={classes.title}>
          Otimização Automática
          </Typography>
          <Typography variant="body1" className={classes.text}>
          Sites da EVAH são otimizados automaticamente no padrão Lighthouse, que garante melhor indexação orgânica e cliques mais baratos em links patrocinados.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={[classes.itemImage, classes.circle]}>
          <img src={imgSpeedTest} className={classes.img} />
        </Grid>
      </Grid>

      <Grid item container className={classes.item}>
        <Grid item xs={12} md={6} className={[classes.itemText, classes.mdOrder1]}>
          <Typography variant="h4" className={classes.title}>
          Layout Adaptativo
          </Typography>
          <Typography variant="body1" className={classes.text}>
          Vá além do responsivo, caso necessário você pode criar layouts e conteúdos únicos para diferentes dispositivos de forma prática e descomplicada.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={[classes.itemImage, classes.circle, classes.mdOrder0]}>
          <img src={imgLayouts} className={classes.img} />
        </Grid>
      </Grid>

      <Grid item container className={classes.item}>
        <Grid item xs={12} md={6} className={classes.itemText}>
          <Typography variant="h4" className={classes.title}>
          Crie Seu Site em 3 Passos
          </Typography>
          <Typography variant="body1" className={classes.text}>
          Selecione um template, personalize e publique. Após a configuração do domínio ative o SSL com um clique. <span>Pague somente por sites publicados!</span>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={[classes.itemImage, classes.circle, classes.lastImage]}>
          <img src={imgROI} className={classes.img} />
        </Grid>
      </Grid>


    </Container>
  );
}

export default withStyles(styles)(Difference);
