import themeEvah from '../../../assets/theme';
import BgCta1 from '../../../assets/bg-cta-1.svg';
import BgCta3 from '../../../assets/bg-cta-3.svg';

const styles = (theme) => ({
  root: {

  },
  cta1: {
    background: `url(${BgCta1})`,
    backgroundSize: 'cover',
    padding: '20px 0',
    marginTop: '-90px',
    marginBottom: 70,
  },
  title1: {
    color: '#333',
    fontSize: 40,
    fontFamily: 'Open Sans',
    lineHeight: '1.4',
    marginBottom: 15,

    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
      lineHeight: '1.2',
    },
  },
  subtitle1: {
    color: '#333',
    fontSize: 35,
    fontFamily: 'Open Sans',
    fontWeight: '700',
    marginBottom: 40,

    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
      lineHeight: '1.2',
      marginBottom: 30,
    },

  },
  button1: {
    background: 'linear-gradient(96deg, #02569C 0%, #02569C 100%) 0% 0% no-repeat padding-box',
    fontSize: 18,
    fontFamily: 'Open Sans',
    color: '#fff',
    textTransform: 'none',
  },
  cta2: {
    background: 'transparent linear-gradient(96deg, #009BD6 0%, #02569C 100%) 0% 0% no-repeat padding-box',
    padding: '20px 10px',
  },
  title2: {
    color: '#fff',
    fontSize: 40,
    fontFamily: 'Open Sans',
    fontWeight: 600,
    lineHeight: '1.4',
    marginBottom: 40,

    '& span': {
      fontWeight: 700,
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
      lineHeight: '1.2',
    },
  },
  button2: {
    background: '#fff',
    fontSize: 18,
    fontFamily: 'Open Sans',
    color: '#333',
    textTransform: 'none',
  },
  cta3: {
    background: `url(${BgCta3})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '4% 0',
    margin: '70px 0 80px',

    [theme.breakpoints.down('sm')]: {
      padding: '30px 20px',
      margin: '30px 0 60px',
    },
  },
  title3: {
    color: '#333',
    fontSize: 35,
    fontFamily: 'Avenir Roman',
    lineHeight: '1.4',
    marginBottom: 10,

    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
      lineHeight: '1.2',
    },
  },
  subtitle3: {
    color: '#333',
    fontSize: 38,
    fontFamily: 'Open Sans',
    fontWeight: '700',
    marginBottom: 40,

    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  button3: {
    background: '#02569C',
    fontSize: 18,
    fontFamily: 'Open Sans',
    color: '#fff',
    textTransform: 'none',
  },
  container: {
    padding: '50px 0 55px',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      padding: '15px 0 25px',
    },
  },
});


export default styles;
