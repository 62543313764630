/* eslint-disable camelcase */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import {DialogTitle,Button,DialogActions ,Dialog} from '@material-ui/core';


import { Creators as actions } from '../../../../store/ducks/payment';

import styles from './styles';

function ModalFreePublish({ classes, open, setOpen, site,}) {
  const dispatch = useDispatch();
  const me = useSelector(state => state.user.me);


  function handlePay() {
    dispatch(actions.paymentRequest({ }, site.site_name));
  }



  return (


    <Dialog open={open}  aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{me.emailVerified ? 'Publicar Site' : 'Email Não Verificado'  }</DialogTitle>

      { me.emailVerified ?  
        (
          <>
          <DialogTitle id="form-dialog-title">Deseja publicar este site?</DialogTitle>

          <DialogActions>  
            <Button onClick={() => setOpen(!open)} color="primary">
              cancelar
            </Button>
            <Button onClick={() => handlePay()} color="primary">
              publicar
            </Button>
          </DialogActions>
          </>
        )
        
        : ( 
          <DialogActions>  
            <Button onClick={() => setOpen(!open)} color="primary">
              cancelar
            </Button>
          </DialogActions>
        ) }

    </Dialog>

  );
}

export default withStyles(styles)(ModalFreePublish);
