import React, { useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab';
import { withStyles, Typography } from '@material-ui/core';

import ModalCreateSite from '../ModalCreateSite';

import styles from './styles';


function SpeedDialCreateSite({ classes }) {
  const [open, setOPen] = useState(false);


  const [create, setCreate] = useState(false);


  function handleClick() {
    setOPen(!open);
    setCreate(!create);
  }

  return (
    <>
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={() => setOPen(false)}
        onOpen={() => setOPen(true)}
        open={open}
      >
        <SpeedDialAction
          icon={<AddIcon color="primary" size="large" />}
          tooltipTitle={(
            <Typography noWrap align="center" color="primary" variant="subtitle1">
              Criar Site
            </Typography>
        )}
          tooltipOpen
          onClick={() => handleClick()}
        />


      </SpeedDial>

      <ModalCreateSite open={create} setOpen={setCreate} />
    </>
  );
}


export default withStyles(styles)(SpeedDialCreateSite);
