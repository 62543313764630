/* eslint-disable camelcase */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import {DialogTitle,Button,DialogActions ,Dialog ,DialogContent  ,DialogContentText} from '@material-ui/core';


import styles from './styles';

function ModalFreePublish({ classes, open, setOpen,}) {

  return (
    <Dialog open={open}  aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Verificação de Email</DialogTitle>
      <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Email de confirmação enviado!
          </DialogContentText>
      </DialogContent>
     
      <DialogActions>  
        <Button onClick={() => setOpen(!open)} color="primary">
          Voltar
        </Button>
      </DialogActions>
        

    </Dialog>

  );
}

export default withStyles(styles)(ModalFreePublish);
