import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';

import styles from './styles';
import Facebook from '../../../assets/logo-fb-ads.svg';
import Google from '../../../assets/logo-google-ads.svg';
import Instagram from '../../../assets/logo-ig-ads.svg';

function Banner({ classes }) {
  return (
    <div className={classes.root}>

      <Grid className={classes.mainBanner}>
        <Grid className={classes.mainTitleWrapper}>
          <Typography variant="h2" component="h1" className={classes.mainTitle} align="center" paragraph>
            Plataforma <span className={classes.blue}>Web Design</span> para
          </Typography>
          <Typography variant="h2" component="h1" className={classes.mainTitle} align="center" paragraph>
          construir Sites e Lojas Virtuais
          </Typography>
        </Grid>

        <Typography variant="h6" component="h4" className={classes.subTitle} align="center">
          Aumente a performance da sua Equipe migrando do Wordpress
        </Typography>

        <Grid className={classes.buttonWrapper} align="center">
          <Button variant="contained" className={[classes.button, classes.blueButton]}>
            Comece Agora
          </Button>
        </Grid>
      </Grid>

      <Grid className={classes.subBanner}>
        <Typography variant="h6" component="h5" className={classes.adsTitle} align="center" paragraph>
          Equipe certificada pelo Google e Facebook
        </Typography>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={4} className={classes.adsImage} align="center">
            <img src={Google} alt="" />
          </Grid>
          <Grid item xs={12} sm={4} className={classes.adsImage} align="center">
            <img src={Facebook} alt="" />
          </Grid>
          <Grid item xs={12} sm={4} className={classes.adsImage} align="center">
            <img src={Instagram} alt="" />
          </Grid>
        </Grid>
      </Grid>

    </div>
  );
}

export default withStyles(styles)(Banner);
