const firebase = require('firebase');
require('firebase/auth');

/* const firebaseConfig = {
  apiKey: 'AIzaSyBvnbOgzSrZENNqJOviKEMfVGYO68FFGK4',
  authDomain: 'evah-saas.firebaseapp.com',
  databaseURL: 'https://evah-saas.firebaseio.com',
  projectId: 'evah-saas',
  storageBucket: 'evah-saas.appspot.com',
  messagingSenderId: '856866160624',
  appId: '1:856866160624:web:9bc73e73da6df1f854e735',
}; */

const firebaseConfig = {
  apiKey: 'AIzaSyAsoTkvxYLlVXOZmMJ9_GluD_bcmcQDPWE',
  authDomain: 'ambient-net-178300.firebaseapp.com',
  databaseURL: 'https://ambient-net-178300.firebaseio.com',
  projectId: 'ambient-net-178300',
  storageBucket: 'ambient-net-178300.appspot.com',
  messagingSenderId: '997647196304',
  appId: '1:997647196304:web:6b9b055be82e7f0c',
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const provider = new firebase.auth.GoogleAuthProvider();

export default firebase.auth();
